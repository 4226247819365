<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 p-0">
        <card class="m-0 mb-2">
          <div class="card-header p-0">
            <div class="row mr-2">
              <div class="col-md-8">


                <h4 class="card-title">
                  Para criar um novo envio basta manter os produtos aqui.
                </h4>
                <p class="card-category">
                  ou escolher novos produtos no botão adicionar.
                </p>
              </div>
              <div class="col-md-2 offset-md-2">
                <l-button wide type="success" size="sm" @click="addProduct">
                  <span class="btn-label">
                    <i class="fa fa-check"></i>
                  </span>
                  &nbsp;&nbsp;Adicionar
                </l-button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <el-table :data="cartProducts">
                <el-table-column type="index"></el-table-column>
                <el-table-column min-width="40" label="Foto">
                  <template slot-scope="{ row }">
                    <div class="img-fluid">
                      <img
                        :src="productImage(row.image)"
                        alt="Foto"
                        class="img-thumbnail"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="stock"
                  align="center"
                  min-width="100"
                  label="Qt Estoque"
                >
                </el-table-column>
                <el-table-column
                  prop="amount_send"
                  align="center"
                  min-width="100"
                  label="Qt a Enviar"
                >
                </el-table-column>
                <el-table-column
                  prop="weight"
                  align="center"
                  min-width="100"
                  label="Peso"
                >
                </el-table-column>
                <el-table-column
                  prop="created_at"
                  align="center"
                  min-width="120"
                  label="Criado em"
                >
                </el-table-column>
                <el-table-column label="ações" min-width="60">
                  <div class="td-actions" slot-scope="props">
                    <a
                      v-tooltip.top-center="'Delete'"
                      class="btn btn-danger btn-link btn-xs"
                      @click="removeProductToCart(props.$index)"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <tfoot>
          <tr>
            <td colspan="5">
              <table class="table table-responsive-md m-0 p-0">
                <tr style="border: 0px">
                  <td class="m-0 p-0 b-0" style="border: 0px">
                      <span class="text-left">
                        <strong>Total de produstos:</strong>&nbsp;
                      </span>
                  </td>
                  <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                    <span class="text-right">{{ totalQntProduct }}</span>
                  </td>
                </tr>
                <tr style="border: 0px">
                  <td class="m-0 p-0 b-0" style="border: 0px">
                      <span class="text-left">
                        <strong>Peso Total dos produstos:</strong>&nbsp;
                      </span>
                  </td>
                  <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                      <span class="text-right"
                      >{{ totalProductWeight }} kg</span
                      >
                  </td>
                </tr>
                <tr>
                  <td class="m-0 p-0" style="border: 0px">
                      <span class="text-left">
                        <strong>Peso estimado da caixa:</strong>&nbsp;
                      </span>
                  </td>
                  <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                      <span class="text-right"
                      >{{ totalProductWeightApproximate }} kg</span
                      >
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          </tfoot>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 p-0 pr-2">
        <card class="m-0 mb-2">
          <div class="card-header p-0">
            <div class="row">
              <div class="col-md-6">
                <h5 class="card-title">Endereço para entrega.</h5>
              </div>
              <div class="col-md-2">
                <add-user-address></add-user-address>
              </div>
            </div>
          </div>

          <div class="col-xl-12 col-lg-12 p-0 mt-2">
            <el-select
              class="select-default col-md-12"
              size="large"
              v-model="cart_address"
              filterable
              :placeholder="selects.placeholder"
            >
              <el-option
                v-for="option in selects.userAddresses"
                class="select-default"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>
        </card>
      </div>
      <div class="col-xl-6 col-lg-6 p-0">
        <card class="m-0 mb-2">
          <div class="card-header p-0">
            <h5 class="card-title">Opção de pagamento</h5>
          </div>
          <div class="col-xl-12 col-lg-12 p-0 mt-2">
            <el-select
              class="select-default col-md-12"
              size="large"
              :placeholder="selects.placeholderPayment"
              v-model="cart_payment_method"
            >
              <el-option
                v-for="option in selects.paymentOptions"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-7 col-lg-7 p-0 pr-2">
        <cart-declariont></cart-declariont>
        <card class="m-0 mb-2">
          <div class="card-header p-0">
            <h5 class="card-title">Observações Extras.</h5>
            <p class="card-category">
              Se tem alguma observação sobre seu pedido escreva aqui.
            </p>
          </div>
          <div class="row pt-2">
            <div class="col-md-12">
              <fg-input label="">
                <textarea
                  class="form-control"
                  rows="3"
                  v-model="observation"
                ></textarea>
              </fg-input>
            </div>
          </div>
        </card>
      </div>
      <div class="col-xl-5 col-lg-5 p-0">
        <div class="row">
          <div class="col-12">
            <card class="m-0 mb-2">
              <div class="card-header p-0 pb-2">
                <h5 class="card-title">Serviços Extras.</h5>
              </div>
              <div class="pl-4">
                <div v-for="service in extraSeervices" :key="service.id">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-bind:id="service.name"
                    v-bind:value="service.id"
                    v-model="cart_extra_services"
                  />
                  <label
                    :for="service.name"
                    class="btn btn-link text-body btn-outline-primary p-0"
                  >
                    {{ service.name }}
                  </label>
                </div>
              </div>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <div class="row mb-2">
                  <div class="col-4">&nbsp;</div>
                  <div class="col-8 text-right">
                    <l-button
                      type="primary"
                      size="lg"
                      @click="setCartAddress"
                      v-if="!cart_address"
                    >
                      <span class="btn-label">
                        <i class="fa fa-check pr-4 "></i>
                      </span>
                      Criar envio
                    </l-button>
                    <l-button
                      type="primary"
                      size="lg"
                      @click="setPaymebntMethod"
                      v-else-if="!cart_payment_method"
                    >
                      <span class="btn-label">
                        <i class="fa fa-check pr-4 "></i>
                      </span>
                      Criar envio
                    </l-button>
                    <l-button
                      type="primary"
                      size="lg"
                      @click="setCartDeclaration"
                      v-else-if="declarationsCart.length < 1"
                    >
                      <span class="btn-label">
                        <i class="fa fa-check pr-4"></i>
                      </span>
                      Criar envio
                    </l-button>

                    <l-button type="primary" size="lg" @click="setCart" v-else>
                      <span class="btn-label">
                        <i class="fa fa-check pr-4"></i>
                      </span>
                      Criar envio
                    </l-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="Meus Produtos" :visible.sync="modals.modal_product">
      <div>
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="m-0 mb-2">
              <div class="row">
                <div class="col-12">
                  <fg-input
                    type="text"
                    label="Pesquise o seu produto"
                    name="item"
                    v-model="find.search"
                    v-on:keyup="loadMyProducts"
                  ></fg-input>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <table class="table table-striped table-hover table-condesed">
                    <thead>
                    <tr>
                      <th>foto</th>
                      <th class="text-center">qnt estoque</th>
                      <th class="text-center">peso</th>
                      <th class="text-center">criado em</th>
                      <th class="text-center">ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in myProducts" :key="product.id">
                      <td width="20%">
                        <img
                          :src="productImage(product.image)"
                          width="60"
                          alt="image"
                          class="align-self-center img-thumbnail img-fluid"
                        />
                      </td>
                      <td width="15%" class="text-center">
                        {{ product.amount }}
                      </td>
                      <td width="15%" class="text-center">
                        {{ product.weight }} kg
                      </td>
                      <td width="20%" class="text-center">
                        {{ product.created_at }}
                      </td>
                      <td class="text-center">
                        <a
                          v-tooltip.top-center="'Adicionar'"
                          @click="addProductToCart(product)"
                          class="p-0 btn btn-success btn-link btn-xs"
                        >
                          <i class="fa fa-plus"></i>
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('modal_product')">
          fechar</button
        >&nbsp;
      </span>
    </el-dialog>
    <el-dialog
      title="Fechamento do Carrinho"
      :visible.sync="modals.modal_cart"
      class="pt-0"
    >
      <div>
        <div class="col-xl-12 col-lg-12">
          <div class="m-0">
            <h3 class="text-danger text-center mt-0">
              <strong>ATENÇÃO!</strong>
            </h3>
            <p class="text-body text-justify">
              1. Após finalizar, sua caixa será fechada e lacrada em até 24
              horas úteis, ficando disponível para pagamento. Caso deseje
              inserir, alterar algum produto ou cancelar seu pedido depois de finalizar esse
              formulário, será cobrada uma taxa extra de € 10,00 para caixas de até 10 kg e € 15,00 para caixas a cima
              de 10 kg.
            </p>
            <p class="text-body text-justify">
              2. Todos os pedidos de envios terão o período gratuito de
              armazenamento de 30 (trinta) dias. Ao findar o prazo o pedido terá
              uma multa no valor de 10€ e se o cliente não entrar em contato em
              até 3 (três) dias após o pacote expirado, o mesmo será considerado
              abandonado. A EURO FAST BOX venderá os pacotes abandonados através
              de leilão público ou uma venda particular, sem qualquer aviso
              prévio.
            </p>
            <p class="text-body text-justify">
              3. A EURO FAST BOX não será responsável por qualquer dano ou
              extravio de suas encomendas dentro ou fora do território EUROPEU,
              seja por erro ou omissão no fornecimento de informações falsas ou
              incorretas inseridas no preenchimento do formulário de envio do
              cliente ou até mesmo por falta de serviço executado pelo CTT ou
              Correios do país de destino.
            </p>
            <p class="text-body text-justify">
              4. A EURO FAST BOX também não é responsável por taxas (caso
              ocorra) no país de destino ou desembaraços de mercadorias junto às
              autoridades alfândegarias do país de destino.
            </p>
            <p class="text-body text-justify">
              5. A EURO FAST BOX não oferece nenhuma compensação adicional pelo
              atraso ou fornece frete grátis se esse pacote for enviado
              posteriormente.
            </p>
            <p class="text-body text-justify">
              6. Se enviarmos um pacote para o endereço errado (por culpa da
              EURO FAST BOX), então, fazemos tudo para reencaminhar o pacote
              para o cliente correto.Vamos cobrir o custo de envio e manuseio,
              mas não compensaremos atrasos ou inconvenientes.
            </p>
            <fg-input class="" label="">
              <checkbox v-model="accept_terms">
                li e estou ciente das informações acima.
              </checkbox>
            </fg-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('modal_cart')">
          cancelar
        </button>&nbsp;
        <button
          :disabled="setCartDisabled"
          class="btn btn-primary"
          @click="closeCart"
          v-show="accept_terms"
        >
          solicitar envio
        </button>
        <button class="btn btn-default disabled" v-show="!accept_terms">
          solicitar envio
        </button>
      </span>
    </el-dialog>
    <el-dialog
      title="ATENÇÃO!"
      :visible.sync="modals.modal_address"
      class="pt-0"
    >
      <div>
        <div class="col-xl-12 col-lg-12">
          <div class="m-0">
            <strong>Você precisa selecionar um endereço valido.</strong>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-primary" @click="closeModal('modal_address')">
          OK
        </button>&nbsp;
        <!-- <button class="btn btn-primary" @click="closeCart" v-show="accept_terms">solicitar envio</button>
              <button class="btn btn-default disabled"  v-show="!accept_terms">solicitar envio</button> -->
      </span>
    </el-dialog>
    <el-dialog
      title="ATENÇÃO!"
      :visible.sync="modals.modal_payment_method"
      class="pt-0"
    >
      <div class="col-xl-12 col-lg-12">
        <div class="m-0">
          <strong>Você precisa selecionar uma forma de pagamento.</strong>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-primary" @click="closeModal('modal_payment_method')">
          OK
        </button>&nbsp;
      </span>
    </el-dialog>
    <el-dialog
      title="ATENÇÃO!"
      :visible.sync="modals.modal_declaration"
      class="pt-0"
    >
      <div>
        <div class="col-xl-12 col-lg-12">
          <div class="m-0">
            <strong>
              Você precisa fazer a declaração de pelo menos 1 item.
            </strong>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn btn-primary"
          @click="closeModal('modal_declaration')"
        >
          OK</button
        >&nbsp;
        <!-- <button class="btn btn-primary" @click="closeCart" v-show="accept_terms">solicitar envio</button>
              <button class="btn btn-default disabled"  v-show="!accept_terms">solicitar envio</button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {Checkbox, Dialog, Option, Radio, Select, Table, TableColumn} from "element-ui";
import {StatsCard} from "src/components/index";
import CartDeclariont from "./CartDeclaration";
import MyProducts from "./MyProducts";
import TotalProduct from "./TotalProduct";
import LAlert from "../../../../../components/Alert";
import AddUserAddress from "../../UserProfile/addUserAddress";

export default {
  components: {
    LAlert,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    CartDeclariont,
    Checkbox,
    MyProducts,
    TotalProduct,
    StatsCard,
    Radio,
    AddUserAddress
  },
  data() {
    return {
      setCartDisabled: false,
      loading: false,
      modals: {
        modal_product: false,
        modal_cart: false,
        modal_address: false,
        modal_payment_method: false,
        modal_declaration: false,
        centered: true
      },
      cart_uuid: User.userProfile().id,
      find: {
        search: ""
      },
      isAdmin: false,
      accept_terms: false,
      totalProducts: "",
      myProducts: [],
      cartProducts: [],
      extraSeervices: [],
      declarationsCart: [],
      totalQntProduct: 0,
      totalProductWeight: 0,
      totalProductWeightApproximate: 0,
      cartOptionsSend: [],
      cart_address: "",
      observation: "",
      cart_option: "",
      cart_payment_method: "",
      cart_extra_services: [],
      selects: {
        placeholder: "Selecione um Endereço",
        placeholderPayment: "Selecione uma forma de pagamento",
        userAddresses: [],
        paymentOptions: []
      },
      cartClose: {}
    };
  },
  methods: {
    checkAddress() {
      axios.post("/auth/me").then(({data}) => {
        if (!data.is_address) {
          swal({
            title: "Adicionar Endereço!",
            text:
              "Você precisa completar todos os seus endereços de envios. Caso tenha algum endereço que não vai mais usar, é só exclui-lo.",
            type: "warning",
            showCancelButton: false,
            confirmButtonText: "ok",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          }).then(result => {
            window.location.href = "/admin/userprofile";
          });
        }
      });
    },
    productImage(image) {
      return (
        baseURLIMGUSER +
        "/" +
        User.userProfile().id +
        "/box/product/thumbnail_" +
        image
      );
    },
    closeCart() {
      this.setCartDisabled = true;
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom"
      });

      this.cartClose.services = this.cart_extra_services;
      this.cartClose.declarations = this.declarationsCart;
      this.cartClose.address = this.cart_address;
      this.cartClose.products = this.cartProducts;
      this.cartClose.observation = this.observation;
      this.cartClose.payment_method = this.cart_payment_method;

      axios
        .post("/cart", this.cartClose)
        .then(() => {
          this.$Progress.finish();
          this.modals.modal_cart = false;
          this.$notify({
            message:
              "Carrinho criado com sucesso! Fatura sera enviada por email.",
            type: "success",
            icon: "fa fa-check"
          });
          localStorage.removeItem("cart_" + User.userProfile().id);
          this.countCart(0);
          this.loading = false;
          this.setCartDisabled = false;

          swal({
            title: "Aguarde!",
            text:
              "Em até 24h uteis seu pedido estará disponível para pagamento.",
            type: "warning",
            showCancelButton: false,
            confirmButtonText: "Ok, tudo bem!",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.modals.modal_cart = false;
              this.setCartLoading = true;
              location.reload();

            }
            location.reload('/');
          });
          this.modal_cart = false;
          this.setCartLoading = false;
        })
        .catch(error => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
          });
          this.loading = false;
          this.setCartDisabled = false;
          this.setCartLoading = false;
          this.modals.modal_cart = false;
          this.$Progress.fail();
        });
    },
    setCart() {
      this.modals["modal_cart"] = true;
    },
    setCartAddress() {
      this.modals["modal_address"] = true;
    },
    setPaymebntMethod() {
      swal({
        title: "Atenção!",
        text:
          "Você precisa escolher uma forma de pagamento!",
        type: "warning",
        showCancelButton: false,
        confirmButtonText: "Fechar",
        confirmButtonClass: "btn btn-danger btn-fill",
        buttonsStyling: false
      })
      // this.modals["modal_payment_method"] = true;
    },
    setCartDeclaration() {
      this.modals["modal_declaration"] = true;
    },
    addProduct() {
      this.modals["modal_product"] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    loadMyProducts() {
      axios
        .get("product/myproduct?search=" + this.find.search)
        .then(({data}) => (this.myProducts = data.data));
    },
    loadUserAddress() {
      this.loading = true;
      this.$Progress.start();
      axios
        .get("cartuseraddress")
        .then(({data}) => (this.selects.userAddresses = data.data));
      this.loading = false;
      this.$Progress.finish();

    },
    loadCartPaymentOptions() {
      axios
        .get("cartpaymentoption")
        .then(({data}) => (this.selects.paymentOptions = data.data));
    },
    loadCartExtraServices() {
      axios
        .get("cartextraservice")
        .then(({data}) => (this.extraSeervices = data.data));
    },
    addProductToCart(product) {
      const index = this.cartProducts.findIndex(
        (cartProduct, index, array) => cartProduct.id === product.id
      );
      let qtde = prompt("Quantos produtos?");

      if (index < 0) {
        if (parseInt(qtde) < parseInt(product.amount)) {
          this.cartProducts.push({
            id: product.id,
            description: product.description,
            stock: product.amount,
            amount_send: qtde,
            weight: product.weight,
            image: product.image,
            created_at: product.created_at,
            active: false
          });
          localStorage["cart_" + User.userProfile().id] = JSON.stringify(
            this.cartProducts
          );
          this.totalWeigth();
          this.approximate();
          this.countCart(
            JSON.parse(localStorage["cart_" + User.userProfile().id]).length
          );
        } else {
          this.cartProducts.push({
            id: product.id,
            description: product.description,
            stock: product.amount,
            amount_send: product.amount,
            weight: product.weight,
            image: product.image,
            created_at: product.created_at,
            active: false
          });
          localStorage["cart_" + User.userProfile().id] = JSON.stringify(
            this.cartProducts
          );
          this.totalWeigth();
          this.approximate();
          this.countCart(
            JSON.parse(localStorage["cart_" + User.userProfile().id]).length
          );
        }
      } else {
        const cartProductsList = this.cartProducts.map(
          (productCart, index, array) => {
            if (productCart.id === product.id) {
              let amount_send =
                parseInt(qtde) + parseInt(productCart.amount_send);
              if (parseInt(amount_send) < parseInt(productCart.stock)) {
                productCart.amount_send = parseInt(amount_send);
              } else {
                productCart.amount_send = product.amount;
              }
            } else {
            }
            return productCart;
          }
        );
        localStorage["cart_" + User.userProfile().id] = JSON.stringify(
          this.cartProducts
        );
        this.totalWeigth();
        this.approximate();
        this.countCart(
          JSON.parse(localStorage["cart_" + User.userProfile().id]).length
        );
      }
    },
    removeProductToCart(index) {
      this.$delete(this.cartProducts, index);
      let items = JSON.parse(localStorage["cart_" + User.userProfile().id]);
      items.splice(index, 1);
      localStorage["cart_" + User.userProfile().id] = JSON.stringify(items);
      this.totalWeigth();
      this.approximate();
      this.countCart(
        JSON.parse(localStorage["cart_" + User.userProfile().id]).length
      );
    },
    handleEdit(index, row) {
      alert(`Your want to edit ${row.name}`);
    },
    handleDelete(index, row) {
      alert(`Your want to delete ${row.name}`);
    },
    totalWeigth() {
      let productsWeight = JSON.parse(
        localStorage.getItem("cart_" + User.userProfile().id)
      );
      if (localStorage["cart_" + User.userProfile().id]) {
        let weight = productsWeight
          .reduce(
            (total, item) => parseFloat(total + item.amount_send * item.weight),
            0
          )
          .toFixed(3);
        this.totalProductWeight = parseFloat(weight).toFixed(3);
        this.totalQntProduct = productsWeight.reduce(
          (total, item) => total + item.amount_send,
          0
        );
      }
    },
    approximate() {
      let productsWeight = JSON.parse(
        localStorage.getItem("cart_" + User.userProfile().id)
      );
      if (localStorage["cart_" + User.userProfile().id]) {
        let weight = productsWeight
          .reduce(
            (total, item) => parseFloat(total + item.amount_send * item.weight),
            0
          )
          .toFixed(3);
        let total = parseFloat(weight * 0.17).toFixed(3);
        this.totalProductWeightApproximate = (
          parseFloat(weight) + parseFloat(total)
        ).toFixed(3);
      }
    },
    countCart(count) {
      EventBus.$emit("count-cart", count);
    }
  },
  created() {
    this.loadMyProducts();
    this.totalWeigth();
    this.approximate();
    if (localStorage.getItem("cart_" + User.userProfile().id)) {
      this.cartProducts = JSON.parse(
        localStorage.getItem("cart_" + User.userProfile().id)
      );
    }
    this.totalProducts = localStorage["cart_" + User.userProfile().id]
      ? JSON.parse(localStorage["cart_" + User.userProfile().id]).length
      : 0;

    this.loadUserAddress();
    this.loadCartPaymentOptions();
    this.loadCartExtraServices();
    this.isAdmin = User.isAdmin();
  },
  mounted() {
    this.checkAddress();
    EventBus.$on("declarations", declaration => {
      this.declarationsCart = declaration;
    });
    EventBus.$on("loadUserAddress", () => {
      this.loadUserAddress();
    });
    this.loadUserAddress();
    this.loadCartPaymentOptions();
    this.loadCartExtraServices();
    this.isAdmin = User.isAdmin();
  },
  computed: {}
};
</script>
<style>
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.swal-overlay {
  z-index: 100000000000 !important;
}
</style>
