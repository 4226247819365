<template>
  <div class="user">
    <div class="photo">
      <img :src="profile.picture" alt="avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" href="#">
           <span>
             {{ profile.name }}
             <b class="caret"></b>
          </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <el-collapse-transition>
          <ul class="nav" v-show="!isClosed">
            <slot>
              <li>
                <router-link class="profile-dropdown" to="/admin/userprofile">
                  <i class="fa fa-user"></i>
                  <span class="sidebar-normal">Minha Conta</span>
                </router-link>
              </li>
            </slot>
          </ul>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

  export default {
    components: {
      [CollapseTransition.name]: CollapseTransition
    },
    data () {
      return {
        isClosed: true,
        profile: {}
      }
    },
    created() {
      if(User.userProfile().profile_picture =='no-img-profile.png'){
        this.profile.picture = baseURLIMGUSER+'/'+User.userProfile().profile_picture;
      }else{
        this.profile.picture = baseURLIMGUSER+User.userProfile().id+'/'+User.userProfile().profile_picture;
      }
      this.profile.name = User.userProfile().name;
      this.profile.created_at = User.userProfile().created_at;
    },
    methods: {
      toggleMenu () {
        this.isClosed = !this.isClosed
      }
    }
  }
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>
