<template>
  <label class="file-select">
    <div class="btn btn-info btn-fill btn-wd">
      <span v-if="value">{{value.name}}</span>
      <span v-else>Escolher</span>
    </div>
      <input type="file" id="picture" ref="picture" @change="handleFileChange" accept="image/*" capture="camera"/>
  </label>
</template>

<script>
  export default {
    props: {
      value: File
    },
    data() {
      return {
        picture: '',
        loading: false
      }
    },
    methods: {
      handleFileChange() {
        this.$notify(
          {
            message: 'Aguarde estamos trabalhando em sua solicitação!',
            type: 'info',
            icon: 'fa fa-check',
            horizontalAlign: 'left',
            verticalAlign: 'bottom',
          });

        this.loading = true;
        this.$Progress.start();

        this.picture = this.$refs.picture.files[0];
        let formData = new FormData();
        formData.append('picture', this.picture );

        axios.post('user/picture',formData,
          {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }
          ).then((res)=>{
          User.clearSetUser(res.data.data[0]);
          this.$notify(
            {
              message: 'Dados atualizados com sucesso!',
              type: 'success',
              icon: 'fa fa-check'
            });
          this.loading = false;
          this.$Progress.finish();
          location.reload();
        }).catch((error)=>{
          if(error){
            Exception.handle(error);
          }
          swal({
            title:"Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false
          });
          this.loading = false;
          this.$Progress.fail();
        });
      }
    }
  }
</script>

<style scoped>
  .file-select > .select-button {
    padding: 1rem;
    color: white;
    background-color: #2EA169;
    border-radius: .3rem;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  .file-select > input[type="file"] {
    display: none;
  }
</style>
