<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{name: 'Dashboard', icon: 'fa fa-tachometer-alt', path: '/admin/overview'}"
        ></sidebar-item>

        <sidebar-item :link="{name: 'Configurações', icon: 'fa fa-cog'}" v-show="isAdmin">
          <sidebar-item :link="{name: 'Definições', path: '/admin/settings'}"></sidebar-item>
          <sidebar-item :link="{name: 'Opções de Pagamento', path: '/admin/paymentoption'}"></sidebar-item>
          <sidebar-item :link="{name: 'Serviços Extra', path: '/admin/extraservice'}"></sidebar-item>
          <sidebar-item :link="{name: 'Mensagens', path: '/admin/boxmessage'}"></sidebar-item>
          <sidebar-item :link="{name: 'Taxa Paypal', path: '/admin/paypalrate'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{name: 'Configuração de Frete', icon: 'fa fa-shopping-bag'}"
          v-show="isAdmin"
        >
          <sidebar-item :link="{name: 'Opções de Envio', path: '/admin/shipping/shippingoption'}"></sidebar-item>
          <sidebar-item :link="{name: 'Frete', path: '/admin/shipping/shipping'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{name: 'Configuração de Lojas', icon: 'fa fa-shopping-bag'}"
          v-show="isAdmin"
        >
          <sidebar-item :link="{name: 'Categorias', path: '/admin/wherebuy/category'}"></sidebar-item>
          <sidebar-item :link="{name: 'Lojas', path: '/admin/wherebuy/store'}"></sidebar-item>
        </sidebar-item>

        <!-- <sidebar-item :link="{name: 'Afiliados', icon: 'fa fa-handshake'}">
          <sidebar-item :link="{name: 'Indicados', path: '/user/affiliate/indicated'}"></sidebar-item>
          <sidebar-item :link="{name: 'Bônus', path: '/user/affiliate/bonus'}"></sidebar-item>
        </sidebar-item>-->

        <sidebar-item
          :link="{name: 'Cadastrar Caixa', icon: 'fa fa-box-open', path: '/admin/box', }"
          v-show="isAdmin"
        ></sidebar-item>
        <!--        <sidebar-item-->
        <!--          :link="{name: 'Caixas Recebidas', icon: 'fa fa-boxes',path: '/admin/viewbox'}"-->
        <!--        ></sidebar-item>-->
        <sidebar-item :link="{name: 'Armazenar', icon: 'fa fa-boxes', path: '/admin/store'}" v-show="isAdmin">
        </sidebar-item>
        <sidebar-item :link="{name: 'Estoque', icon: 'fa fa-pallet', path: '/admin/product'}"></sidebar-item>
        <sidebar-item
          :link="{name: 'Onde Comprar', icon: 'fa fa-shopping-bag', path: '/user/wherebuy'}"
        ></sidebar-item>

        <sidebar-item
          :link="{name: 'Calculadora', icon: 'fa fa-calculator', path: '/user/calculator'}"
        ></sidebar-item>

        <sidebar-item :link="{name: 'Clientes', icon: 'fa fa-users-cog', path: '/admin/viewuser'}" v-show="isAdmin">
        </sidebar-item>

        <sidebar-item :link="{name: 'Clientes', icon: 'fa fa-users-cog'}" v-show="isAdmin">
          <sidebar-item :link="{name: 'Lista de Clientes', path: '/admin/users/list-users'}"></sidebar-item>
          <sidebar-item :link="{name: 'Relatório', path: '/admin/users/list-users-with-shipments'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{name: 'Carrinho - ' + totalProducts, icon: 'fa fa-shopping-cart',path: '/user/cart/newcart'}"
        ></sidebar-item>
        <sidebar-item
          :link="{name: 'Meus Envios', icon: 'fa fa-share-square', path: '/user/cart/send'}"
        ></sidebar-item>

        <sidebar-item :link="{name: 'Envios', icon: 'fa fa-shipping-fast', path: '/admin/cart/new'}" v-show="isAdmin">
        </sidebar-item>

        <sidebar-item :link="{name: 'Sair', icon: 'fa fa-power-off', path: '/logout'}"></sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import TotalProduct from "../Pages/User/Cart/TotalProduct";

export default {
  data() {
    return {
      totalProducts: 0,
      isAdmin: false,
    };
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
    TotalProduct,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  created() {
    EventBus.$on("count-cart", (count) => {
      this.totalProducts = count;
    });
    this.isAdmin = User.isAdmin();
  },
  mounted() {
    this.isAdmin = User.isAdmin();
    this.totalProducts = localStorage["cart_" + User.userProfile().id]
      ? JSON.parse(localStorage["cart_" + User.userProfile().id]).length
      : 0;
  },
};
</script>
