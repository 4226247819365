<template>
  <card class="card">
    <div class="card-header">
      <h4 class="pt-0 mt-0">
        Seu endereço no Brasil
        <svg id="Layer_1" enable-background="new 0 0 512 512" height="30" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg"><g><path d="m508 425.98h-504c-2.209 0-4-1.791-4-4v-331.96c0-2.209 1.791-4 4-4h504c2.209 0 4 1.791 4 4v331.96c0 2.209-1.791 4-4 4z" fill="#4ba543"/><path d="m481.095 86.02v339.96h26.905c2.209 0 4-1.791 4-4v-331.96c0-2.209-1.791-4-4-4z" fill="#43a047"/><path d="m70.122 252.719 183.59-128.017c1.375-.959 3.201-.959 4.576 0l183.59 128.017c2.283 1.592 2.283 4.97 0 6.562l-183.59 128.017c-1.375.959-3.201.959-4.576 0l-183.59-128.017c-2.283-1.592-2.283-4.97 0-6.562z" fill="#fece3f"/><circle cx="256" cy="256" fill="#2387eb" r="74.173"/><path d="m256 181.827c-5.3 0-10.467.566-15.453 1.623 33.546 7.111 58.72 36.886 58.72 72.55s-25.175 65.439-58.72 72.55c4.986 1.057 10.152 1.623 15.453 1.623 40.965 0 74.173-33.208 74.173-74.173s-33.208-74.173-74.173-74.173z" fill="#207bd6"/><path d="m183.12 242.249 143.247 37.202c2.456-7.373 3.806-15.252 3.806-23.451 0-2.563-.13-5.095-.384-7.591l-134.54-34.941c-5.939 8.467-10.15 18.228-12.129 28.781z" fill="#efefef"/><path d="m297.54 271.964 28.827 7.487c2.456-7.373 3.806-15.252 3.806-23.451 0-2.563-.13-5.095-.384-7.591l-32.25-8.376c1.129 5.143 1.729 10.484 1.729 15.967 0 5.481-.6 10.822-1.728 15.964z" fill="#dcdcdc"/></g></svg>
      </h4>
    </div>
    <div role="alert" class="alert alert-danger">
        <span data-notify="message">
          <span>
          <b>Atenção!</b> É de extrema importância que coloque todos os dados desse endereço para que sua encomenda seja identificada.</span>
        </span>
    </div>
    <table class="table">
      <tbody>
        <tr>
          <td width="35%"><strong>Nome Completo</strong></td>
          <td class="td-actions d-flex text-left">{{profile.name || ''}} {{ profile.last_name || ''}}</td>
        </tr>
        <tr>
          <td width="35%"><strong>Endereço </strong></td>
          <td class="td-actions d-flex text-left">Rua Nova nº 104, {{profile.suite || ''}} Euro Fast Box</td>
        </tr>
        <tr>
          <td width="35%"><strong>Cidade / Localidade</strong></td>
          <td class="td-actions d-flex text-left">Macaparana</td>
        </tr>
        <tr>
          <td width="35%"><strong>Bairro</strong></td>
          <td class="td-actions d-flex text-left">Centro</td>
        </tr>
        <tr>
          <td width="35%"><strong>Telefone</strong></td>
          <td class="td-actions d-flex text-left">(081) 9 9205-8052</td>
        </tr>
        <tr>
          <td width="35%"><strong>Estado / Região</strong></td>
          <td class="td-actions d-flex text-left">Pernambuco</td>
        </tr>
        <tr>
          <td width="35%"><strong>Pais</strong></td>
          <td class="td-actions d-flex text-left">Brasil</td>
        </tr>
        <tr>
          <td width="35%"><strong>Código Postal</strong></td>
          <td class="td-actions d-flex text-left">55865-000</td>
        </tr>
      </tbody>
    </table>
  </card>
</template>
<script>
  import { Table as LTable } from 'src/components/index'
  export default {
    name: 'UserAddressBrasil',
    components: {
      LTable
    },
    data (){
      return {
        profile: {
        },
        tableData: [],
      }
    },
      methods: {
      },
    created() {
      this.profile.picture = baseURLIMGUSER+User.userProfile().profile_picture;
      this.profile.name = User.userProfile().name;
      this.profile.last_name = User.userProfile().last_name;
      this.profile.suite = User.userProfile().suite;
      this.profile.created_at = User.userProfile().created_at;
    }
  }
</script>
<style>

</style>
