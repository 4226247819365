<template>
  <div>
    <div class="row mb-2">
      <div class="col-lg-12 pr-0">
        <div class="col-lg-2 offset-lg-10 pr-0">
          <l-button wide type="primary" size="md" @click="createShipping">
            <span class="btn-label">
              <i class="nc-icon nc-send"></i>
            </span>
            &nbsp;&nbsp;Adicionar
          </l-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="m-0 m-0">
            <div
              class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-default mb-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
              <el-input
                type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Pesquiser"
                v-model="searchQuery"
                aria-controls="datatables"
              />
            </div>
            <div class="col-sm-12">
              <el-table stripe style="width: 100%" :data="queriedData" border>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :align="column.align"
                  :fit="column.fit"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column :min-width="80" fixed="right" label="Ações" align="center">
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Editar'"
                      class="btn-warning btn-simple btn-link"
                      @click="editShipping(props.row)"
                    >
                      <i class="fa fa-edit"></i>
                    </a>

                    <a
                      v-tooltip.top-center="'Apagar'"
                      class="btn-danger btn-simple btn-link"
                      @click="shippingDelete(props.$index, props.row)"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">Exibindo de {{from + 1}} até {{to}} de {{total}} registros</p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            ></l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog title="Adicionar nova Opção de envio" :visible.sync="modals.basic">
      <div>
        <form>
          <div class="row">
            <div class="col-md-4">
              <fg-input
                type="text"
                label="Nome"
                name="name"
                v-model="shipping.name"
                v-validate="modelValidations.name"
                :error="getError('name')"
              ></fg-input>
            </div>
            <div class="col-lg-4">
              <div>
                <label>&nbsp;</label><br />
                <el-checkbox-group v-model="shipping.is_dimensional">
                  <el-checkbox-button :label="1"
                    >Considerar peso dimensional</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="col-md-4">
              <fg-input
                type="text"
                label="Tempo de Entrega"
                name="delivery_time"
                v-model="shipping.delivery_time"
                v-validate="modelValidations.delivery_time"
                :error="getError('delivery_time')"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <fg-input
                type="text"
                label="Link"
                name="link"
                v-model="shipping.url_tracking"
                v-validate="modelValidations.url_tracking"
                :error="getError('url_tracking')"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <fg-input
                type="text"
                label="Peso Maximo"
                name="maximum_weight"
                v-model="shipping.maximum_weight"
                v-validate="modelValidations.maximum_weight"
                :error="getError('maximum_weight')"
              ></fg-input>
            </div>

            <div class="col-md-3">
              <fg-input
                type="text"
                label="Peso dimensional"
                name="dimensional_weight"
                v-model="shipping.dimensional_weight"
                v-validate="modelValidations.dimensional_weight"
                :error="getError('dimensional_weight')"
              ></fg-input>
            </div>

            <div class="col-md-3">
              <fg-input
                type="text"
                label="Tamanho Maximo"
                name="maximum_size"
                v-model="shipping.maximum_size"
                v-validate="modelValidations.maximum_size"
                :error="getError('maximum_size')"
              ></fg-input>
            </div>

            <div class="col-md-3">
              <fg-input
                type="text"
                label="Compr. Maximo"
                name="maximum_lenght"
                v-model="shipping.maximum_lenght"
                v-validate="modelValidations.maximum_lenght"
                :error="getError('maximum_lenght')"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <fg-input label="Codigo de Rastreio">
                <radio label="0" v-model="shipping.tracking_code">Sem</radio>
                <radio label="1" v-model="shipping.tracking_code">Com</radio>
              </fg-input>
            </div>

            <div class="col-md-4">
              <fg-input label="Frequencia de partida">
                <radio label="0" v-model="shipping.starting_frequency">Indeterminado</radio>
                <radio label="1" v-model="shipping.starting_frequency">Todos os dias</radio>
              </fg-input>
            </div>

            <div class="col-md-4">
              <fg-input
                type="text"
                label="Valor do Seguro"
                name="secure"
                v-model="shipping.secure"
                v-validate="modelValidations.secure"
                v-mask="['#.##','##.##','###.##']"
                :error="getError('secure')"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-4 col-md-4 col-sm-12">
              <el-select
                name="Layout"
                class="select-default d-block m-1"
                label="Status"
                placeholder="Layout"
                v-model="shipping.layout_declaration"
                block
              >
                <el-option v-for="option in layout_declaration"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </form>
      </div>

      <span v-show="!loading" slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('basic')">cancelar</button>&nbsp;
        <button class="btn btn-success" @click.prevent="validate">salvar</button>
      </span>

      <span v-show="loading" slot="footer" class="dialog-footer">
        <button class="btn btn-default" disabled>cancelar</button>&nbsp;
        <button class="btn btn-default" disabled>salvar</button>
      </span>
    </el-dialog>

    <el-dialog title="Editar Opção de envio" :visible.sync="modals.edit">
      <div>
        <form>
          <div class="row">
            <div class="col-md-4">
              <fg-input type="text" label="Nome" name="name" v-model="fieldEditShipping.name"></fg-input>
            </div>
            <div class="col-lg-4">
              <div>
                <label>&nbsp;</label><br />
                <el-checkbox-group v-model="fieldEditShipping.is_dimensional">
                  <el-checkbox-button :label="1"
                    >Considerar peso dimensional</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="col-md-4">
              <fg-input
                type="text"
                label="Tempo de Entrega"
                name="delivery_time"
                v-model="fieldEditShipping.delivery_time"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <fg-input
                type="text"
                label="Link"
                name="link"
                v-model="fieldEditShipping.url_tracking"
                :error="getError('url_tracking')"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <fg-input
                type="text"
                label="Peso Maximo"
                name="maximum_weight"
                v-model="fieldEditShipping.maximum_weight"
              ></fg-input>
            </div>

            <div class="col-md-3">
              <fg-input
                type="text"
                label="Peso dimensional"
                name="dimensional_weight"
                v-model="fieldEditShipping.dimensional_weight"
              ></fg-input>
            </div>

            <div class="col-md-3">
              <fg-input
                type="text"
                label="Tamanho Maximo"
                name="maximum_size"
                v-model="fieldEditShipping.maximum_size"
              ></fg-input>
            </div>

            <div class="col-md-3">
              <fg-input
                type="text"
                label="Compr. Maximo"
                name="maximum_lenght"
                v-model="fieldEditShipping.maximum_lenght"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <fg-input label="Codigo de Rastreio">
                <radio label="0" v-model="fieldEditShipping.tracking_code">Sem</radio>
                <radio label="1" v-model="fieldEditShipping.tracking_code">Com</radio>
              </fg-input>
            </div>

            <div class="col-md-4">
              <fg-input label="Frequencia de partida">
                <radio label="0" v-model="fieldEditShipping.starting_frequency">Indeterminado</radio>
                <radio label="1" v-model="fieldEditShipping.starting_frequency">Todos os dias</radio>
              </fg-input>
            </div>

            <div class="col-md-4">
              <fg-input
                type="text"
                label="Valor do Seguro"
                name="secure"
                v-model="fieldEditShipping.secure"
                v-mask="['#.##','##.##','###.##']"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-4 col-md-4 col-sm-12">
              <el-select
                name="Layout"
                class="select-default d-block m-1"
                label="Status"
                placeholder="Layout"
                v-model="fieldEditShipping.layout_declaration"
                block
              >
                <el-option v-for="option in layout_declaration"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </form>
      </div>
      <span v-show="!loading" slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('edit')">cancelar</button>&nbsp;
        <button class="btn btn-success" @click="shippingUpdate">salvar</button>
      </span>

      <span v-show="loading" slot="footer" class="dialog-footer">
        <button class="btn btn-default" disabled>cancelar</button>&nbsp;
        <button class="btn btn-default" disabled>salvar</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pt_BR from "vee-validate/dist/locale/pt_BR";
import {
  Dialog,
  Table,
  TableColumn,
  Select,
  Option,
  Checkbox,
  CheckboxButton,
  CheckboxGroup
} from "element-ui";
import { Pagination as LPagination, Radio } from "src/components/index";
import { mask } from "vue-the-mask";
import Fuse from "fuse.js";

export default {
  components: {
    LPagination,
    Radio,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        this.fuseSearch = new Fuse(this.tableData, { keys: ["name", "price"] });
        result = this.fuseSearch.search(this.searchQuery);
        this.pagination.total = result.length;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  directives: { mask },
  data() {
    return {
      loading: false,
      shippings: {},
      shipping: {
        is_dimensional: "",
        name: "",
        url_tracking: "",
        delivery_time: "",
        maximum_weight: "",
        dimensional_weight: "",
        maximum_size: "",
        maximum_lenght: "",
        tracking_code: "0",
        starting_frequency: "0",
        secure: "0.00",
        layout_declaration: "",
      },
      fieldEditShipping: {
        id: null,
        is_dimensional: "",
        name: "",
        url_tracking: "",
        delivery_time: "",
        maximum_weight: "",
        dimensional_weight: "",
        maximum_size: "",
        maximum_lenght: "",
        tracking_code: "0",
        starting_frequency: "0",
        secure: "0.00",
        layout_declaration: "",
      },
      layout_declaration: [
        {
          label: 'CN22',
          value: 'CN22',
        },
        {
          label: 'DEFAULT',
          value: 'DEFAULT',
        }
      ],
      modals: {
        basic: false,
        edit: false,
        centered: true,
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name"],
      tableColumns: [
        { prop: "name", label: "Opção de Envio", fit: true },
        { prop: "delivery_time", label: "Tempo de Entrega", fit: true },
        { prop: "maximum_weight", label: "Peso Máximo", fit: true },
        { prop: "dimensional_weight", label: "Peso dimensional", fit: true },
        { prop: "maximum_size", label: "Tamanho Máx", fit: true },
        { prop: "maximum_lenght", label: "Comprimento Máx.", fit: true },
        { prop: "view_tracking_code", label: "Codigo de rastreio", fit: true },
        {
          prop: "view_starting_frequency",
          label: "Fre. de partida",
          fit: true,
        },
        { prop: "view_secure", label: "Seguro", fit: true },
      ],
      tableData: [],
      fuseSearch: null,
      modelValidations: {
        name: {
          required: true,
        },
        delivery_time: {
          required: true,
        },
        maximum_weight: {
          required: true,
        },
        dimensional_weight: {
          required: true,
        },
        maximum_size: {
          required: true,
        },
        maximum_lenght: {
          required: true,
        },
        tracking_code: {
          required: true,
        },
        starting_frequency: {
          required: true,
        },
        secure: {
          required: true,
        },
      },
    };
  },
  methods: {
    createShipping() {
      this.modals["basic"] = true;
    },
    editShipping(row) {
      this.fieldEditShipping.id = row.id;
      this.fieldEditShipping.is_dimensional = row.is_dimensional;
      this.fieldEditShipping.name = row.name;
      this.fieldEditShipping.url_tracking = row.url_tracking;
      this.fieldEditShipping.delivery_time = row.delivery_time;
      this.fieldEditShipping.maximum_weight = row.maximum_weight;
      this.fieldEditShipping.dimensional_weight = row.dimensional_weight;
      this.fieldEditShipping.maximum_size = row.maximum_size;
      this.fieldEditShipping.maximum_lenght = row.maximum_lenght;
      this.fieldEditShipping.tracking_code = row.tracking_code;
      this.fieldEditShipping.starting_frequency = row.starting_frequency;
      this.fieldEditShipping.secure = row.secure;
      this.fieldEditShipping.layout_declaration = row.layout_declaration;
      this.modals["edit"] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    addShipping() {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .post("/shippingoption", this.shipping)
        .then(() => {
          this.$notify({
            message: "Dados inseridos com sucesso!",
            type: "success",
            icon: "fa fa-check",
          });
          this.loading = false;
          this.$Progress.finish();
          this.shipping = "";
          this.loadShippings();
          this.closeModal("basic");
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.closeModal("basic");
          this.loading = false;
          this.$Progress.fail();
        });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.localize("pt_BR", pt_BR);
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.addShipping();
        }
      });
    },
    shippingUpdate() {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .put(
          "/shippingoption/" + this.fieldEditShipping.id,
          this.fieldEditShipping
        )
        .then(() => {
          this.$notify({
            message: "Dados inseridos com sucesso!",
            type: "success",
            icon: "fa fa-check",
          });
          this.loading = false;
          this.$Progress.finish();
          this.loadShippings();
          this.closeModal("edit");
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadShippings();
          this.closeModal("basic");
          this.loading = false;
          this.$Progress.fail();
        });
    },
    loadShippings() {
      axios
        .get("/shippingoption")
        .then(({ data }) => (this.tableData = data.data))
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
        });
    },
    shippingDelete(index, row) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      this.$Progress.start();
      swal({
        title: "Você tem certeza?",
        text: "Você não poderá recuperar este registro!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, apague isto!",
        cancelButtonText: "Não, mantenha",
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        buttonsStyling: false,
      }).then((result) => {
        // Send request to the server
        if (result.value) {
          axios
            .delete("/shippingoption/" + row.id)
            .then(() => {
              if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
              }
              swal({
                title: "Apagado!",
                text: "Seu registro foi apagado.",
                type: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
              this.$Progress.finish();
              this.$notify({
                message: "Dados inseridos com sucesso!",
                type: "success",
                icon: "fa fa-check",
              });
            })
            .catch((error) => {
              if (error) {
                Exception.handle(error);
              }
              swal({
                title: "Erro!",
                text: "Há algo errado.",
                type: "warning",
                confirmButtonClass: "btn btn-danger btn-fill",
                buttonsStyling: false,
              });
              this.$Progress.fail();
            });
        }
      });
    },
  },
  mounted() {
    this.loadShippings();
  },
};
</script>
<style>
.swal2-container {
  z-index: 10000;
}
</style>
