<template>
  <div class="row">
    <div class="col-12">
      <card class="card text-center">
        <strong>Nº Cliente: {{ suite }}</strong>
      </card>
    </div>
    <div
      class="col-lg-12 col-md-12">
      <div class="card card"><!---->
        <div
          class="card-header">
          <div role="alert" class="alert alert-danger"><span data-notify="message"><span>
      <b>Atenção!</b>  É de extrema importância que você leia esses artigos antes de utilizar nossos serviços.</span></span>
          </div>
        </div>
        <div
          class="card-body">
          <a class="nav-link"
             href="https://blog.eurofastbox.com/index.php/2023/11/02/restricoes-de-envios-para-produtos-com-baterias/"
             target="_blank">
            Restrições de Envios para produtos com baterias.
          </a>
          <a class="nav-link" href="https://blog.eurofastbox.com/index.php/2023/11/02/produtos-proibidos-de-enviar/"
             target="_blank">Produtos proibidos de enviar
          </a>
          <a class="nav-link" href="https://blog.eurofastbox.com/index.php/2023/11/02/como-importar-perfumes-da-europa/"
             target="_blank">A Euro Fast Box envia Perfumes?
          </a>
          <a class="nav-link" href="https://blog.eurofastbox.com/index.php/2023/11/02/encomendas-devolvidas-a-euro-fast-box/"
             target="_blank">Encomenda devolvida a Euro Fast Box
          </a>
        </div>

      </div>

      <div class="row" v-show="isAdmin">
        <div class="col-md-3">
          <router-link to="/admin/users/list-users">
            <stats-card :title="users" subTitle="clientes" class="cardhover">
              <div slot="header" class="icon-warning">
                <i class="fa fa-users-cog text-danger"></i>
              </div>
              <template slot="footer">
                <i class="fa fa-info"></i>
                clientes
              </template>
            </stats-card>
          </router-link>
        </div>
        <div class="col-md-3">
          <router-link to="/admin/store">
            <stats-card :title="store" subTitle="Produtos" class="cardhover">
              <div slot="header" class="icon-warning">
                <i class="fa fa-boxes text-warning"></i>
              </div>
              <template slot="footer">
                <i class="fa fa-info"></i>
                armazenar
              </template>
            </stats-card>
          </router-link>
        </div>
        <div class="col-md-3">
          <router-link to="/admin/product">
            <stats-card :title="products" subTitle="produtos" class="cardhover">
              <div slot="header" class="icon-warning">
                <i class="fa fa-shopping-basket text-primary"></i>
              </div>
              <template slot="footer">
                <i class="fa fa-info"></i>
                produtos
              </template>
            </stats-card>
          </router-link>
        </div>
        <div class="col-md-3">
          <router-link to="/admin/cart/new">
            <stats-card :title="envios" subTitle="envios" class="cardhover">
              <div slot="header" class="icon-warning">
                <i class="fa fa-shipping-fast text-success"></i>
              </div>
              <template slot="footer">
                <i class="fa fa-info"></i>
                envios
              </template>
            </stats-card>
          </router-link>
        </div>
      </div>

      <div class="row" v-show="isAdmin">
        <div class="col-md-4">
          <router-link to="/admin/cart/new/6">
            <stats-card :title="pendentes" subTitle="pendentes" class="cardhover">
              <div slot="header" class="icon-warning">
                <i class="fa fa-shipping-fast text-success"></i>
              </div>
              <template slot="footer">
                <i class="fa fa-info"></i>
                envios pendentes
              </template>
            </stats-card>
          </router-link>
        </div>
        <div class="col-md-4">
          <router-link to="/admin/cart/new/9">
            <stats-card :title="despacho" subTitle="despacho" class="cardhover">
              <div slot="header" class="icon-warning">
                <i class="fa fa-shipping-fast text-info"></i>
              </div>
              <template slot="footer">
                <i class="fa fa-info"></i>
                em despacho
              </template>
            </stats-card>
          </router-link>
        </div>
        <div class="col-md-4">
          <router-link to="/admin/cart/new/8">
            <stats-card :title="aprovacao" subTitle="aprovacao" class="cardhover">
              <div slot="header" class="icon-warning">
                <i class="fa fa-shipping-fast text-warning"></i>
              </div>
              <template slot="footer">
                <i class="fa fa-info"></i>
                aguardando aprovação
              </template>
            </stats-card>
          </router-link>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-lg-6 col-md-6">
          <user-address></user-address>
        </div>

        <div class="col-lg-6 col-md-6">
          <user-address-brazil></user-address-brazil>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-6 col-md-6">
          <template slot="header"></template>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              type="text/html"
              src="https://www.youtube.com/embed/yxnfEzz0_cI"
            >
            </iframe>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <template slot="header"></template>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              type="text/html"
              src="https://www.youtube.com/embed/rnMgVjXRDkI"
            >
            </iframe>
          </div>
        </div>

      </div>

      <div class="row mb-3">
        <div class="col-lg-6 col-md-6">
          <template slot="header"></template>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              type="text/html"
              src="https://www.youtube.com/embed/KwMKp7d3y2w"
            >
            </iframe>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12">
          <card class="card" title="Instruções de uso."
                subTitle="Utilize seu endereço nos Europa para começar a fazer suas compras.">
            <p class="card-category">
              A Euro Fast Box tem Armazém em Portugal e no Brasil. O seu endereço na Europa é para você fazer compras na
              Europa. O seu endereço no Brasil é para você fazer compras no Brasil
            </p>
            <p class="card-category">
              É de extrema importância que você coloque todos os dados que está no seu endereço, inclusive o campo
              ´´Endereço 2´´ que é o seu número de cliente (suite) para que possamos identificar suas compras.
            </p>
            <p class="card-category pt-2">
              Em <strong>ONDE COMPRAR</strong> temos algumas sugestões de lojas para te ajudar a encontrar o que
              procura.
            </p>
            <p class="card-category pt-2">
              <strong>60 dias de armazenamento gratuito.</strong><br/>
              Todas as compras recebidas são cadastradas em seu painel de controle em até 24h uteis após o recebimento.
              Você será avisado por e-mail a cada recebimento.
            </p>
            <p class="card-category pt-2">
              <strong>Compre em quantas lojas quiser.</strong><br/>
              Você pode comprar em varias lojas e juntar suas compras em um único envio.
            </p>
            <p class="card-category pt-2">
              Solicite o envio quando quiser, selecionando os itens na aba <strong>Estoque</strong> e adicione seus
              produtos ao carrinho.
            </p>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6">
          <card class="card" title="Horários" subTitle="Horario de funcionamento">
            <p class="card-category">
              <i class="fa fa-clock"></i>
              Das 10:30h as 20:00h horário de PORTUGAL seg a sex
            </p>
            <p class="card-category">
              <i class="fa fa-at"></i>
              Email - contato@eurofastbox.com
            </p>
          </card>
        </div>

        <div class="col-lg-6 col-md-6">
          <card
            class="card"
            title="Prazos"
            subTitle="Cadastro de caixas e envios"
          >
            <p class="card-category">
              <i class="fa fa-box-open"></i>
              Cadastro em até 24 horas úteis após recebimento
            </p>
            <p class="card-category">
              <i class="fa fa-shipping-fast"></i>
              Envio em até 48 horas úteis após confirmação do pagamento.
            </p>
          </card>
        </div>
      </div>
      <el-dialog title="Menssagem" :visible.sync="modals.message" width="40%">

        <div class="row">
          <div class="col-md-12">
            <pre v-html="message.message" class="wrap"></pre>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
        <button class="btn btn-primary" @click="closeModalRead('message')">
          marcar como lida</button
        >&nbsp;
        <button class="btn btn-danger" @click="closeModal('message')">
          fechar</button
        >&nbsp;
      </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "../Pages/UserProfile/EditProfileForm";
import UserAddress from "../Pages/UserProfile/UserAddress.vue";
import UserAddressBrazil from "../Pages/UserProfile/UserAddressBrazil.vue";
import {StatsCard} from "src/components/index";
import {VueEditor} from "vue2-editor";

export default {
  components: {
    EditProfileForm,
    UserAddress,
    UserAddressBrazil,
    StatsCard,
    VueEditor,
  },
  data() {
    return {
      suite: '',
      isAdmin: false,
      products: "",
      users: "",
      envios: "",
      boxes: "",
      pendentes: "",
      aprovacao: "",
      despacho: "",
      store: "Armazenar",
      linkAfiliate: "",
      message: "",
      read: {
        box_message_id: "",
      },
      modals: {
        message: false,
      },
    };
  },
  methods: {
    loadMDashboard() {
      axios.get("dashboard").then(({data}) => {
        this.products = data.data[0].products;
        this.users = data.data[0].users;
        this.envios = data.data[0].envios;
        this.boxes = data.data[0].boxes;
        this.pendentes = data.data[0].pendentes;
        this.aprovacao = data.data[0].aprovacao;
        this.despacho = data.data[0].despacho;
      });
    },
    addCopy() {
      let copyLink = document.querySelector("#link-code");
      copyLink.setAttribute("type", "text");
      copyLink.select();
      document.execCommand("copy");
      copyLink.setAttribute("type", "hidden");
    },
    closeModalRead(name) {
      axios.post("/boxreadmessage", this.read).then(() => {
        this.modals[name] = false;
      });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal() {
      axios.get("boxmessage/1").then(({data}) => {
        if (data.message) {
          this.message = data;
          this.read.box_message_id = this.message.id;
          this.modals.message = true;
        }
      });
    },
  },
  created() {
    this.isAdmin = User.isAdmin();
    this.linkAfiliate =
      "https://app.eurofastbox.com/register/" + User.userSuite();
    this.loadMDashboard();
    this.openModal();
    this.suite = User.userProfile().suite;
  },
  mounted() {
    this.isAdmin = User.isAdmin();
    this.loadMDashboard();
  },
};
</script>
<style>
.cardhover {
  transition: height 0.3s, box-shadow 0.3s;
}

.cardhover:hover {
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.2);
}

.wrap {
  width: 600px;
  min-height: 15px;
  height: auto !important;
  color: #666;
  padding: 1%;
  font-size: 14px;
  font-weight: normal;
  word-wrap: break-word;
  text-align: left;
}
</style>
