<template>
  <div>
  <router-view></router-view>
  <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
  import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
  import TotalProduct from 'src/pages/Dashboard/Pages/User/Cart/TotalProduct';
  export default {
    mounted () {
      this.$notify(
        {
          component: NotificationSample,TotalProduct,
          icon: 'nc-icon nc-bell-55'
        });
    }
  }
</script>
