<template>
  <div>
    <div class="row mb-2">
      <div class="col-lg-12 pr-0">
        <div class="col-lg-2 offset-lg-10 pr-0">
          <l-button wide type="primary" size="md" @click="createStore">
                <span class="btn-label">
                    <i class="nc-icon nc-send"></i>
                </span>
            &nbsp;&nbsp;Adicionar
          </l-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="m-0 m-0">
            <div class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-default mb-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <el-input type="search"
                        class="mb-3"
                        style="width: 200px"
                        placeholder="Pesquiser"
                        v-model="searchQuery"
                        aria-controls="datatables"
              />
            </div>
            <div class="col-sm-12">
              <el-table stripe
                        style="width: 100%"
                        :data="queriedData"
                        border>

                <el-table-column min-width="220" label="Logomarca">
                  <template slot-scope="{row}">
                    <div class="img-container">
                      <img :src="storeImage(row)" alt="Foto">
                    </div>
                  </template>
                </el-table-column>

                <el-table-column v-for="column in tableColumns"
                                 :key="column.label"
                                 :min-width="column.minWidth"
                                 :align="column.align"
                                 :fit="column.fit"
                                 :prop="column.prop"
                                 :label="column.label">
                </el-table-column>

                <el-table-column
                  :min-width="80"
                  fixed="right"
                  label="Ações"
                  align="center"
                >
                  <template slot-scope="props">

                    <a v-tooltip.top-center="'Editar'" class="btn-warning btn-simple btn-link"
                       @click="editStore(props.row)">
                      <i class="fa fa-edit"></i>
                    </a>

                    <a v-tooltip.top-center="'Apagar'" class="btn-danger btn-simple btn-link"
                       @click="deleteStore(props.$index, props.row)">
                      <i class="fa fa-times"></i>
                    </a>

                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">Exibindo de {{from + 1}} até {{to}} de {{total}} registros</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog
      title="Adicionar nova Loja"
      :visible.sync="modals.basic"
      width="40%"
      >
      <div>
        <form>
          <div class="row">
              <div class="col-md-6">
                 <h5 class="title">Link</h5>
                <fg-input type="text"
                          name="link"
                          v-model="store.link"
                          v-validate="modelValidations.link"
                          :error="getError('name')"
                >
                </fg-input>
              </div>
                  <div class="col-md-6">
                     <h5 class="title">Categoria</h5>
                        <el-select
                                  label="Categoria"
                                  multiple
                                  class="select-primary"
                                  v-model="store.category"
                                  placeholder="Categorias">
                          <el-option v-for="option in categories.category"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.label">
                          </el-option>
                        </el-select>
                    </div>
          </div>
              <div class="row">
                <div class="col-lg-6">
                  <h5 class="title">Logomarca</h5>
                  <label class="file-select">
                    <div class="btn btn-info btn-fill btn-md">
                      <span v-if="store.image">{{store.image.name}}</span>
                      <span v-else>Foto da Caixa</span>
                    </div>
                    <input class="mb-0" label="Foto da Caixa"
                           placeholder="Foto da Caixa"
                           type="file"
                           id="image" ref="image"
                           v-on:change="onChangeStoreImage()"
                           accept="image/*" capture
                    />
                  </label>
                  <!-- <span class="text-danger small m-0 p-0" v-if="error.errors.image">{{ error.errors.image[0] }}</span> -->
                </div>
              </div>

        </form>
      </div>

      <span v-show="!loading" slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">cancelar</button>&nbsp;
              <button class="btn btn-success" @click.prevent="validate">salvar</button>
            </span>

      <span v-show="loading" slot="footer" class="dialog-footer">
              <button class="btn btn-default" disabled>cancelar</button>&nbsp;
              <button class="btn btn-default" disabled>salvar</button>
            </span>
    </el-dialog>


    <el-dialog
      title="Editar Loja"
      :visible.sync="modals.edit">

            <div>
              <form>
                <div class="row">

                  <div class="col-md-6">
                    <h5 class="title">Link</h5>
                      <fg-input type="text"
                                name="link"
                                v-model="fieldEditStore.link"
                      >
                      </fg-input>
                  </div>


                  <div class="col-md-6">
                     <h5 class="title">Categoria</h5>
                        <el-select
                                  label="Categoria"
                                  multiple
                                  class="select-primary"
                                  v-model="fieldEditStore.category"
                                  placeholder="Categorias">
                          <el-option v-for="option in categories.category"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.label">
                          </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                <div class="col-lg-6">
                  <h5 class="title">Logomarca</h5>
                  <label class="file-select">
                    <div class="btn btn-info btn-fill btn-md">
                      <span v-if="fieldEditStore.image">{{fieldEditStore.image.name}}</span>
                      <span v-else>Foto da Caixa</span>
                    </div>
                    <input class="mb-0" label="Foto da Caixa"
                           placeholder="Foto da Caixa"
                           type="file"
                           id="image" ref="image"
                           v-on:change="onChangeEditStoreImage()"
                           accept="image/*" capture
                    />
                  </label>
                  <!-- <span class="text-danger small m-0 p-0" v-if="error.errors.image">{{ error.errors.image[0] }}</span> -->
                </div>
              </div>


              </form>
            </div>

            <span v-show="!loading" slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('edit')">cancelar</button>&nbsp;
              <button class="btn btn-success" @click="updateStore">salvar</button>
            </span>

            <span v-show="loading" slot="footer" class="dialog-footer">
              <button class="btn btn-default" disabled>cancelar</button>&nbsp;
              <button class="btn btn-default" disabled>salvar</button>
            </span>
    </el-dialog>

  </div>

</template>
<script>
    import pt_BR from 'vee-validate/dist/locale/pt_BR';
    import { Dialog, Table, TableColumn, Select, Option } from 'element-ui'
    import {Pagination as LPagination} from 'src/components/index'
    import Fuse from 'fuse.js'

    export default {
        components: {
            LPagination,
            [Dialog.name]: Dialog,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        computed: {
            pagedData () {
                return this.tableData.slice(this.from, this.to)
            },
            queriedData () {
                let result = this.tableData;
                if (this.searchQuery !== '') {
                    this.fuseSearch = new Fuse(this.tableData, { keys: ['name','payment_gateway'] });
                    result = this.fuseSearch.search(this.searchQuery);
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to () {
                let highBound = this.from + this.pagination.perPage;
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total () {
                this.pagination.total = this.tableData.length;
                return this.tableData.length
            }
        },
        data () {
            return {
                loading: false,
                stores: {},
                store:{
                    link: '',
                    image: '',
                    category: [],
                },
                fieldEditStore:{
                    id: null,
                    link: '',
                    image: '',
                    category: [],
                },
                modals: {
                    basic: false,
                    edit: false,
                    centered: true,
                },
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['link'],
                tableColumns: [
                    { prop: 'link', label: 'link', align: 'center', fit: true},
                    { prop: 'active', label: 'ativo', align: 'center', fit: true},
                ],
                tableData: [],
                fuseSearch: null,
                modelValidations: {
                    name: {
                        link: true,
                    },
                },
                categories: {
                  simple: '',
                  category:[],
                  multiple: 'ARS'
                },
            }
        },
        methods: {
            storeImage (row) {
              return baseURLIMG+'/store/thumbnail_'+ row.image;
            },
            onChangeStoreImage(){
              this.store.image = this.$refs.image.files[0];
            },
            onChangeEditStoreImage(){
              this.fieldEditStore.image = this.$refs.image.files[0];
            },
            createStore () {
                this.modals['basic'] = true
            },
            editStore (row) {
                this.fieldEditStore.id=row.id;
                this.fieldEditStore.link=row.link;
                this.fieldEditStore.category=row.category_selected;
                this.modals['edit'] = true;
            },
            closeModal (name) {
                this.modals[name] = false
            },
            addStore () {
                this.loading = true;
                this.$Progress.start();

                let formData = new FormData();

                formData.append('image', this.store.image);
                formData.append('link', this.store.link);
                formData.append('category', this.store.category);

                this.$notify(
                    {
                        message: 'Aguarde estamos trabalhando em sua solicitação!',
                        type: 'info',
                        icon: 'fa fa-check',
                        horizontalAlign: 'left',
                        verticalAlign: 'bottom',
                    });
                axios.post('/store',formData,
                      {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      }
                ).then(()=>{
                    this.$notify(
                        {
                            message: 'Dados inseridos com sucesso!',
                            type: 'success',
                            icon: 'fa fa-check'
                        });
                    this.loading = false;
                    this.$Progress.finish();
                    this.store.link='';
                    this.loadStore();
                    this.closeModal('basic');
                }).catch((error)=>{
                    if(error){
                        Exception.handle(error);
                    }
                    swal({
                        title:"Erro!",
                        text: "Há algo errado.",
                        type: "warning",
                        confirmButtonClass: 'btn btn-danger btn-fill',
                        buttonsStyling: false
                    });
                    this.closeModal('basic');
                    this.loading = false;
                    this.$Progress.fail();
                });
            },
            getError (fieldName) {
                return this.errors.first(fieldName)
            },
            validate () {
                this.$validator.localize('pt_BR', pt_BR);
                this.$validator.validateAll().then(isValid => {
                    if(isValid){
                        this.addStore();
                    }
                })
            },
            updateStore () {
                this.loading = true;
                this.$Progress.start();

                let formData = new FormData();

                formData.append('image', this.fieldEditStore.image);
                formData.append('link', this.fieldEditStore.link);
                formData.append('category', this.fieldEditStore.category);


                this.$notify(
                    {
                        message: 'Aguarde estamos trabalhando em sua solicitação!',
                        type: 'info',
                        icon: 'fa fa-check',
                        horizontalAlign: 'left',
                        verticalAlign: 'bottom',
                    });

                axios.post('/store/update/'+this.fieldEditStore.id,formData,
                      {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      }
                ).then(()=>{
                    this.$notify(
                        {
                            message: 'Dados inseridos com sucesso!',
                            type: 'success',
                            icon: 'fa fa-check'
                        });
                    this.loading = false;
                    this.$Progress.finish();
                    this.loadStore();
                    this.closeModal('edit');
                }).catch((error)=>{
                    if(error){
                        Exception.handle(error);
                    }
                    swal({
                        title:"Erro!",
                        text: "Há algo errado.",
                        type: "warning",
                        confirmButtonClass: 'btn btn-danger btn-fill',
                        buttonsStyling: false
                    });
                    this.loadStore();
                    this.closeModal('basic');
                    this.loading = false;
                    this.$Progress.fail();
                });
            },
            loadStore(){
                axios.get("/store")
                    .then(({ data }) => (
                        this.tableData = data.data
                    ))
                    .catch((error)=> {
                        if (error) {
                            Exception.handle(error);
                        }
                    });
            },
            loadCategory(){
                axios.get("/category/1")
                    .then(({ data }) => (
                        this.categories.category = data.data
                    ))
                    .catch((error)=> {
                        if (error) {
                            Exception.handle(error);
                        }
                    });
            },
            deleteStore (index, row) {
                let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
                this.$Progress.start();
                swal({
                    title: 'Você tem certeza?',
                    text: 'Você não poderá recuperar este registro!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim, apague isto!',
                    cancelButtonText: 'Não, mantenha',
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    buttonsStyling: false,
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.delete('/store/'+row.id).then(()=>{
                            if (indexToDelete >= 0) {
                                this.tableData.splice(indexToDelete, 1)
                            }
                            swal({
                                title: 'Apagado!',
                                text: 'Seu registro foi apagado.',
                                type: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            });
                            this.$Progress.finish();
                            this.$notify(
                                {
                                    message: 'Dados inseridos com sucesso!',
                                    type: 'success',
                                    icon: 'fa fa-check'
                                });
                        }).catch((error)=>{
                            if(error){
                                Exception.handle(error);
                            }
                            swal({
                                title:"Erro!",
                                text: "Há algo errado.",
                                type: "warning",
                                confirmButtonClass: 'btn btn-danger btn-fill',
                                buttonsStyling: false
                            });
                            this.$Progress.fail();
                        });
                    }
                })
            },
        },
        mounted () {
            this.loadStore();
            this.loadCategory();
        },
    }
</script>
<style>
  .swal2-container {
    z-index: 10000;
  }
</style>
