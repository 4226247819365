<template>
  <card class="card" title="Atualizar endereço" subTitle="endereço de recebimento.">
    <div>
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="text"
                      label="Endereço"
                      name="address"
                      v-model="address.full_name"
                      v-validate="modelValidations.full_name"
                      :error="getError('address')"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Endereço"
                      name="address"
                      v-model="address.address"
                      v-validate="modelValidations.address"
                      :error="getError('address')"
            >
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="Bairro"
                      name="district"
                      v-model="address.district"
                      v-validate="modelValidations.district"
                      :error="getError('district')"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <fg-input type="text"
                      label="Nº"
                      name="number"
                      v-model="address.number"
                      v-validate="modelValidations.number"
                      :error="getError('number')"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="text"
                      label="Complemento"
                      name="complement"
                      v-model="address.complement"
                      v-validate="modelValidations.complement"
                      :error="getError('complement')"
            >
            </fg-input>
          </div>
          <div class="col-md-6">
            <div class="form-group has-label">
              <label>País</label>
              <el-select class="select-default"
                         size="large"
                         name="country"
                         placeholder="Selecione o País"
                         v-model="address.country"
                         @change="loadState"
              >
                <el-option v-for="option in selects.countries"
                           class="select-primary"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group has-label">
              <label>Estado</label>
              <el-select class="select-default"
                         size="large"
                         name="country"
                         placeholder="Selecione o País"
                         v-model="address.state"
              >
                <el-option v-for="option in selects.states"
                           class="select-primary"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-5">
            <fg-input type="text"
                      label="Cidade"
                      name="city"
                      v-model="address.city"
                      v-validate="modelValidations.city"
                      :error="getError('city')"
            >
            </fg-input>
          </div>
          <div class="col-md-3">
            <fg-input type="text"
                      label="Cep"
                      name="post_code"
                      v-model="address.post_code"
                      v-validate="modelValidations.post_code"
                      :error="getError('post_code')"
            >
            </fg-input>
          </div>
        </div>


        <div class="text-left">
          <button  v-show="!loading" type="submit" class="btn btn-info btn-fill btn-wd"  @click.prevent="validate">
            <i class="fa fa-save"></i>
            atualizar
          </button>

          <button  v-show="loading" class="btn btn-default btn-fill btn-wd disabled" disabled>
            <i class="spinner-grow spinner-grow-sm"></i>&nbsp;&nbsp;
            aguarde
          </button>

        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>

</template>
<script>
  import { Select, Option } from 'element-ui'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data () {
      return {
        loading: false,
        selects: {
          countries: [],
          states: []
        },
        address:{},
        modelValidations: {
          full_name: {
            required: true,
          },
          address: {
            required: true,
          },
        }
      }
    },
    methods: {
      updateAddress () {
        this.loading = true;
        this.$Progress.start();
        axios.put('settingsaddress/'+this.address.id,this.address).then(()=>{
          this.$notify(
            {
              message: 'Dados atualizados com sucesso!',
              type: 'success',
              icon: 'fa fa-check'
            });
          this.loading = false;
          this.$Progress.finish();
          this.loadAddress();
        }).catch((error)=>{
          if(error){
            Exception.handle(error);
          }
          swal({
            title:"Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false
          });
          this.loading = false;
          this.$Progress.fail();
        });
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          if(isValid){
            this.updateAddress();
          }
        })
      },
      loadAddress(){
        this.$Progress.start();
        axios.get("settingsaddress")
          .then(({ data }) => (
            this.address = data.data[0]
          )).catch((error)=>{
          if(error){
            Exception.handle(error);
          }
          swal({
            title:"Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false
          });
          this.$Progress.fail();
        });
        this.$Progress.finish();
      },
      loadCountry(){
        this.$Progress.start();
        axios.get("country")
          .then(({ data }) => (
            this.selects.countries = data.data
          )).catch((error)=>{
          if(error){
            Exception.handle(error);
          }
          swal({
            title:"Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false
          });
          this.$Progress.fail();
        });
        this.$Progress.finish();
      },
      loadState(){
        this.$Progress.start();
        let country = this.address.country;
        if (!country){
          country = '';
        }
        axios.get("state?country="+country)
          .then(({ data }) => (
            this.selects.states = data.data
          )).catch((error)=>{
          if(error){
            Exception.handle(error);
          }
          swal({
            title:"Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false
          });
          this.$Progress.fail();
        });
        this.$Progress.finish();
      },
    },
    mounted () {
      this.loadAddress();
      this.address.country = 'Brazil';
      this.loadCountry();
      this.loadState();
    },
  }

</script>
<style>

</style>
