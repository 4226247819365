<template>
  <card class="card" title="Editar dados" subTitle="Atualize suas informações">
    <div>
      <form>
        <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        label="Nome"
                        name="name"
                        v-model="user.name"
                        v-validate="modelValidations.name"
                        :error="getError('name')"
              >
              </fg-input>
            </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="Sobrenome"
                      name="last_name"
                      v-model="user.last_name">
            </fg-input>
          </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <fg-input type="text"
                        label="Email"
                        name="email"
                        :disabled="true"
                        v-model="user.email"
                        v-validate="modelValidations.email"
                        :error="getError('email')"
              >
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text"
                        label="Celular/WhatsApp"
                        name="cell_phone"
                        :disabled="false"
                        v-model="user.cell_phone">
              </fg-input>
            </div>
          </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input type="password"
                      label="Senha"
                      ref="password"
                      name="password"
                      placeholder="senha"
                      v-model="user.password"
                      v-validate="modelValidations.password"
                      :error="getError('password')"
            >
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="password"
                      label="Confirme Senha"
                      name="password_confirmation"
                      placeholder="Confirme Senha"
                      v-model="user.password_confirmation"
                      v-validate="user.password ? 'required|confirmed:password' : ''"
                      :error="getError('password_confirmation')"
            >
            </fg-input>
          </div>
        </div>


        <div class="text-left">
          <button  v-show="!loading" type="submit" class="btn btn-info btn-fill btn-wd"  @click.prevent="validate">
            <i class="fa fa-save"></i>
           atualizar perfil
          </button>

          <button  v-show="loading" class="btn btn-default btn-fill btn-wd disabled" disabled>
            <i class="spinner-grow spinner-grow-sm"></i>&nbsp;&nbsp;
           aguarde
          </button>

        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>


</template>
<script>
  export default {
    data () {
      return {
        loading: false,
        addresses:{},
        user: {
          id: User.userProfile().id,
          name: User.userProfile().name,
          last_name: User.userProfile().last_name,
          email: User.userProfile().email,
          cell_phone: User.userProfile().cell_phone,
        },
        modelValidations: {
          email: {
            required: true,
            email: true
          },
          name: {
            required: true
          },
          password: {
            min: 6
          },
          password_confirmation: {
            confirmed: 'password'
          }
        }
      }
    },
    methods: {
      updateProfile () {
        this.loading = true;
        this.$Progress.start();
        axios.put('user/'+this.user.id,this.user).then((res)=>{
          User.clearSetUser(res.data.data[0]);
          this.$notify(
            {
              message: 'Dados atualizados com sucesso!',
              type: 'success',
              icon: 'fa fa-check'
            });
          this.loading = false;
          this.$Progress.finish();
          location.reload();
        }).catch((error)=>{
            if(error){
              Exception.handle(error);
            }
            swal({
              title:"Erro!",
              text: "Há algo errado.",
              type: "warning",
              confirmButtonClass: 'btn btn-danger btn-fill',
              buttonsStyling: false
            });
            this.loading = false;
            this.$Progress.fail();
          });
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          if(isValid){
            this.updateProfile();
          }
        })
      }
    },
    created() {
    }
  }

</script>
<style>

</style>
