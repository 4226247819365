import { sync } from 'vuex-router-sync'
import store from './store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import Tawk from 'vue-tawk';

// Vue.use(Tawk, {
//   tawkSrc: 'https://embed.tawk.to/5ce96c042135900bac127d9e/default'
// });

import VueProgressBar from 'vue-progressbar'

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters);

import swal from 'sweetalert2';

window.swal = swal;

const options = {
  color: '#4eb7bb',
  failedColor: '#e20e29',
  thickness: '6px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
};

Vue.use(VueProgressBar, options);

import LightBootstrap from './light-bootstrap-main'
// Plugins
import App from './App.vue'

window.axios = require('axios');

axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;
window.baseURL = process.env.VUE_APP_BASE_URI;
window.baseURLIMG = process.env.VUE_APP_BASE_IMG;
window.baseURLIMGUSER = process.env.VUE_APP_BASE_IMG_USER;
Vue.prototype.$APP_DEBUG = process.env.VUE_APP_DEBUG;

let token = localStorage.getItem('token');
const JwtToken = `Bearer ${ token }`;
if (token){
  window.axios.defaults.headers.common['Authorization'] = JwtToken;
  window.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
}

//Helpers
import User from './Helpers/User'
window.User = User;

import Exception from './Helpers/Exception'
window.Exception = Exception;

window.EventBus = new Vue();

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate);


// router setup
import indexOld from '@/routes'
// plugin setup
Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(LightBootstrap);

// configure router
const router = new VueRouter({
  routes: indexOld, // short for routes: routes
  linkActiveClass: 'active',
  hashbang: false,
  mode: 'history'
});

axios.post('/auth/me').then().catch(error => Exception.handle(error));

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login','/regis','/forgo','/reset'];
  const authRequired = !publicPages.includes(to.path.slice(0,6));
  const loggedIn = User.loggedIn();
  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});
