<template>
  <div>
    <div class="row">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="m-0 m-0">
            <div
              class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-default mb-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-input
                type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Pesquisar"
                v-model="searchQuery"
                aria-controls="datatables"
              />
            </div>
            <div class="col-sm-12">
              <el-table stripe
                        style="width: 100%;"
                        :data="queriedData"
                        border>
                <el-table-column min-width="90" label="Foto">
                  <template slot-scope="{ row }">
                    <div class="img-container">
                      <img
                        :src="cartImage(row)"
                        alt="Foto"
                        class="img-thumbnail"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :align="column.align"
                  :fit="column.fit"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column
                  :min-width="250"
                  fixed="right"
                  label="Conteudo"
                  align="center"
                >
                  <template slot-scope="props">
                    <p
                      class="text-body small p-0 m-0"
                      v-for="product in props.row.products"
                      :key="product.id"
                    >
                      Código Produto: {{ product.id }} / Quan:
                      {{ product.amount }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="94"
                  fixed="right"
                  label="Valor"
                  align="center"
                >
                  <template slot-scope="props">
                    <span>{{ cart_total_price[props.row.id] }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="120"
                  fixed="right"
                  label="Rastreio"
                  align="center"
                >
                  <template slot-scope="props">
                    <span> {{ cart_tracking[props.row.id] }} </span>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="180"
                  fixed="right"
                  label="Status"
                  align="center"
                >
                  <template slot-scope="props">
                    <span>{{ cart_status[props.row.id] }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :min-width="80"
                  fixed="right"
                  label="Ações"
                  align="center"
                >
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Detalhes'"
                      class="btn-info btn-simple btn-link"
                      @click="openCartDetails(props.$index, props.row)"
                    >
                      <i class="fa fa-eye"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="" v-if="meta">
              <p class="card-category">Exibindo {{meta.from}} até {{meta.to}} de um total de {{meta.total}} registros</p>
            </div>
            <ul class="pagination">
              <li class="page-item" :class="{active: link.active}" v-for="link in meta.links" :key="link.label">
                <a class="page-link" @click.prevent="changePage(link.label)">{{link.label}}</a>
              </li>
            </ul>
          </div>
        </card>
      </div>
    </div>
    <!-- START DETAILS -->
    <el-dialog :visible.sync="modals.details" width="80%">
      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Detalhes do Envio</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
            <tr>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-1 m-0 text-left text-secondary">
                Envio: #{{ sendId }} &nbsp; &nbsp; Data: {{ sendDate }}
              </td>
            </tr>
          </tbody>
        </table>
      </card>

      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Dados do Cliente.</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
            <tr>
              <th class="text-center">Suite</th>
              <th class="text-center">Nome Completo</th>
              <th class="text-center">Telefone</th>
              <th class="text-center">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-1 m-0 text-center text-secondary" width="5%">
                {{ sendUser.suite }}
              </td>
              <td class="p-1 m-0 text-center text-secondary">
                {{ sendUser.name }} {{ sendUser.last_name }}
              </td>
              <td class="p-1 m-0 text-center text-secondary">
                {{ sendUser.cell_phone }}
              </td>
              <td class="p-1 m-0 text-center text-secondary">
                {{ sendUser.email }}
              </td>
            </tr>
          </tbody>
        </table>
      </card>

      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Dados do Carrinho.</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
            <tr>
              <th class="text-center">valor</th>
              <th class="text-center">rastreio</th>
              <th class="text-center">status</th>
              <th class="text-center">foto</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                <fg-input
                  type="text"
                  v-model="cart_total_price[sendCart.id]"
                  @focusout.self="cartChangeTotalPrice(sendCart.id, sendCart)"
                  v-mask="['#.##', '##.##', '###.##']"
                >
                </fg-input>
              </td>
              <td class="text-center">
                <fg-input
                  type="text"
                  v-model="cart_tracking[sendCart.id]"
                  @focusout.self="cartChangeTracking(sendCart.id, sendCart)"
                >
                </fg-input>
              </td>
              <td class="text-center">
                <el-select
                  class="select-default form-group"
                  size="large"
                  :placeholder="selects.place.label"
                  v-model="cart_status[sendCart.id]"
                  @change="cartChangeStatus(sendCart.id, sendCart)"
                >
                  <el-option
                    v-for="option in selects.cartStatus"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </td>
              <td class="text-center">
                <label class="file-select">
                  <div class="btn btn-info btn-fill btn-md">
                    <span v-if="cartSend.image">{{ cartSend.image.name }}</span>
                    <span v-else>Foto da Caixa</span>
                  </div>
                  <input
                    class="mb-0"
                    label="Foto da Caixa"
                    placeholder="Foto da Caixa"
                    type="file"
                    id="image"
                    ref="image"
                    v-on:change="onChangeCartImage(sendCart.id, sendCart.suite)"
                    accept="image/*"
                    capture
                  />
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </card>

      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Produtos solicitados.</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
            <tr>
              <th class="text-center">Foto</th>
              <th class="text-center">Código Produto</th>
              <th class="text-center">Referência</th>
              <th class="text-center">Qt Estoque</th>
              <th class="text-center">Qt Enviar</th>
              <th class="text-center">Peso</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in sendProducts" :key="product.id">
              <td class="p-1 m-0" width="70" wid>
                <div class="img-fluid" style="cursor: pointer">
                  <img
                    :src="productImage(product)"
                    alt="Foto"
                    class="img-thumbnail"
                    @click="productImageList(product)"
                  />
                </div>
              </td>
              <td class="p-1 m-0 text-center text-secondary" width="120">
                {{ product.id }}
              </td>
              <td class="p-1 m-0 text-center text-secondary">{{ product.reference_code || '-' }}</td>
              <td class="p-1 m-0 text-center text-secondary">
                {{ product.amount_stock }}
              </td>
              <td class="p-1 m-0 text-center text-secondary">
                {{ product.amount }}
              </td>
              <td class="p-1 m-0 text-center text-secondary">
                {{ product.weight }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <table class="table table-responsive-md m-0 p-0">
                  <tr style="border: 0px">
                    <td class="m-0 p-0 b-0" style="border: 0px" width="30%">
                      <span class="text-left">
                        <strong>Total de produstos:</strong>&nbsp;
                      </span>
                    </td>
                    <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                      <span class="text-right">{{ totalQntProduct }}</span>
                    </td>
                  </tr>
                  <tr style="border: 0px">
                    <td class="m-0 p-0 b-0" style="border: 0px">
                      <span class="text-left">
                        <strong>Peso Total dos produtos:</strong>&nbsp;
                      </span>
                    </td>
                    <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                      <span class="text-right"
                        >{{ totalProductWeight }} kg</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="m-0 p-0" style="border: 0px">
                      <span class="text-left">
                        <strong>Peso estimado da caixa:</strong>&nbsp;
                      </span>
                    </td>
                    <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                      <span class="text-right"
                        >{{ totalProductWeightApproximate }} kg</span
                      >
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tfoot>
        </table>
      </card>

      <div class="row">
        <div class="col-md-8">
          <card class="m-0 mb-2">
            <div class="card-header p-0">
              <h5 class="card-title">Endereço de entrega.</h5>
            </div>
            <table class="table table-hover table-responsive-md">
              <tbody>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Apelido do Endereço:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.name_address }}
                  </td>
                  <td
                    class="p-1 m-0 text-left text-secondary font-weight-bold"
                  ></td>
                  <td class="p-1 m-0 text-left text-secondary"></td>
                </tr>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Destinatário:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.recipient_name }}
                  </td>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Telefone destinatário:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.recipient_phone }}
                  </td>
                </tr>

                <tr>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Endereço:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.address }}
                  </td>
                  <td class="p-1 m-0 text-left text-secondary"></td>
                  <td class="p-1 m-0 text-left text-secondary"></td>
                </tr>

                <tr>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Complemento:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.complement }}
                  </td>
                  <td
                    class="p-1 m-0 text-left text-secondary font-weight-bold"
                  ></td>
                  <td class="p-1 m-0 text-left text-secondary"></td>
                </tr>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Cidade / Localidade:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.city }}
                  </td>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Estado / Província / Região:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.state }}
                  </td>
                </tr>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Pais:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.country }}
                  </td>
                  <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                    Código Postal / Cep:
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendAddress.post_code }}
                  </td>
                </tr>
              </tbody>
            </table>
          </card>
        </div>
        <div class="col-md-4">
          <card class="m-0 mb-2">
            <div class="card-header p-0">
              <h5 class="card-title">Opção de pagamento.</h5>
            </div>
            <h5 class="card-title pt-2 pb-2"><strong>Pay Pal</strong></h5>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <card class="m-0 mb-2" v-if="sendDeclarations.length > 0">
            <div class="card-header p-0">
              <h5 class="card-title">Declaração Alfandegaria.</h5>
            </div>
            <table class="table table-hover table-responsive-md">
              <thead>
                <tr>
                  <th>Item</th>
                  <th class="text-center">Quantidade</th>
                  <th class="text-center">Valor Unit.</th>
                  <th class="text-center">Valor Total.</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="declaration in sendDeclarations"
                  :key="declaration.id"
                >
                  <td class="p-1 m-0 text-secondary">{{ declaration.name }}</td>
                  <td class="p-1 m-0 text-center text-secondary">
                    {{ declaration.amount }}
                  </td>
                  <td class="p-1 m-0 text-center text-secondary">
                    {{ declaration.unit_price }}
                  </td>
                  <td class="p-1 m-0 text-center text-secondary">
                    {{ declaration.total_price }}
                  </td>
                </tr>
              </tbody>
            </table>
          </card>

          <card class="m-0 mb-2" v-else>
            <div class="card-header p-0">
              <h5 class="card-title text-center">
                <strong>Sem solicitação de declaração alfandegaria.</strong>
              </h5>
            </div>
          </card>
        </div>

        <div class="col-md-6">
          <card class="m-0 mb-2" v-if="sendServices.length > 0">
            <div class="card-header p-0">
              <h5 class="card-title">Serviços Extras.</h5>
            </div>
            <table class="table table-hover table-responsive-md">
              <thead>
                <tr>
                  <th>Serviço</th>
                  <th class="text-center">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="service in sendServices" :key="service.id">
                  <td class="p-1 m-0 text-center text-secondary">
                    {{ service.name }}
                  </td>
                  <td class="p-1 m-0 text-center text-secondary">
                    {{ service.price }}
                  </td>
                </tr>
              </tbody>
            </table>
          </card>

          <card class="m-0 mb-2" v-else>
            <div class="card-header p-0">
              <h5 class="card-title text-center">
                <strong>Sem solicitação de serviços extras.</strong>
              </h5>
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <card class="m-0 mb-2">
            <div class="card-header p-0">
              <h5 class="card-title">Detalhamento de valores.</h5>
            </div>
            <table class="table table-hover table-responsive-md">
              <thead>
                <tr>
                  <th>Item</th>
                  <th class="text-center">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary">Frete</td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendPrices.shipping_price }}
                  </td>
                </tr>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary">
                    Taxa Redirecionamento
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendPrices.service_price }}
                  </td>
                </tr>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary">
                    Taxa Serviços Extras
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendPrices.extra_service_price }}
                  </td>
                </tr>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary">
                    Taxa PayPal %
                  </td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendPrices.rate_paypal }}
                  </td>
                </tr>
                <tr>
                  <td class="p-1 m-0 text-left text-secondary">Valor Total</td>
                  <td class="p-1 m-0 text-left text-secondary">
                    {{ sendPrices.total_price }}
                  </td>
                </tr>
              </tbody>
            </table>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <card class="m-0 mb-2" v-if="sendFirstObservation.length > 0">
            <div class="card-header p-0">
              <h5 class="card-title">Observações.</h5>
            </div>
            <p>{{ sendFirstObservation[0].text }}</p>
          </card>

          <card class="m-0 mb-2" v-else>
            <div class="card-header p-0">
              <h5 class="card-title text-center">
                <strong>Sem observação na soliciatação do envio.</strong>
              </h5>
            </div>
          </card>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('details')">
          cancelar</button
        >&nbsp;
      </span>
    </el-dialog>
    <!-- END DETAILS -->

    <!-- START IMAGE -->
    <el-dialog
      title="Fotos do Produto"
      :visible.sync="modals.image"
      width="40%"
    >
      <div class="row">
        <div class="col-md-12">
          <span class="img-fluid">
            <img :src="images.one" class="img-fluid" />
          </span>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('image')">
          fechar</button
        >&nbsp;
      </span>
    </el-dialog>
    <!-- END IMAGE -->
  </div>
</template>
<script>
import { Dialog, Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { mask } from "vue-the-mask";
import Fuse from "fuse.js";

export default {
  components: {
    LPagination,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        this.fuseSearch = new Fuse(this.tableData, {
          tokenize: false, threshold: 0.0, keys: ["suite", "id", "tracking"],
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.pagination.total = result.length;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  directives: { mask },
  data() {
    return {
      loading: false,
      modals: {
        details: false,
        image: false,
        centered: false,
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["suite", "id", "tracking"],
      tableColumns: [
        { prop: "suite", label: "suite", align: "center", fit: true },
        { prop: "id", label: "caixa", align: "center", fit: true },
      ],
      tableData: [],
      fuseSearch: null,
      selects: {
        place: "",
        cartStatus: [
          { value: "0", label: "novo" },
          { value: "1", label: "pago" },
          { value: "2", label: "em processamento" },
          { value: "3", label: "cancelado" },
          { value: "4", label: "finalizado" },
          { value: "5", label: "aguardando pagamento" },
          { value: "6", label: "pendente" },
          { value: "7", label: "aguardando escolha do frete" },
          { value: "8", label: "aguardando aprovação" },
          { value: "9", label: "em despacho" },
        ],
      },
      cart_status: [],
      cart_tracking: [],
      cart_total_price: [],
      sendId: "",
      sendDate: "",
      sendProducts: {},
      sendAddress: {},
      sendAddressNull: {
        address: "-",
        city: "-",
        complement: "-",
        country: "-",
        created_at: "2020-02-27 10:45:27",
        default: "-",
        deleted_at: null,
        district: "-",
        id: 0,
        name_address: "ENDEREÇO NÃO INFORMADO",
        number: "-",
        post_code: "-",
        recipient_cpf: "-",
        recipient_name: "-",
        state: "-",
        updated_at: "2020-02-27 10:45:27",
        user_id: 0,
      },
      sendFirstObservation: {},
      sendDeclarations: {},
      sendServices: {},
      sendPrices: {},
      sendUser: {},
      sendCart: {},
      totalQntProduct: 0,
      totalProductWeight: 0,
      totalProductWeightApproximate: 0,
      cartSend: {
        image: "",
      },
      images: {
        one: "",
      },
      meta: {},
      paginate: 50,
    };
  },
  methods: {
    cartImage(cart) {
      if (cart.image_url_thumbnail) {
        return baseURLIMG + cart.image_url_thumbnail;
      }
      return baseURLIMG + "/sem-imagem.png";
    },
    productImage(product) {
      return (
        baseURLIMGUSER +
        "/" +
        product.user_id +
        "/box/product/thumbnail_" +
        product.image
      );
    },
    productImageList(row) {
      this.modals["image"] = true;
      this.images.one =
        baseURLIMGUSER + "/" + row.user_id + "/box/product/" + row.image;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    totalWeigth(products) {
      let productsWeight = products
        .reduce(
          (total, item) => parseFloat(total + item.amount * item.weight),
          0
        )
        .toFixed(3);
      if (productsWeight) {
        return productsWeight;
      }
    },
    approximate(products) {
      let productsWeight = products
        .reduce(
          (total, item) => parseFloat(total + item.amount * item.weight),
          0
        )
        .toFixed(3);
      if (productsWeight) {
        let weight = parseFloat(productsWeight * 0.17).toFixed(3);
        return (parseFloat(productsWeight) + parseFloat(weight)).toFixed(3);
      }
    },
    openCartDetails(index, row) {
      this.modals["details"] = true;
      this.sendId = row.id;
      this.sendDate = row.created_at;
      this.sendProducts = row.products;
      this.sendAddress = row.send_address
        ? row.send_address
        : this.sendAddressNull;
      this.sendDeclarations = row.declarations;
      this.sendServices = row.services;
      this.sendPrices = row.cart_prices;
      this.sendUser = row.user;
      this.sendCart = row;
      this.sendFirstObservation = row.first_observation;
      this.totalQntProduct = row.products.reduce(
        (total, item) => total + item.amount,
        0
      );
      this.totalProductWeight = this.totalWeigth(row.products);
      this.totalProductWeightApproximate = this.approximate(row.products);
    },
    loadCarts(page) {
      this.loadingSearch = true;
      this.$Progress.start();
      if (!page){
        const page=0
      }
      this.pagination.perPage = this.paginate
      axios
        .get("/cart?status=4&page="+page+"&limit="+this.paginate)
        .then(({ data }) => {
          this.tableData = data.data;
          this.meta = data.meta;
          for (let i = 0; i < data.data.length; i++) {
            this.cart_status[data.data[i].id] = data.data[i].status;
            this.cart_tracking[data.data[i].id] = data.data[i].tracking;
            this.cart_total_price[data.data[i].id] = data.data[i].total_price;
          }
          this.$Progress.finish();
          this.loadingSearch = false;
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loading = false;
          this.$Progress.fail();
        });
    },
    changePage(page){
      let new_page = page
      if(new_page==='«'){
        new_page = this.meta.current_page -1
      }
      if(new_page==='»'){
        new_page = this.meta.current_page +1
      }
      this.loadCarts(new_page)
    },
    cartChangeImage(id, suite) {
      this.loading = true;
      this.$Progress.start();

      let formData = new FormData();

      formData.append("image", this.cartSend.image);
      formData.append("suite", suite);
      formData.append("id", id);

      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });

      axios
        .post("/cart/changeimage", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$notify({
            message: "Status atualizado com sucesso.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });
    },
    cartChangeTotalPrice(index, row) {
      let price = this.cart_total_price[row.id];
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .put("/cart/changetotalprice/" + row.id, "price=" + price)
        .then(() => {
          this.$notify({
            message: "Status atualizado com sucesso.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });
    },
    cartChangeStatus(index, row) {
      let status = this.cart_status[row.id];
      console.log(status)
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      // axios
      //   .put("/cart/changestatus/" + row.id, "status=" + status)
      //   .then(() => {
      //     this.$notify({
      //       message: "Status atualizado com sucesso.",
      //       type: "success",
      //       icon: "fa fa-check",
      //     });
      //     this.loadCarts();
      //     this.loading = false;
      //     this.$Progress.finish();
      //   })
      //   .catch((error) => {
      //     if (error) {
      //       Exception.handle(error);
      //     }
      //     swal({
      //       title: "Erro!",
      //       text: "Há algo errado.",
      //       type: "warning",
      //       confirmButtonClass: "btn btn-danger btn-fill",
      //       buttonsStyling: false,
      //     });
      //     this.loadCarts();
      //     this.loading = false;
      //     this.$Progress.fail();
      //   });
    },
    cartChangeTracking(index, row) {
      let tracking = this.cart_tracking[row.id];
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .put("/cart/changetracking/" + row.id, "tracking=" + tracking)
        .then(() => {
          this.$notify({
            message: "Rastreio atualizado com sucesso.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });
    },
  },
  mounted() {
    this.loadCarts(0);
  },
};
</script>
<style>
.swal2-container {
  z-index: 10000;
}
</style>
