<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <ul class="navbar-nav">
          <li class="nav-item">
            <l-button wide @click="searchCategory(0)">Todas as Lojas</l-button>
          </li>
        </ul>
        <ul class="navbar-nav" v-for="category in categories" :key="category.id">
          <li class="nav-item">
            <l-button wide outline @click="searchCategory(category.id)">{{ category.name }}</l-button>
          </li>
        </ul>
      </div>

      <div class="col-md-9">
        <div class="row">
            <div class="col-md-4" v-for="store in filteredStore" :key="store.id">
                <div class="media  p-2 ">
                  <a :href="store.link" target="_blanck"><img :src="storeImage(store.image)" class="align-self-center img-thumbnail img-fluid cursor-pointer cardhover"></a>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {StatsCard} from 'src/components/index'
 import Fuse from 'fuse.js'
  export default {
    components: {
      StatsCard,
    },
    data () {
      return {
          loading: false,
          categories: {},
          stores: {},
          search: '',
      }
    },
    methods: {
      storeImage (image) {
        return baseURLIMG+'/store/'+ image;
      },
      searchCategory (id) {
        if(id){
          return this.search = id.toString();
        }else{
          return this.search = '';
        }

      },
      loadCategory(){
        axios.get("/category")
            .then(({ data }) => (
                this.categories = data.data
            ))
            .catch((error)=> {
                if (error) {
                    Exception.handle(error);
                }
        });
      },
      loadStore(){
        axios.get("/store")
            .then(({ data }) => (
                this.stores = data.data
            ))
            .catch((error)=> {
                if (error) {
                    Exception.handle(error);
                }
        });
      },
    },
    computed: {
      filteredStore: function () {
          if(this.search){
            const fuse = new Fuse(this.stores, { tokenize: false, threshold: 0.0, keys: ['category_id']});
            return fuse.search(this.search);
          }else{
              return this.stores;
          }
      }
    },
    mounted () {
      this.loadCategory();
      this.loadStore();
    },
  }

</script>
<style>

</style>
