<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
            <router-link :to="{ path: '/admin' }">Dashboard</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; Euro Fast Box - Todos os Direitos Reservados -
        <a href="https://eurofastbox.com/termodeuso" target="_blank">
          Termos de Uso e Contrato
        </a>
        - Desenvolvido Por
        <a href="https://github.com/juniorsantos" target="_blank">J.S.Júnior</a>
      </div>
    </div>
   
  </footer>
</template>
<script>
export default {};
</script>
<style>
</style>
