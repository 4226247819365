<template>
  <label class="file-select">
    <div class="btn btn-info btn-fill btn-wd">
      <span v-if="value">{{value.name}}</span>
      <span v-else>Escolher</span>
    </div>
    <input type="file" @change="handleFileChange" accept="image/*" capture="camera"/>
  </label>
</template>

<script>
  export default {
    props: {
      value: File
    },

    methods: {
      handleFileChange(e) {
        this.$emit('input', e.target.files[0]);
        console.log(e.target.files[0]);
      }
    }
  }
</script>

<style scoped>
  .file-select > .select-button {
    padding: 1rem;
    color: white;
    background-color: #2EA169;
    border-radius: .3rem;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  .file-select > input[type="file"] {
    display: none;
  }
</style>
