<template>
  <div>
    <div class="row">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="m-0 m-0">
            <div class="col-12 d-flex justify-content-center justify-content-lg-between flex-wrap">
              <div class="col-4">
                <el-select
                    class="select-default mb-3"
                    v-model="pagination.perPage"
                    placeholder="Per page">
                  <el-option
                      class="select-default"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="col-5 d-flex justify-content-center">
                <div class="col-6">
                  <el-input type="search"
                            class="mb-3"
                            placeholder="Pesquisar"
                            v-model="searchQuery"
                            aria-controls="datatables"
                  />
                </div>
                <div class="col-5">
                  <el-select
                      name="Status"
                      class="select-default d-block m-1"
                      label="Status"
                      placeholder="Status"
                      v-model="userStatus"
                  >
                    <el-option v-for="option in selects.status"
                               class="select-default"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="col-4">
                  <l-button class="p-2 ml-1" type="success" size="sm" @click="loadUsers(0)" v-show="!loadingSearch">
                    <i class="fa fa-search"></i>
                  </l-button>
                  <l-button class="p-2 ml-1" type="secondary" size="sm" v-show="loadingSearch">
                    <i class="fa fa-spinner" aria-hidden="true"></i>
                  </l-button>

                  <l-button class="p-2 ml-1" type="primary" size="sm" @click="handleExport()" v-show="!loadingExport">
                    <i class="fa fa-download"></i>
                  </l-button>
                  <l-button class="p-2 ml-1" type="secondary" size="sm" v-show="loadingExport">
                    <i class="fa fa-spinner" aria-hidden="true"></i>
                  </l-button>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <el-table stripe
                        style="width: 100%;"
                        :data="queriedData"
                        border>
                <el-table-column v-for="column in tableColumns"
                                 :key="column.label"
                                 :min-width="column.minWidth"
                                 :prop="column.prop"
                                 :label="column.label">
                </el-table-column>
                <el-table-column
                    :min-width="70"
                    fixed="right"
                    label="Caixa Aberta"
                    align="center"
                >
                  <template slot-scope="props">

                   <span
                       @click.prevent="handleSetOpenBox(props.row.id,props.row.is_open_box)"
                   >
                     <l-switch
                         v-model="props.row.is_open_box"
                     ></l-switch>
                   </span>

                  </template>
                </el-table-column>
                <el-table-column
                    :min-width="70"
                    fixed="right"
                    label="Ações"
                    align="center"
                >
                  <template slot-scope="props">

                    <a v-tooltip.top-center="'Endereços'" class="btn-primary btn-simple btn-link"
                       @click="userAddresses(props.$index, props.row)">
                      <i class="fa fa-address-card"></i>
                    </a>

                    <a v-tooltip.top-center="'Apagar'" class="btn-danger btn-simple btn-link"
                       @click="userDelete(props.$index, props.row)">
                      <i class="fa fa-times"></i>
                    </a>

                    <a v-tooltip.top-center="'Bloquear'" class="btn-warning btn-simple btn-link"
                       @click="userBan(props.$index, props.row)">
                      <i class="fa fa-ban"></i>
                    </a>

                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="" v-if="meta">
              <p class="card-category">Exibindo {{ meta.from }} até {{ meta.to }} de um total de {{ meta.total }}
                registros</p>
            </div>
            <ul class="pagination">
              <li class="page-item" :class="{active: link.active}" v-for="link in meta.links" :key="link.label">
                <a class="page-link" @click.prevent="changePage(link.label)">{{ link.label }}</a>
              </li>
            </ul>
          </div>

        </card>
      </div>
    </div>

    <el-dialog
        title="Endereços"
        :visible.sync="modals.basic"
        width="60%"
    >
      <div class="row">
        <div class="card-body table-striped p-0">
          <table class="table table-hover table-striped">
            <tbody>
            <tr>
              <th>endereço</th>
              <th>bairro</th>
              <th>complemento</th>
              <th>cidade</th>
              <th>estado</th>
              <th>pais</th>
              <th>codigo postal</th>
              <th>Ações</th>
            </tr>
            <tr v-for="address in addresses" :key="address.id">
              <td>{{ address.address }} - {{ address.number }}</td>
              <td>{{ address.district }}</td>
              <td>{{ address.complement }}</td>
              <td>{{ address.city }}</td>
              <td>{{ address.state }}</td>
              <td>{{ address.country }}</td>
              <td>{{ address.post_code }}</td>
              <td>
                <a v-tooltip.top-center="'Apagar'" class="btn-danger btn-simple btn-link"
                   @click="addressDelete(address.id)">
                  <i class="fa fa-times"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">cancelar</button>&nbsp;
            </span>
    </el-dialog>
  </div>

</template>
<script>
import {Dialog, Option, Select, Table, TableColumn} from 'element-ui'
import {Pagination as LPagination, Switch as LSwitch} from 'src/components/index'
import Fuse from 'fuse.js'

export default {
  components: {
    LPagination,
    LSwitch,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== '') {
        this.fuseSearch = new Fuse(this.tableData, {keys: ['suite', 'full_name', 'email', 'cell_phone']});
        result = this.fuseSearch.search(this.searchQuery);
        this.pagination.total = result.length
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length
    }
  },
  data() {
    return {
      loading: false,
      users: {},
      user_is_open_box: [],
      addresses: {},
      userStatus: [],
      selects: {
        status: [
          {value: "0", label: "Ativo"},
          {value: "1", label: "Bloqueado"},
        ],
      },
      modals: {
        basic: false,
        centered: false,
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['suite', 'full_name', 'email', 'cell_phone'],
      tableColumns: [
        {prop: 'suite', label: 'suite', fit: true},
        {prop: 'full_name', label: 'nome completo', minWidth: 240},
        {prop: 'email', label: 'email', minWidth: 140},
        {prop: 'cell_phone', label: 'telefone', minWidth: 120},
        {prop: 'created_at', label: 'criada em', minWidth: 100},
      ],
      tableData: [],
      fuseSearch: null,
      meta: {},
      paginate: 50,
      loadingSearch: false,
      loadingExport: false,
    }
  },
  methods: {
    handleExport() {
      this.loadingExport = true
      let status = this.userStatus
      if (this.userStatus == '') {
        status = 0
      }
      let fileName = 'clientes-com-envio'
      axios.get(`/users/with-shipments/export?status=${status}`, {
        responseType: 'blob',
      }).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
            'download',
            `clientes-${new Date().toLocaleDateString()}.xlsx`
        )
        document.body.appendChild(link)
        link.click()

        this.loadingExport = false
      });
    },
    handleSetOpenBox(userId, isOpenBox) {
      this.$notify(
          {
            message: 'Por favor aguarde estamos trabalhando em sua solicitação!',
            type: 'info',
            icon: 'fa fa-check'
          });

      this.$Progress.start();
      axios.put(`/user/open-box/${userId}`,
          {is_open_box: isOpenBox}
      ).then(() => {
        this.$Progress.finish();
        this.$notify(
            {
              message: 'Dado atualizado com sucesso!',
              type: 'success',
              icon: 'fa fa-check'
            });
      }).catch((error) => {
        if (error) {
          Exception.handle(error);
        }
        swal({
          title: "Erro!",
          text: "Há algo errado.",
          type: "warning",
          confirmButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        });
        this.$Progress.fail();
      });
    },
    closeModal(name) {
      this.modals[name] = false
    },
    loadUsers(page) {
      this.loadingSearch = true;
      this.$Progress.start();
      if (!page) {
        const page = 0
      }
      let term = this.searchQuery

      if (!term) {
        term = ''
      }
      this.pagination.perPage = this.paginate

      axios.get("/users/with-shipments?page=" + page + "&term=" + term + "&limit=" + this.paginate)
          .then(({data}) => {
            this.tableData = data.data;
            this.meta = data.meta;
            this.term = null;
            this.$Progress.finish();
            this.loadingSearch = false;
          });
    },
    changePage(page) {
      let new_page = page
      if (new_page === '«') {
        new_page = this.meta.current_page - 1
      }
      if (new_page === '»') {
        new_page = this.meta.current_page + 1
      }
      this.loadUsers(new_page)
    },
    userAddresses(index, row) {
      this.modals['basic'] = true;
      this.addresses = row.addresses;
    },
    userDelete(index, row) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
      this.$Progress.start();
      swal({
        title: 'Você tem certeza?',
        text: 'Você não poderá recuperar este registro!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apague isto!',
        cancelButtonText: 'Não, mantenha',
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false,
      }).then((result) => {
        // Send request to the server
        if (result.value) {
          axios.delete('/user/' + row.id).then(() => {
            if (indexToDelete >= 0) {
              this.tableData.splice(indexToDelete, 1)
            }
            swal({
              title: 'Apagado!',
              text: 'Seu registro foi apagado.',
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            this.$Progress.finish();
            this.$notify(
                {
                  message: 'Dados inseridos com sucesso!',
                  type: 'success',
                  icon: 'fa fa-check'
                });
          }).catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            swal({
              title: "Erro!",
              text: "Há algo errado.",
              type: "warning",
              confirmButtonClass: 'btn btn-danger btn-fill',
              buttonsStyling: false
            });
            this.$Progress.fail();
          });
        }
      })
    },
    addressDelete(row) {
      this.$Progress.start();
      swal({
        title: 'Você tem certeza?',
        text: 'Você não poderá recuperar este registro!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apague isto!',
        cancelButtonText: 'Não, mantenha',
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false,
      }).then((result) => {
        // Send request to the server
        if (result.value) {
          axios.delete('/user/address/' + row).then(() => {
            this.modals['basic'] = false;
            // if (indexToDelete >= 0) {
            //   this.addresses.splice(indexToDelete, 1)
            // }
            swal({
              title: 'Apagado!',
              text: 'Seu registro foi apagado.',
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            this.$Progress.finish();
            this.$notify(
                {
                  message: 'Dados inseridos com sucesso!',
                  type: 'success',
                  icon: 'fa fa-check'
                });
          }).catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            swal({
              title: "Erro!",
              text: "Há algo errado.",
              type: "warning",
              confirmButtonClass: 'btn btn-danger btn-fill',
              buttonsStyling: false
            });
            this.$Progress.fail();
          });
        }
      })
    },
    userBan(index, row) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
      this.$Progress.start();
      swal({
        title: 'Você tem certeza?',
        text: 'Você poderá reativar este usuário posteriormente!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, bloquear!',
        cancelButtonText: 'Não, mantenha',
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false,
      }).then((result) => {
        // Send request to the server
        if (result.value) {
          axios.put('/user/block/' + row.id).then(() => {
            if (indexToDelete >= 0) {
              this.tableData.splice(indexToDelete, 1)
            }
            swal({
              title: 'Bloqueio!',
              text: 'Usuário bloqueado com sucesso.',
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            this.$Progress.finish();
            this.$notify(
                {
                  message: 'Usuário bloqueado com sucesso!',
                  type: 'success',
                  icon: 'fa fa-check'
                });
          }).catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            swal({
              title: "Erro!",
              text: "Há algo errado.",
              type: "warning",
              confirmButtonClass: 'btn btn-danger btn-fill',
              buttonsStyling: false
            });
            this.$Progress.fail();
          });
        }
      })
    },
  },
  mounted() {
    this.loadUsers(0);
  },
}
</script>
<style>
.swal2-container {
  z-index: 10000;
}
</style>
