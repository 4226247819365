<template>
</template>
<script>
  import DropDown from 'src/components/Dropdown.vue'

  export default {
    components: {
      DropDown
    }
  }
</script>
<style>
</style>
