<template>
  <div>
      <div class="row mb-2">
        <div class="col-lg-12 pr-0">
          <div class="col-lg-2 offset-lg-10 pr-0">
            <router-link to="/admin/box" v-if="isAdmin">
              <l-button wide type="primary" size="md">
                    <span class="btn-label">
                        <i class="fa fa-box"></i>
                    </span>
                &nbsp;&nbsp;inserir caixa
              </l-button>
            </router-link>
          </div>
        </div>
      </div>
    <div class="row">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="table-responsive">
            <el-table class="table-bigboy"
                      style="width: 100%"
                      :data="tableData">
              <el-table-column min-width="220" label="Caixa">
                <template slot-scope="{row}">
                  <div class="img-container">
                    <img :src="boxImage(row)" width="120" alt="image" class="align-self-center img-thumbnail img-fluid cursor-pointer" @click="boxImageList(row)">
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="220" label="suite">
                <template slot-scope="{row}">
                  <p>{{row.user_suite}}</p>
                </template>
              </el-table-column>
              <el-table-column min-width="220" label="rastreio">
                <template slot-scope="{row}">
                  <p>{{row.tracking_number}}</p>
                </template>
              </el-table-column>

              <el-table-column min-width="100" label="criada em" prop="created_at">
              </el-table-column>
              <el-table-column  min-width="120">
               <template slot-scope="props">

                    <a v-tooltip.top-center="'Editar'" class="btn-warning btn-simple btn-link"
                       @click="boxProducts(props.$index, props.row)">
                      <i class="fa fa-box-open"></i>
                    </a>

                    <a v-tooltip.top-center="'Apagar'" class="btn-danger btn-simple btn-link"
                       @click="boxDelete(props.$index, props.row)"
                       v-show="isAdmin"
                    >
                      <i class="fa fa-times"></i>
                    </a>


                  </template>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>

    <el-dialog
      title="Productos"
      :visible.sync="modals.basic">
          <div class="row">
            <div class="card-body table-striped p-0">
              <table class="table table-hover table-striped">
                <tbody>
                <tr>
                  <th>img</th>
                  <th>weight</th>
                  <th>amount</th>
                  <th>description</th>
                  <th>referência</th>
                  <th>Ações</th>
                </tr>
                <tr v-for="product in products" :key="product.id">
                  <td width="220">
                    <span class="img-fluid">
                      <img :src="productImage(product)" alt="Foto" class="img-fluid">
                    </span>
                  </td>
                  <td>{{ product.weight }}</td>
                  <td>{{ product.amount }}</td>
                  <td>{{ product.description }}</td>
                  <td>{{ product.reference_code }}</td>
                  <td>
                    <a v-tooltip.top-center="'Apagar'" class="btn-danger btn-simple btn-link"
                       @click="productDelete(product.id)">
                      <i class="fa fa-times"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

            <span  slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">cancelar</button>&nbsp;
            </span>
    </el-dialog>
  </div>

</template>
<script>
  import { Dialog, Table, TableColumn, Select, Option } from 'element-ui'
  import {Pagination as LPagination} from 'src/components/index'
  import Fuse from 'fuse.js'

  export default {
    components: {
      LPagination,
      [Dialog.name]: Dialog,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {

    },
    data () {
      return {
        loading: false,
        isAdmin: false,
        boxes: {},
        products: {},
        modals: {
          basic: false,
          centered: false,
        },
        tableData: [],
        fuseSearch: null
      }
    },
    methods: {
      productImage (row) {
        return baseURLIMGUSER+'/'+ row.user_id +'/box/product/thumbnail_'+ row.image;
      },
      closeModal (name) {
        this.modals[name] = false
      },
      loadBoxes(){
        axios.get("/box")
          .then(({ data }) => (
            this.tableData = data.data
          ));
      },
      boxImage (row) {
        return baseURLIMGUSER+'/'+ row.user_id +'/box/thumbnail_'+ row.image;
      },
      boxProducts (index, row) {
        this.modals['basic'] = true;
        this.products = row.products;
        // alert(`Your want to edit ${row.user_suite}`)
      },
      boxDelete (index, row) {
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
        this.$Progress.start();
        swal({
          title: 'Você tem certeza?',
          text: 'Você não poderá recuperar este registro!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, apague isto!',
          cancelButtonText: 'Não, mantenha',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false,
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('/box/'+row.id).then(()=>{
              if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1)
              }
              swal({
                title: 'Apagado!',
                text: 'Seu registro foi apagado.',
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
              this.$Progress.finish();
              this.$notify(
                {
                  message: 'Dados inseridos com sucesso!',
                  type: 'success',
                  icon: 'fa fa-check'
                });
            }).catch((error)=>{
              if(error){
                Exception.handle(error);
              }
              swal({
                title:"Erro!",
                text: "Há algo errado.",
                type: "warning",
                confirmButtonClass: 'btn btn-danger btn-fill',
                buttonsStyling: false
              });
              this.$Progress.fail();
            });
          }
        })
      },
      productDelete (row) {
        this.$Progress.start();
        swal({
          title: 'Você tem certeza?',
          text: 'Você não poderá recuperar este registro!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, apague isto!',
          cancelButtonText: 'Não, mantenha',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false,
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('/box/'+row.id).then(()=>{
              if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1)
              }
              swal({
                title: 'Apagado!',
                text: 'Seu registro foi apagado.',
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
              this.$Progress.finish();
              this.$notify(
                {
                  message: 'Dados inseridos com sucesso!',
                  type: 'success',
                  icon: 'fa fa-check'
                });
            }).catch((error)=>{
              if(error){
                Exception.handle(error);
              }
              swal({
                title:"Erro!",
                text: "Há algo errado.",
                type: "warning",
                confirmButtonClass: 'btn btn-danger btn-fill',
                buttonsStyling: false
              });
              this.$Progress.fail();
            });
          }
        })
      }
    },
    mounted () {
        this.isAdmin = User.isAdmin();
        this.loadBoxes();
    },
  }
</script>
<style>
  .swal2-container {
  z-index: 10000;
  }
</style>
