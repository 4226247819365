<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <form autocomplete="off" @submit.prevent="login">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div slot="header">
                <h3 class="card-title text-center">Login</h3>
              </div>
                <h5 class="text-danger text-center small m-0 p-0">{{ error }}&nbsp;</h5>
              <div>
                <fg-input label="Email"
                          placeholder="Enter"
                          type="email"
                          v-model="user.email">

                </fg-input>
                <fg-input label="senha"
                          type="password"
                          placeholder="senha"
                          v-model="user.password">
                </fg-input>

              </div>
              <div class="text-center">
                <button v-show="!loading" type="submit" class="btn btn-fill btn-info btn-round btn-wd ">acessar</button>
                <button  v-show="loading" class="btn btn-default btn-fill btn-wd btn-round disabled" disabled>
                  <i class="spinner-grow spinner-grow-sm"></i>&nbsp;&nbsp;
                  acessando
                </button>
                <br>
                <div class="forgot">
                  <router-link to="/register" class="card-category">
                    Não sou cadastrado!
                  </router-link>
                </div>
                <div class="forgot">
                  <router-link to="/forgot" class="card-category">
                    Recuperar senha!
                  </router-link>
                </div>
              </div>
            </card>
          </fade-render-transition>

        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'
  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout
    },
    data () {
      return {
          loading:false,
          error: '',
          user: {
              email: '',
              password: ''
          }
      }
    },
    methods: {
      login() {
          this.loading=true;
            axios.post('/auth/login', this.user)
                .then(res => User.responseAfterLogin(res))
                .catch((error) => {
                    this.error = error.response.data.error;
                    this.loading=false;
                })
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open');
      },
      closeMenu () {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
