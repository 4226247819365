<template>
  <div>
    <div class="row">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="m-0 m-0">
            <div class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-default mb-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <el-input type="search"
                        class="mb-3"
                        style="width: 200px"
                        placeholder="Pesquiser"
                        v-model="searchQuery"
                        aria-controls="datatables"
              />
            </div>
            <div class="col-sm-12">
              <el-table stripe
                        style="width: 100%;"
                        :data="queriedData"
                        border>
                <el-table-column v-for="column in tableColumns"
                                 :key="column.label"
                                 :min-width="column.minWidth"
                                 :prop="column.prop"
                                 :label="column.label">
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">Exibindo de {{from + 1}} até {{to}} de {{total}} registros</p>
            </div>
            <l-pagination class="pagination-no-border"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </l-pagination>
          </div>
        </card>
      </div>
    </div>


  </div>

</template>
<script>
  import { Table, TableColumn} from 'element-ui'
  import {Pagination as LPagination} from 'src/components/index'
  import Fuse from 'fuse.js'

  export default {
    components: {
      LPagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      queriedData () {
        let result = this.tableData;
        if (this.searchQuery !== '') {
          this.fuseSearch = new Fuse(this.tableData, { keys: ['name','last_name'] });
          result = this.fuseSearch.search(this.searchQuery);
          this.pagination.total = result.length
        }
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length;
        return this.tableData.length
      }
    },
    data () {
      return {
        loading: false,
        users: {},
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['name','last_name'],
        tableColumns: [
          { prop: 'name', label: 'Nome', fit: true },
          { prop: 'last_name', label: 'Sobrenome', minWidth: 240},
          { prop: 'created_at', label: 'Cadastrado',minWidth: 100},
        ],
        tableData: [],
        fuseSearch: null
      }
    },
    methods: {
      loadAffiliate(){
        axios.get("/affiliate")
          .then(({ data }) => (
            this.tableData = data.data
          ));
      },
    },
    mounted () {
      this.loadAffiliate();
    },
  }
</script>
<style>
  .swal2-container {
  z-index: 10000;
  }
</style>
