import Token from './Token'
import AppStorage from './AppStorage'

class User {
  login(data) {
    axios.post('/auth/login', data)
      .then(res => this.responseAfterLogin(res))
      .catch(error => console.log(error.response.data))
  }

  responseAfterLogin(res) {
    const access_token = res.data.access_token;
    const user = res.data.user;
    if (Token.isValid(access_token)) {
      AppStorage.store(user, access_token);
      window.location = '/admin/overview'
    }
  }

  hasToken() {
    const storedToken = AppStorage.getToken();
    if (storedToken) {
      return Token.isValid(storedToken) ? true : this.logout()
    }
    return false
  }

  loggedIn() {
    return this.hasToken()
  }

  logout() {
    AppStorage.clear();
    window.location = '/login'
  }

  clearSetUser(user) {
    AppStorage.clearUser();
    AppStorage.storeNewUser(user);
  }

  userProfile() {
    if (this.loggedIn()) {
      return JSON.parse(AppStorage.getUser())
      // console.log(AppStorage.getUser())
    }
  }

  userSuite() {
    const user = JSON.parse(AppStorage.getUser());
    if (user) {
      return user.suite;
    }else{
      return '';
    }
  }

  isAdmin() {
    const user = JSON.parse(AppStorage.getUser());
    if (user.role==='admin') {
      return true;
    }else{
      return false;
    }
  }


}

export default User = new User();
