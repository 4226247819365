<template>
  <div>
    <div class="row mb-2">
      <div class="col-lg-12 pr-0">
        <div class="col-lg-2 offset-lg-10 pr-0">
          <l-button wide type="primary" size="md" @click="createShipping">
            <span class="btn-label">
              <i class="nc-icon nc-send"></i>
            </span>
            &nbsp;&nbsp;Adicionar
          </l-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="m-0 m-0">
            <div
              class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-default mb-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
              <el-input
                type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Pesquiser"
                v-model="searchQuery"
                aria-controls="datatables"
              />
            </div>
            <div class="col-sm-12">
              <el-table stripe style="width: 100%" :data="queriedData" border>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :align="column.align"
                  :fit="column.fit"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>

                <el-table-column
                  :min-width="80"
                  fixed="right"
                  label="Ações"
                  align="center"
                >
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Editar'"
                      class="btn-warning btn-simple btn-link"
                      @click="editShipping(props.row)"
                    >
                      <i class="fa fa-edit"></i>
                    </a>

                    <a
                      v-tooltip.top-center="'Apagar'"
                      class="btn-danger btn-simple btn-link"
                      @click="deleteStore(props.$index, props.row)"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-country">
                Exibindo de {{ from + 1 }} até {{ to }} de {{ total }} registros
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            ></l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog
      title="Adicionar nova Loja"
      :visible.sync="modals.basic"
      width="40%"
    >
      <div>
        <form>
          <div class="row">
            <div class="col-lg-4">
              <fg-input
                class="mb-0"
                label="Nome do Frete"
                placeholder="Nome do Frete"
                name="name"
                type="text"
                ref="name"
                v-model="shipping.name"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h5 class="title">Opção de Envio</h5>
              <el-select
                label="Opção de Envio"
                class="select-primary"
                v-model="shipping.shipping_option_id"
                filterable
                autocomplete="off"
                placeholder="Opção de Envio"
                v-validate="modelValidations.shipping_option_id"
                :error="getError('shipping_option_id')"
              >
                <el-option
                  v-for="shippingFee in shipping_options.shipping_option"
                  class="select-primary"
                  :value="shippingFee.value"
                  :label="shippingFee.label"
                  :key="shippingFee.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-6">
              <h5 class="title">Países</h5>
              <el-select
                label="Países"
                multiple
                collapse-tags
                class="select-primary"
                v-model="shipping.countries"
                filterable
                autocomplete="off"
                placeholder="Países"
                v-validate="modelValidations.countries"
                :error="getError('countries')"
              >
                <el-option
                  v-for="option in countries.country"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <fg-input
                class="mb-0"
                label="Peso Incial"
                placeholder="Peso Incial"
                name="weight_start"
                type="text"
                ref="weight_start"
                v-model="shipping.weight_start"
                v-mask="['#.##', '#.###', '##.###']"
                masked="true"
                v-validate="modelValidations.weight_start"
                :error="getError('weight_start')"
              ></fg-input>
            </div>
            <div class="col-lg-4">
              <fg-input
                class="mb-0"
                label="Peso Final"
                placeholder="Peso Final"
                name="weight_end"
                type="text"
                ref="weight_end"
                v-model="shipping.weight_end"
                v-mask="['#.##', '#.###', '##.###']"
                masked="true"
                v-validate="modelValidations.weight_end"
                :error="getError('weight_end')"
              ></fg-input>
            </div>
            <div class="col-lg-4">
              <fg-input
                class="mb-0"
                label="Valor"
                placeholder="Valor"
                name="price"
                type="text"
                ref="price"
                v-model="shipping.price"
                v-mask="['#.##', '##.##', '###.##']"
                masked="true"
                v-validate="modelValidations.price"
                :error="getError('price')"
              ></fg-input>
            </div>
          </div>
        </form>
      </div>

      <span v-show="!loading" slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('basic')">
          cancelar</button
        >&nbsp;
        <button class="btn btn-success" @click.prevent="validate">
          salvar
        </button>
      </span>

      <span v-show="loading" slot="footer" class="dialog-footer">
        <button class="btn btn-default" disabled>cancelar</button>&nbsp;
        <button class="btn btn-default" disabled>salvar</button>
      </span>
    </el-dialog>

    <el-dialog title="Editar Frete" :visible.sync="modals.edit">
      <div>
        <form>
          <div class="row">
            <div class="col-lg-4">
              <fg-input
                class="mb-0"
                label="Nome do Frete"
                placeholder="Nome do Frete"
                name="name"
                type="text"
                ref="name"
                v-model="fieldEditShipping.name"
              ></fg-input>
            </div>
            <div class="col-lg-4">
              <div>
                <label>&nbsp;</label><br />
                <el-checkbox-group v-model="fieldEditShipping.is_dimensional">
                  <el-checkbox-button :label="1"
                    >Considerar peso dimensional</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h5 class="title">Opção de Envio</h5>
              <el-select
                label="Opção de Envio"
                class="select-primary"
                v-model="fieldEditShipping.shipping_option_id"
                placeholder="Opção de Envio"
                filterable
                size="large"
              >
                <el-option
                  v-for="shippingFee in shipping_options.shipping_option"
                  class="select-primary"
                  :value="shippingFee.value"
                  :label="shippingFee.label"
                  :key="shippingFee.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-6">
              <h5 class="title">Países</h5>
              <el-select
                label="Países"
                multiple
                collapse-tags
                filterable
                class="select-primary"
                v-model="fieldEditShipping.countries"
                placeholder="Países"
              >
                <el-option
                  v-for="option in countries.country"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <fg-input
                class="mb-0"
                label="Peso Incial"
                placeholder="Peso Incial"
                name="weight_start"
                type="text"
                ref="weight_start"
                v-model="fieldEditShipping.weight_start"
                v-mask="['#.##', '#.###', '##.###']"
                masked="true"
              ></fg-input>
            </div>
            <div class="col-lg-4">
              <fg-input
                class="mb-0"
                label="Peso Final"
                placeholder="Peso Final"
                name="weight_end"
                type="text"
                ref="weight_end"
                v-model="fieldEditShipping.weight_end"
                v-mask="['#.##', '#.###', '##.###']"
                masked="true"
              ></fg-input>
            </div>
            <div class="col-lg-4">
              <fg-input
                class="mb-0"
                label="Valor"
                placeholder="Valor"
                name="price"
                type="text"
                ref="price"
                v-model="fieldEditShipping.price"
                v-mask="['#.##', '##.##', '###.##']"
                masked="true"
              ></fg-input>
            </div>
          </div>
        </form>
      </div>

      <span v-show="!loading" slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('edit')">
          cancelar</button
        >&nbsp;
        <button class="btn btn-success" @click="updateShipping">salvar</button>
      </span>

      <span v-show="loading" slot="footer" class="dialog-footer">
        <button class="btn btn-default" disabled>cancelar</button>&nbsp;
        <button class="btn btn-default" disabled>salvar</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pt_BR from "vee-validate/dist/locale/pt_BR";
import {
  Dialog,
  Table,
  TableColumn,
  Select,
  Option,
  Checkbox,
  CheckboxButton,
  CheckboxGroup
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { mask } from "vue-the-mask";
import Fuse from "fuse.js";

export default {
  directives: { mask },
  components: {
    LPagination,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["name", "payment_gateway"]
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.pagination.total = result.length;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  data() {
    return {
      loading: false,
      stores: {},
      shipping: {
        shipping_name: "",
        shipping_option_id: "",
        weight_start: "",
        weight_end: "",
        price: "",
        countries: []
      },
      fieldEditShipping: {
        id: null,
        shipping_name: "",
        shipping_option_id: "",
        weight_start: "",
        weight_end: "",
        price: "",
        countries: []
      },
      modals: {
        basic: false,
        edit: false,
        centered: true
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["shipping_option_name", "shipping_name"],
      tableColumns: [
        {
          prop: "shipping_name",
          label: "Nome do frete",
          align: "center",
          fit: true
        },
        {
          prop: "shipping_option_name",
          label: "Opção de envio",
          align: "center",
          fit: true
        },
        {
          prop: "weight_start",
          label: "Peso Inci.",
          align: "center",
          fit: true
        },
        { prop: "weight_end", label: "Peso Fim.", align: "center", fit: true },
        { prop: "price", label: "Valor", align: "center", fit: true }
      ],
      tableData: [],
      fuseSearch: null,
      modelValidations: {
        name: {
          required: true
        },
        shipping_option_id: {
          required: true
        },
        countries: {
          required: true
        },
        weight_start: {
          required: true
        },
        weight_end: {
          required: true
        },
        price: {
          required: true
        }
      },
      countries: {
        simple: "",
        country: [],
        multiple: "ARS"
      },
      shipping_options: {
        simple: "",
        shipping_option: []
      }
    };
  },
  methods: {
    createShipping() {
      this.modals["basic"] = true;
    },
    editShipping(row) {
      this.fieldEditShipping.id = row.id;
      this.fieldEditShipping.name = row.shipping_name;
      this.fieldEditShipping.shipping_option_id = row.shipping_option_id;
      this.fieldEditShipping.weight_start = row.weight_start;
      this.fieldEditShipping.weight_end = row.weight_end;
      this.fieldEditShipping.price = row.price;
      this.fieldEditShipping.countries = row.countries_by_id;
      this.modals["edit"] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    addShipping() {
      this.loading = true;
      this.$Progress.start();

      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom"
      });
      axios
        .post("/shipping", this.shipping)
        .then(() => {
          this.$notify({
            message: "Dados inseridos com sucesso!",
            type: "success",
            icon: "fa fa-check"
          });
          this.loading = false;
          this.$Progress.finish();
          this.loadShipping();
        })
        .catch(error => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: error.response.data.message,
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
          });
          // this.closeModal("basic");
          this.loading = false;
          this.$Progress.fail();
        });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.localize("pt_BR", pt_BR);
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.addShipping();
        }
      });
    },
    updateShipping() {
      this.loading = true;
      this.$Progress.start();

      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom"
      });
      axios
        .put("/shipping/" + this.fieldEditShipping.id, this.fieldEditShipping)
        .then(() => {
          this.$notify({
            message: "Dados inseridos com sucesso!",
            type: "success",
            icon: "fa fa-check"
          });
          this.loading = false;
          this.$Progress.finish();
          this.modals["edit"] = false;
          this.loadShipping();
        })
        .catch(error => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: error.response.data.message,
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
          });
          this.loading = false;
          this.$Progress.fail();
          this.modals["edit"] = false;
        });
    },
    loadShipping() {
      axios
        .get("/shipping")
        .then(({ data }) => (this.tableData = data.data))
        .catch(error => {
          if (error) {
            Exception.handle(error);
          }
        });
    },
    loadCountry() {
      axios
        .get("/country-select")
        .then(({ data }) => (this.countries.country = data.data))
        .catch(error => {
          if (error) {
            Exception.handle(error);
          }
        });
    },
    loadShippingOption() {
      axios
        .get("/shippingoption-select")
        .then(({ data }) => (this.shipping_options.shipping_option = data.data))
        .catch(error => {
          if (error) {
            Exception.handle(error);
          }
        });
    },
    deleteStore(index, row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      this.$Progress.start();
      swal({
        title: "Você tem certeza?",
        text: "Você não poderá recuperar este registro!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, apague isto!",
        cancelButtonText: "Não, mantenha",
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        buttonsStyling: false
      }).then(result => {
        // Send request to the server
        if (result.value) {
          axios
            .delete("/shipping/" + row.id)
            .then(() => {
              if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
              }
              swal({
                title: "Apagado!",
                text: "Seu registro foi apagado.",
                type: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false
              });
              this.$Progress.finish();
              this.$notify({
                message: "Dados inseridos com sucesso!",
                type: "success",
                icon: "fa fa-check"
              });
            })
            .catch(error => {
              if (error) {
                Exception.handle(error);
              }
              swal({
                title: "Erro!",
                text: "Há algo errado.",
                type: "warning",
                confirmButtonClass: "btn btn-danger btn-fill",
                buttonsStyling: false
              });
              this.$Progress.fail();
            });
        }
      });
    }
  },
  mounted() {
    this.loadShipping();
    this.loadCountry();
    this.loadShippingOption();
  }
};
</script>
<style>
.swal2-container {
  z-index: 10000;
}
</style>
