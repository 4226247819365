<template>
    <span>Bem vindo <b> {{ full_name }} </b></span>
</template>
<script>
  export default {
    name: 'notification-sample',
    data (){
      return {
        full_name: User.userProfile().name + ' ' + User.userProfile().last_name
      }
    }
  }
</script>
<style>
</style>
