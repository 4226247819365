<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 p-0">
      <card class="m-0 mb-2">
        <div class="row">
          <div class="col-12">
            <fg-input type="text" label="Pesquise o seu produto"
                      name="item"
                      v-model="find.search"
                      v-on:keyup="loadMyProducts"
            ></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <table class="table table-striped table-hover table-condesed">
              <thead>
                <tr>
                  <th>descrição</th>
                  <th>referência</th>
                  <th class="text-center">qnt estoque</th>
                  <th class="text-center">peso</th>
                  <th class="text-center">criado em</th>
                  <th class="text-center">ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in myProducts ">
                  <td width="50%">{{ product.description }}</td>
                  <td width="20%">{{ product.reference_code }}</td>
                  <td width="15%" class="text-center">{{ product.amount }}</td>
                  <td width="15%" class="text-center">{{ product.weight }} kg</td>
                  <td width="20%" class="text-center">{{ product.created_at }}</td>
                  <td class="text-center">
                    <a v-tooltip.top-center="'Adicionar'" class="p-0 btn btn-success btn-link btn-xs"
                      >
                      <i class="fa fa-plus"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </card>
    </div>
  </div>
</template>

<script>

    export default {
        components: {},
        name: "MyProducts",
        data () {
            return {
                find: {
                    search: ''
                },
                myProducts:[],
            }
        },
        methods:{
            loadMyProducts(){
                axios.get("product/myproduct?search="+this.find.search,)
                    .then(({ data }) => (
                        this.myProducts = data.data
                    ));
            },
        },
        created() {
            this.loadMyProducts();
        }
    }
</script>

<style scoped>
</style>
