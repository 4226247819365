<template>
  <card class="card">
    <div class="card-header">
     <h4 class="pt-0 mt-0">Seu endereço na Europa
       <svg height="30" version=" 1.1" id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001
      512.001;" xml:space="preserve">
      <path style="fill:#41479B;" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828
	h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z"/>
         <g>
        <path style="fill:#FFE15A;" d="M259.59,126.686l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646
		l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666
		l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C257.187,125.312,259.132,125.312,259.59,126.686z"/>
           <path style="fill:#FFE15A;" d="M259.59,354.548l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646
		l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666
		l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C257.187,353.174,259.132,353.174,259.59,354.548z"/>
           <path style="fill:#FFE15A;" d="M373.521,240.617l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646
		l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666
		l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C371.118,239.243,373.063,239.243,373.521,240.617z"/>
           <path style="fill:#FFE15A;" d="M145.658,240.617l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646
		l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666
		l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C143.256,239.243,145.201,239.243,145.658,240.617z"/>
           <path style="fill:#FFE15A;" d="M162.762,181.121l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646
		l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666
		l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C160.36,179.747,162.303,179.747,162.762,181.121z"/>
           <path style="fill:#FFE15A;" d="M360.096,295.052l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646
		l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666
		l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C357.694,293.678,359.638,293.678,360.096,295.052z"/>
           <path style="fill:#FFE15A;" d="M318.395,139.419l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646
		l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666
		l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C315.992,138.045,317.935,138.045,318.395,139.419z"/>
           <path style="fill:#FFE15A;" d="M204.463,336.754l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646
		l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666
		l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C202.061,335.38,204.004,335.38,204.463,336.754z"/>
           <path style="fill:#FFE15A;" d="M357.236,181.121l-3.54,10.613l-11.187,0.087c-1.449,0.011-2.049,1.859-0.884,2.72l9,6.646
		l-3.374,10.666c-0.437,1.38,1.135,2.524,2.314,1.681l9.101-6.506l9.101,6.506c1.178,0.842,2.751-0.3,2.314-1.681l-3.374-10.666
		l9-6.646c1.165-0.861,0.565-2.709-0.884-2.72l-11.187-0.087l-3.54-10.613C359.638,179.747,357.694,179.747,357.236,181.121z"/>
           <path style="fill:#FFE15A;" d="M159.902,295.052l-3.54,10.613l-11.187,0.087c-1.449,0.011-2.049,1.859-0.884,2.72l9,6.646
		l-3.374,10.666c-0.437,1.38,1.135,2.524,2.314,1.681l9.101-6.506l9.101,6.506c1.178,0.842,2.751-0.3,2.314-1.681l-3.374-10.666
		l9-6.646c1.165-0.861,0.565-2.709-0.884-2.72l-11.187-0.087l-3.54-10.613C162.303,293.678,160.36,293.678,159.902,295.052z"/>
           <path style="fill:#FFE15A;" d="M201.603,139.419l-3.54,10.613l-11.187,0.087c-1.449,0.011-2.049,1.859-0.884,2.72l9,6.646
		l-3.374,10.666c-0.437,1.38,1.135,2.524,2.314,1.681l9.101-6.506l9.101,6.506c1.178,0.842,2.751-0.3,2.314-1.681l-3.374-10.666
		l9-6.646c1.165-0.861,0.565-2.709-0.884-2.72l-11.187-0.087l-3.54-10.613C204.004,138.045,202.061,138.045,201.603,139.419z"/>
           <path style="fill:#FFE15A;" d="M315.534,336.754l-3.54,10.613l-11.187,0.087c-1.449,0.011-2.049,1.859-0.884,2.72l9,6.646
		l-3.374,10.666c-0.437,1.38,1.135,2.524,2.314,1.681l9.101-6.506l9.101,6.506c1.178,0.842,2.751-0.3,2.314-1.681l-3.374-10.666
		l9-6.646c1.165-0.861,0.565-2.709-0.884-2.72l-11.187-0.087l-3.54-10.613C317.935,335.38,315.992,335.38,315.534,336.754z"/></g><g></g><g></g><g></g><g>
      </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g>
      </g><g></g><g></g><g></g>
      </svg>
     </h4>
      <div role="alert" class="alert alert-danger">
        <span data-notify="message">
          <span>
          <b>Atenção!</b> É de extrema importância que coloque todos os dados desse endereço para que sua encomenda seja identificada.</span>
        </span>
      </div>
    </div>
    <l-table :data="tableData">
      <template slot-scope="{row}">
        <td width="35%"><strong>{{ row.title }}</strong></td>
        <td class="td-actions d-flex text-left">
          {{ row.value }}
        </td>
      </template>
    </l-table>
  </card>
</template>
<script>
import {Table as LTable} from 'src/components/index'

export default {
  components: {
    LTable
  },
  data() {
    return {
      profile: {},
      tableData: [],
    }
  },
  methods: {
    addressUser() {
      this.loading = true;
      this.$Progress.start();
      axios.get('user/myaddress').then((res) => {
        this.tableData = res.data.data[0];
        this.$notify(
          {
            message: 'Endereço carregado com sucesso!',
            type: 'success',
            icon: 'fa fa-check'
          });
        this.loading = false;
        this.$Progress.finish();
      }).catch((error) => {
        if (error) {
          Exception.handle(error);
        }
        swal({
          title: "Erro!",
          text: "Há algo errado.",
          type: "warning",
          confirmButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        });
        this.loading = false;
        this.$Progress.fail();
      });
    }
  },
  created() {
    this.profile.picture = baseURLIMGUSER + User.userProfile().profile_picture;
    this.profile.name = User.userProfile().name;
    this.profile.suite = User.userProfile().suite;
    this.profile.created_at = User.userProfile().created_at;
    this.addressUser();
  }
}
</script>
<style>

</style>
