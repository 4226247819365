<template>
  <div>
    <div class="row">
      <div class="col-12 pr-0">
        <card class="mb-2">
          <div class="row">
            <div class="col-3 col-md-3 col-sm-12">
              <el-select
                name="Status"
                class="select-default d-block m-1"
                label="Status"
                placeholder="Status"
                v-model="searchFilters.status"
                block
                @change="onChangeStatusFilter()"
              >
                <el-option v-for="option in selects.cartStatus"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-3 col-md-3 col-sm-12">
              <fg-input
                placeholder="Suite"
                inputmode="numeric"
                type="text"
                v-model="searchFilters.suite"
                ref="suite"
                class="m-1"
              >
              </fg-input>
            </div>
            <div class="col-3 col-md-3 col-sm-12">
              <fg-input
                placeholder="Rastreio"
                type="text"
                v-model="searchFilters.tracking"
                ref="tracking"
                class="m-1"
              >
              </fg-input>
            </div>
            <div class="col-3 col-md-3 col-sm-12">
              <fg-input
                placeholder="Código de Referencia"
                type="text"
                v-model="searchFilters.reference_code"
                ref="reference_code"
                class="m-1"
              >
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-4 col-md-4 col-sm-12">
              <fg-input
                placeholder="Código Produto"
                inputmode="numeric"
                type="text"
                v-model="searchFilters.product_id"
                ref="product_id"
                class="m-1"
              >
              </fg-input>
            </div>
            <div class="col-4 col-md-4 col-sm-12">
              <fg-input
                placeholder="Código Envio"
                inputmode="numeric"
                type="text"
                v-model="searchFilters.send_code"
                ref="product_id"
                class="m-1"
              >
              </fg-input>
            </div>
            <div class="col-4 col-md-4 col-sm-12">
              <el-select
                label="Opção de Envio"
                class="select-primary d-block m-1"
                v-model="searchFilters.shipping_option_id"
                placeholder="Opção de Envio"
                filterable
                block
              >
                <el-option
                  v-for="shippingFee in shipping_options.shipping_option"
                  class="select-primary"
                  :value="shippingFee.value"
                  :label="shippingFee.label"
                  :key="shippingFee.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 col-sm-12 offset-md-6">
              <button class="btn btn-warning btn-block btn-wd text-uppercase" @click.prevent="filterClear()">
                <i class="fa fa-trash"></i>
                limpar
              </button>
            </div>
            <div class="col-12 col-md-3 col-sm-12">
              <button class="btn btn-primary btn-block btn-wd text-uppercase" @click.prevent="filterQuery()">
                <i class="fa fa-search"></i>
                filtrar
              </button>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row" v-show="!loadingSearch">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="m-0 m-0">
            <div class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-default mb-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <!--              <el-input-->
              <!--                type="search"-->
              <!--                class="mb-3"-->
              <!--                style="width: 200px"-->
              <!--                placeholder="Pesquiser"-->
              <!--                v-model="searchQuery"-->
              <!--                aria-controls="datatables"-->
              <!--              />-->
            </div>
            <div class="col-sm-12">
              <el-table stripe
                        style="width: 100%;"
                        :data="queriedData"
                        border>
                <el-table-column v-for="column in tableColumns"
                                 :key="column.label"
                                 :min-width="column.minWidth"
                                 :prop="column.prop"
                                 :label="column.label">
                </el-table-column>

                <el-table-column
                  :min-width="250"
                  fixed="right"
                  label="Conteudo"
                  align="center"
                >
                  <template slot-scope="props">
                    <p class="text-body small p-0 m-0"
                       v-for="product in props.row.products" :key="product.id"
                    >
                      Código Produto: {{ product.id }} / Quan: {{ product.amount }}
                    </p>
                  </template>
                </el-table-column>

                <el-table-column
                  :min-width="180"
                  fixed="right"
                  label="Status"
                  align="center"
                >
                  <template slot-scope="props">
                    {{ cart_status[props.row.id] }}
                  </template>
                </el-table-column>

                <el-table-column
                  :min-width="70"
                  fixed="right"
                  label="Ações"
                  align="center"
                >
                  <template slot-scope="props">

                    <a v-tooltip.top-center="'Observações'" class="btn-success btn-simple btn-link cursor"
                       @click="openCartObservations(props.row.id,props.row.observations)"
                    >
                      <i class="fa fa-comments"></i>

                    </a>&nbsp;&nbsp;

                    <a v-tooltip.top-center="'Detalhes'" class="btn-info btn-simple btn-link"
                       @click="openCartDetails(props.$index, props.row)"
                    >
                      <i class="fa fa-eye"></i>
                    </a>


                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="" v-if="meta">
              <p class="card-category">Exibindo {{ meta.from }} até {{ meta.to }} de um total de {{ meta.total }}
                registros</p>
            </div>
            <ul class="pagination">
              <li class="page-item" :class="{active: link.active}" v-for="link in meta.links" :key="link.label">
                <a class="page-link" @click.prevent="changePage(link.label)">{{ link.label }}</a>
              </li>
            </ul>
          </div>
        </card>
      </div>
    </div>

    <!-- START DETAILS -->
    <el-dialog
      :visible.sync="modals.details"
      width="80%"
    >
      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Detalhes do Envio</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
          <tr>
            <th class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="p-1 m-0 text-left text-secondary">
              Envio: #{{ sendId }} &nbsp; &nbsp;
              Data: {{ sendDate }}
              Opção de Envio: {{ shippingOption }}
            </td>
          </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-12 col-md-3 col-sm-12">
            <a :href="printDelacration(sendId)" target="_blank">
              <l-button size="sm" block>
                Imprimir Delaração
              </l-button>
            </a>
          </div>
          <div class="col-12 col-md-3 col-sm-12">
            <a :href="printShippingLabel(sendId)" target="_blank">
              <l-button size="sm" block>
                Imprimir Etiqueta de Envio
              </l-button>
            </a>
          </div>
          <div class="col-12 col-md-3 col-sm-12">
            <a :href="printShippingSimpleLabel(sendId)" target="_blank">
              <l-button size="sm" block warning>
                Imprimir Etiqueta de Envio Simples
              </l-button>
            </a>
          </div>
        </div>
      </card>

      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Dados do Cliente.</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
          <tr>
            <th class="text-center">Suite</th>
            <th class="text-center">Nome Completo</th>
            <th class="text-center">Telefone</th>
            <th class="text-center">E-mail</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="p-1 m-0 text-center text-secondary" width="5%">{{ sendUser.suite }}</td>
            <td class="p-1 m-0 text-center text-secondary">{{ sendUser.name }} {{ sendUser.last_name }}</td>
            <td class="p-1 m-0 text-center text-secondary">{{ sendUser.cell_phone }}</td>
            <td class="p-1 m-0 text-center text-secondary">{{ sendUser.email }}</td>
          </tr>
          </tbody>
        </table>
      </card>

      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Dados do Carrinho.</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
          <tr>
            <th class="text-center">Altura</th>
            <th class="text-center">Largura</th>
            <th class="text-center">Comprimento</th>
            <th class="text-center">Peso</th>
            <th class="text-center">Peso Dimensional</th>
            <th class="text-center">Valor Ser. Extras</th>
            <th class="text-center">Taxa Paypal</th>
            <th class="text-center">ação</th>

          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-center">
              <fg-input type="text"
                        v-model="cart_sizes.height"
                        v-mask="['###']"
                        @change="cartSizes"
              >
              </fg-input>
            </td>
            <td class="text-center">
              <fg-input type="text"
                        v-model="cart_sizes.width"
                        v-mask="['###']"
                        @change="cartSizes"
              >
              </fg-input>
            </td>
            <td class="text-center">
              <fg-input type="text"
                        v-model="cart_sizes.length"
                        v-mask="['###']"
                        @change="cartSizes"
              >
              </fg-input>
            </td>
            <td class="text-center">
              <fg-input type="text"
                        v-model="cart_sizes.weight"
                        v-mask="['#.###','##.###','###.###']"
                        @change="cartSizes"
              >
              </fg-input>
            </td>
            <td class="text-center">
              <fg-input type="text"
                        v-model="cart_sizes.dimensional_weight"
                        v-mask="['#.###','##.###','###.###']"
              >
              </fg-input>
            </td>
            <td class="text-center">
              <fg-input type="text"
                        v-model="cart_sizes.extra_service_price"
                        v-mask="['#.##','##.##','###.##']"
              >
              </fg-input>
            </td>
            <td class="text-center">
              {{ paypal_rate }}%
            </td>

            <td class="text-center">
              <button class="btn btn-primary" @click="cartChangePrices(sendCart.id)">salvar</button>
            </td>
          </tr>
          </tbody>
        </table>

        <table class="table table-hover table-responsive-md">
          <thead>
          <tr>
            <th class="text-center">rastreio</th>
            <th class="text-center">status</th>
            <th class="text-center">foto</th>
          </tr>
          </thead>
          <tbody>
          <tr>

            <td class="text-center">
              <fg-input type="text"
                        v-model="cart_tracking[sendCart.id]"
                        @focusout.self="cartChangeTracking(sendCart.id, sendCart)"
              >
              </fg-input>
            </td>
            <td class="text-center">
              <el-select class="select-default form-group"
                         size="large"
                         :placeholder="selects.place.label"
                         v-model="cart_status[sendCart.id]"
                         @change="cartChangeStatus(sendCart.id, sendCart)"
              >
                <el-option v-for="option in selects.cartStatus"
                           class="select-primary"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label"
                >
                </el-option>
              </el-select>
            </td>
            <td class="text-center">
              <label class="file-select">
                <div class="btn btn-info btn-fill btn-md">
                  <span v-if="cartSend.image">{{ cartSend.image.name }}</span>
                  <span v-else>Foto da Caixa</span>
                </div>
                <input class="mb-0" label="Foto da Caixa"
                       placeholder="Foto da Caixa"
                       type="file"
                       id="image" ref="image"
                       v-on:change="onChangeCartImage(sendId,sendCart.suite)"
                       accept="image/*" capture
                />
              </label>
            </td>
          </tr>
          </tbody>
        </table>

      </card>

      <card class="m-0 mb-2">

        <div class="row">
          <div class="col-md-3" v-for="(attachment, indexAttachment) in sendAttachments" :key="indexAttachment">
            <div>
              <a :href="attachment.original_url" target="_blank">
                <l-button size="sm" block>
                  {{ attachment.file_name }}
                </l-button>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <label class="file-select">
              <div class="btn btn-warning btn-block btn-md">
                <span v-if="cartSend.attachment">{{ cartSend.attachment.name }}</span>
                <span v-else>Anexar</span>
              </div>
              <input
                class="mb-0"
                label="Anexar"
                placeholder="Anexar"
                type="file"
                id="attachment"
                ref="attachment"
                v-on:change="onChangeCartAttachment(sendId, sendCart.suite)"
              />
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <fg-input label="Nota">
                <textarea
                  class="form-control"
                  rows="3"
                  v-model="updatePatch.note"
                ></textarea>
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-sm-12 text-right">
            <l-button type="disabled" disabled size="sm" v-show="loading">
              <span class="btn-label">
                <i class="fa fa-save"></i>
              </span>
              &nbsp;&nbsp;SALVAR
            </l-button>
            <l-button type="primary" size="sm" @click="setCartNote(sendCart.id)" v-show="!loading">
              <span class="btn-label">
                <i class="fa fa-save"></i>
              </span>
              &nbsp;&nbsp;SALVAR
            </l-button>
          </div>
        </div>
      </card>

      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Produtos solicitados.</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
          <tr>
            <th class="text-center">Foto</th>
            <th class="text-center">Código Produto</th>
            <th class="text-center">Localização</th>
            <th class="text-center">Referência</th>
            <th class="text-center">Qt Estoque</th>
            <th class="text-center">Qt Enviar</th>
            <th class="text-center">Peso</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="product in sendProducts" :key="product.id">
            <td class="p-1 m-0" width="70">

              <div class="img-fluid" style="cursor: pointer;">
                <img
                  :src="productImage(product)"
                  alt="Foto"
                  class="img-thumbnail"
                  @click="productImageList(product)"
                >
              </div>

            </td>
            <td class="p-1 m-0 text-center text-secondary" width="120">{{ product.id }}</td>
            <td class="p-1 m-0 text-center text-secondary">{{ product.localization }}</td>
            <td class="p-1 m-0 text-center text-secondary">{{ product.reference_code || '-' }}</td>
            <td class="p-1 m-0 text-center text-secondary">{{ product.amount_stock }}</td>
            <td class="p-1 m-0 text-center text-secondary">{{ product.amount }}</td>
            <td class="p-1 m-0 text-center text-secondary">{{ product.weight }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="5">
              <table class="table table-responsive-md m-0 p-0">
                <tr style="border: 0px;">
                  <td class="m-0 p-0 b-0" style="border: 0px;" width="30%">
                      <span class="text-left">
                        <strong>Total de produstos:</strong>&nbsp;
                      </span>
                  </td>
                  <td class="m-0 p-0 b-0 text-left" style="border: 0px;"><span class="text-right">{{
                      totalQntProduct
                    }}</span></td>
                </tr>
                <tr style="border: 0px;">
                  <td class="m-0 p-0 b-0" style="border: 0px;">
                      <span class="text-left">
                        <strong>Peso Total dos produstos:</strong>&nbsp;
                      </span>
                  </td>
                  <td class="m-0 p-0 b-0 text-left" style="border: 0px;"><span class="text-right">{{
                      totalProductWeight
                    }} kg</span></td>
                </tr>
                <tr>
                  <td class="m-0 p-0" style="border: 0px;">
                      <span class="text-left">
                        <strong>Peso estimado da caixa:</strong>&nbsp;
                      </span>
                  </td>
                  <td class="m-0 p-0 b-0 text-left" style="border: 0px;"><span
                    class="text-right">{{ totalProductWeightApproximate }} kg</span></td>
                </tr>
              </table>
            </td>
          </tr>
          </tfoot>
        </table>
      </card>

      <div class="row">
        <div class="col-md-8">
          <card class="m-0 mb-2">
            <div class="card-header p-0">
              <h5 class="card-title">Endereço de entrega.</h5>
            </div>
            <table class="table table-hover table-responsive-md">
              <tbody>
              <tr>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Apelido do Endereço:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.name_address }}
                </td>
                <td
                  class="p-1 m-0 text-left text-secondary font-weight-bold"
                ></td>
                <td class="p-1 m-0 text-left text-secondary"></td>
              </tr>
              <tr>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Destinatário:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.recipient_name }}
                </td>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Telefone destinatário:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.recipient_phone }}
                </td>
              </tr>

              <tr>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Endereço:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.address }}
                </td>
                <td class="p-1 m-0 text-left text-secondary"></td>
                <td class="p-1 m-0 text-left text-secondary"></td>
              </tr>

              <tr>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Complemento:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.complement }}
                </td>
                <td
                  class="p-1 m-0 text-left text-secondary font-weight-bold"
                ></td>
                <td class="p-1 m-0 text-left text-secondary"></td>
              </tr>
              <tr>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Cidade / Localidade:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.city }}
                </td>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Estado / Província / Região:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.state }}
                </td>
              </tr>
              <tr>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Pais:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.country }}
                </td>
                <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                  Código Postal / Cep:
                </td>
                <td class="p-1 m-0 text-left text-secondary">
                  {{ sendAddress.post_code }}
                </td>
              </tr>
              </tbody>
            </table>
          </card>
        </div>
        <div class="col-md-4">
          <card class="m-0 mb-2">
            <div class="card-header p-0">
              <h5 class="card-title">Opção de pagamento.</h5>
            </div>
            <h5 class="card-title pt-2 pb-2">
              <strong>{{ sendCart.payment_option }}</strong>
            </h5>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <card class="m-0 mb-2" v-if="sendDeclarations.length > 0">
            <div class="card-header p-0">
              <h5 class="card-title">Declaração Alfandegaria.</h5>
            </div>
            <table class="table table-hover table-responsive-md">
              <thead>
              <tr>
                <th>Item</th>
                <th class="text-center">Quantidade</th>
                <th class="text-center">Valor Unit.</th>
                <th class="text-center">Valor Total.</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="declaration in sendDeclarations" :key="declaration.id">
                <td class="p-1 m-0 text-secondary">{{ declaration.name }}</td>
                <td class="p-1 m-0 text-center text-secondary">{{ declaration.amount }}</td>
                <td class="p-1 m-0 text-center text-secondary">{{ declaration.unit_price }}</td>
                <td class="p-1 m-0 text-center text-secondary">{{ declaration.total_price }}</td>
              </tr>
              </tbody>
            </table>
          </card>


          <card class="m-0 mb-2" v-else>
            <div class="card-header p-0">
              <h5 class="card-title text-center"><strong>Sem solicitação de declaração alfandegaria.</strong></h5>
            </div>
          </card>

        </div>


        <div class="col-md-6">
          <card class="m-0 mb-2" v-if="sendServices.length > 0">
            <div class="card-header p-0">
              <h5 class="card-title">Serviços Extras.</h5>
            </div>
            <table class="table table-hover table-responsive-md">
              <thead>
              <tr>
                <th>Serviço</th>
                <th class="text-center">Valor</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="service in sendServices" :key="service.id">
                <td class="p-1 m-0 text-center text-secondary">{{ service.name }}</td>
                <td class="p-1 m-0 text-center text-secondary">{{ service.price }}</td>
              </tr>
              </tbody>
            </table>
          </card>

          <card class="m-0 mb-2" v-else>
            <div class="card-header p-0">
              <h5 class="card-title text-center"><strong>Sem solicitação de serviços extras.</strong></h5>
            </div>
          </card>

        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <card class="m-0 mb-2" v-if="sendFirstObservation.length > 0">
            <div class="card-header p-0">
              <h5 class="card-title">Observações.</h5>
            </div>
            <p>{{ sendFirstObservation[0].text }}</p>
          </card>

          <card class="m-0 mb-2" v-else>
            <div class="card-header p-0">
              <h5 class="card-title text-center"><strong>Sem observação na soliciatação do envio.</strong></h5>
            </div>
          </card>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('details')">cancelar</button>&nbsp;
    </span>
    </el-dialog>
    <!-- END DETAILS -->

    <!-- START IMAGE -->
    <el-dialog
      title="Fotos do Produto"
      :visible.sync="modals.image"
      width="40%"
    >
      <div class="row">
        <div class="col-md-12">
            <span class="img-fluid">
              <img :src="images.one" class="img-fluid">
            </span>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('image')">fechar</button>&nbsp;
      </span>
    </el-dialog>
    <!-- END IMAGE -->

    <el-dialog
      title="Observações do envio"
      :visible.sync="modals.observation"
      width="40%"
    >

      <div class="mesgs">
        <div class="msg_history">

          <div v-for="observation in sendObservations" :key="observation.id">

            <div class="incoming_msg" v-if="observation.role == 'user'">
              <!-- <div class="incoming_msg_img">
                <img :src="observationUserImage(observation.user_id, observation.profile_picture)" class="rounded-circle img-thumbnail">
              </div> -->

              <div class="received_msg">
                <div class="received_withd_msg">
                  <p>{{ observation.text }}</p>
                  <span class="time_date">{{ observation.user_full_name }} | {{ observation.created_at }}</span></div>
              </div>
            </div>

            <div class="outgoing_msg" v-if="observation.role == 'admin'">
              <div class="sent_msg">
                <p>{{ observation.text }}</p>
                <span class="time_date">{{ observation.user_full_name }} | {{ observation.created_at }}</span></div>
            </div>

          </div>

        </div>
        <div class="type_msg">
          <div class="input_msg_write">
            <input type="text" class="write_msg" v-model="sendObservation.text" placeholder="Digite uma mensagem"/>
            <button class="msg_send_btn" type="button" v-show="sendObservation.text">
              <i class="fa fa-paper-plane" aria-hidden="true" @click="addObservation"></i>
            </button>
            <button class="msg_send_btn_off" type="button" v-show="!sendObservation.text">
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('observation')">fechar</button>&nbsp;
      </span>
    </el-dialog>


  </div>
</template>
<script>
import {Dialog, Option, Select, Table, TableColumn} from 'element-ui'
import {Pagination as LPagination} from 'src/components/index'
import Fuse from 'fuse.js'
import {mask} from "vue-the-mask"

export default {
  components: {
    LPagination,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  directives: {mask},
  data() {
    return {
      loadingSearch: false,
      loading: false,
      modals: {
        details: false,
        image: false,
        centered: false,
        observation: false,
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["suite", "id", "tracking"],
      tableColumns: [
        {prop: "suite", label: "suite", align: "center", fit: true},
        {prop: "id", label: "envio", align: "center", fit: true},
      ],
      tableData: [],
      fuseSearch: null,
      selects: {
        place: "",
        cartStatus: [
          {value: "0", label: "novo"},
          {value: "1", label: "pago"},
          {value: "2", label: "em processamento"},
          {value: "3", label: "cancelado"},
          {value: "4", label: "finalizado"},
          {value: "5", label: "aguardando pagamento"},
          {value: "6", label: "pendente"},
          {value: "7", label: "aguardando escolha do frete"},
          {value: "8", label: "aguardando aprovação"},
          {value: "9", label: "em despacho"},
        ],
      },
      cart_status: [],
      cart_tracking: [],
      cart_prices: {},
      cart_sizes: {
        height: 0,
        width: 0,
        length: 0,
        weight: 0,
        dimensional_weight: 0,
        extra_service_price: 0.00,
      },
      paypal_rate: 0,
      cart_total_price: [],
      sendId: "",
      sendDate: "",
      shippingOption: "",
      sendProducts: {},
      sendAddress: {},
      sendAddressNull: {
        address: "-",
        city: "-",
        complement: "-",
        country: "-",
        created_at: "2020-02-27 10:45:27",
        default: "-",
        deleted_at: null,
        district: "-",
        id: 0,
        name_address: "ENDEREÇO NÃO INFORMADO",
        number: "-",
        post_code: "-",
        recipient_cpf: "-",
        recipient_name: "-",
        state: "-",
        updated_at: "2020-02-27 10:45:27",
        user_id: 0,
      },
      sendDeclarations: {},
      sendServices: {},
      sendUser: {},
      sendCart: {},
      sendAttachments: {},
      totalQntProduct: 0,
      totalProductWeight: 0,
      totalProductWeightApproximate: 0,
      cartSend: {
        image: "",
      },
      images: {
        one: "",
      },
      sendObservation: {},
      sendFirstObservation: {},
      sendObservations: {},
      meta: {},
      paginate: 50,
      searchFilters: [],
      page: 0,
      updatePatch: {
        note: ''
      },
      shipping_options: {
        simple: "",
        shipping_option: []
      }
    }
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["suite", "id", "tracking"],
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.pagination.total = result.length;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  methods: {
    filterClear() {
      this.searchFilters = []
      this.loadCarts()
    },
    onChangeStatusFilter() {
      let filter = this.searchFilters
      this.searchFilters = [];
      this.searchFilters = filter
    },
    filterQuery() {
      let params = this.searchFilters;
      this.params = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      this.loadCarts()
    },
    printDelacration(cartId) {
      return baseURL + "carts/" + cartId + "/declaration"
    },
    printShippingLabel(cartId) {
      return baseURL + "carts/" + cartId + "/shipping-label"
    },
    printShippingSimpleLabel(cartId) {
      return baseURL + "carts/" + cartId + "/shipping-simple-label"
    },
    setCartNote(cartId) {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "right",
        verticalAlign: "top",
      });
      axios
        .patch("/carts/" + cartId + "/note", this.updatePatch)
        .then(() => {
          this.$notify({
            message: "Dados atualizado com sucesso.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });
    },
    loadCarts() {
      this.loadingSearch = true;
      this.$Progress.start();
      this.pagination.perPage = this.paginate
      axios
        .get("/paypalrate/rate")
        .then(({data}) => {
          this.paypal_rate = data.data[0].rate;
          this.$forceUpdate()
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loading = false;
          this.$Progress.fail();
        });

      axios
        .get("/carts?page=" + this.page + "&limit=" + this.paginate + "&" + this.params)
        .then(({data}) => {
          this.tableData = data.data;
          this.meta = data.meta;
          for (let i = 0; i < data.data.length; i++) {
            this.cart_status[data.data[i].id] = data.data[i].status;
            this.cart_tracking[data.data[i].id] = data.data[i].tracking;
            this.cart_total_price[data.data[i].id] = data.data[i].total_price;
          }
          this.$Progress.finish();
          this.loadingSearch = false;
          this.$forceUpdate()
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loading = false;
          this.$Progress.fail();
        });
    },
    productImage(product) {
      return (
        baseURLIMGUSER +
        "/" +
        product.user_id +
        "/box/product/thumbnail_" +
        product.image
      );
    },
    onChangeCartImage(id, suite) {
      this.cartSend.image = this.$refs.image.files[0];
      this.cartChangeImage(id, suite);
    },
    onChangeCartAttachment(id, suite) {
      this.cartSend.attachment = this.$refs.attachment.files[0];
      this.cartAddAttachment(id, suite);
    },
    cartAddAttachment(id, suite) {
      this.loading = true;
      this.$Progress.start();

      let formData = new FormData();

      formData.append("attachment", this.cartSend.attachment);
      formData.append("id", id);

      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });

      axios
        .post("/carts/" + id + "/attachment", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$notify({
            message: "Anexo adicionado.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });
    },
    productImageList(row) {
      this.modals["image"] = true;
      this.images.one =
        baseURLIMGUSER + "/" + row.user_id + "/box/product/" + row.image;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    totalWeigth(products) {
      let productsWeight = products
        .reduce(
          (total, item) => parseFloat(total + item.amount * item.weight),
          0
        )
        .toFixed(3);
      if (productsWeight) {
        return productsWeight;
      }
    },
    approximate(products) {
      let productsWeight = products
        .reduce(
          (total, item) => parseFloat(total + item.amount * item.weight),
          0
        )
        .toFixed(3);
      if (productsWeight) {
        let weight = parseFloat(productsWeight * 0.17).toFixed(3);
        return (parseFloat(productsWeight) + parseFloat(weight)).toFixed(3);
      }
    },
    cartSizes() {
      let weightDimensional = (
        parseFloat(this.cart_sizes.height) *
        parseFloat(this.cart_sizes.width) *
        parseFloat(this.cart_sizes.length) / 5000
      ).toFixed(3);

      return this.cart_sizes.dimensional_weight = weightDimensional;
    },
    addObservation() {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .post("cartobservation", this.sendObservation)
        .then((response) => {
          this.$notify({
            message: response.data.message,
            type: "success",
            icon: "fa fa-check",
          });
          this.loading = false;
          this.$Progress.finish();
          this.loadCarts();
          this.sendObservation.cart_id = "";
          this.sendObservation.text = "";
          this.closeModal("observation");
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: error.response.data.error,
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.closeModal("observation");
          this.loading = false;
          this.$Progress.fail();
        });
    },
    openCartObservations(cartId, observations) {
      this.modals["observation"] = true;
      this.sendObservation.cart_id = cartId;
      this.sendObservations = observations;
    },
    observationUserImage(userId, image) {
      if (image == "no-img-profile.png") {
        return baseURLIMGUSER + "/" + image;
      } else {
        return baseURLIMGUSER + userId + "/" + image;
      }
    },
    openCartDetails(index, row) {
      this.modals["details"] = true;
      this.sendId = row.id;
      this.updatePatch.note = row.note;
      this.sendDate = row.created_at;
      this.shippingOption = row.shipping_option;
      this.sendProducts = row.products;
      this.sendAddress = row.send_address
        ? row.send_address
        : this.sendAddressNull;
      this.sendDeclarations = row.declarations;
      this.sendServices = row.services;
      this.sendUser = row.user;
      this.sendCart = row;
      this.sendAttachments = row.attachments;
      this.sendFirstObservation = row.first_observation;
      this.cart_prices = row.cart_prices;
      this.cart_sizes = row.cart_sizes;
      this.totalQntProduct = row.products.reduce(
        (total, item) => total + item.amount,
        0
      );
      this.totalProductWeight = this.totalWeigth(row.products);
      this.totalProductWeightApproximate = this.approximate(row.products);
    },
    changePage(page) {
      let new_page = page
      if (new_page === '«') {
        new_page = this.meta.current_page - 1
      }
      if (new_page === '»') {
        new_page = this.meta.current_page + 1
      }
      this.page = new_page;
      this.loadCarts()
    },
    cartChangeImage(id, suite) {
      this.loading = true;
      this.$Progress.start();

      let formData = new FormData();

      formData.append("image", this.cartSend.image);
      formData.append("suite", suite);
      formData.append("id", id);

      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });

      axios
        .post("/cart/changeimage", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$notify({
            message: "Imagem atualizada.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });
    },
    cartChangePrices(id) {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "right",
        verticalAlign: "top",
      });
      axios
        .put("/cart/changetotalprice/" + id, this.cart_sizes)
        .then(() => {
          this.$notify({
            message: "Dados atualizado com sucesso.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });
    },
    cartChangeStatus(index, row) {
      let status = this.cart_status[row.id];
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .put("/cart/changestatus/" + row.id, "status=" + status)
        .then(() => {
          this.$notify({
            message: "Status atualizado com sucesso.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });

      if (status == 3) {
        axios
          .delete("/cart/" + row.id)
          .then((response) => {
            if (indexToDelete >= 0) {
              this.tableData.splice(indexToDelete, 1);
            }
            swal({
              title: "Sucesso!",
              text: response.data.message,
              type: "success",
              confirmButtonClass: "btn btn-danger btn-fill",
              buttonsStyling: false,
            });
            this.loadCarts();
            this.loading = false;
            this.$Progress.finish();
            this.modals["details"] = false;
          })
          .catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            swal({
              title: "Erro!",
              text: error.response.data.error,
              type: "warning",
              confirmButtonClass: "btn btn-danger btn-fill",
              buttonsStyling: false,
            });
            this.loadCarts();
            this.loading = false;
            this.$Progress.fail();
            this.modals["details"] = false;
          });
      }
    },
    cartChangeTracking(index, row) {
      let tracking = this.cart_tracking[row.id];
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .put("/cart/changetracking/" + row.id, "tracking=" + tracking)
        .then(() => {
          this.$notify({
            message: "Rastreio atualizado com sucesso.",
            type: "success",
            icon: "fa fa-check",
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loadCarts();
          this.loading = false;
          this.$Progress.fail();
        });
    },
    loadShippingOption() {
      axios
        .get("/shippingoption-select")
        .then(({data}) => (this.shipping_options.shipping_option = data.data))
        .catch(error => {
          if (error) {
            Exception.handle(error);
          }
        });
    },
    init() {
      if (this.$route.params.status) {
        this.searchFilters.status = this.$route.params.status
        this.filterQuery()
      } else {
        this.loadCarts(0);
      }
    }
  },
  mounted() {
    this.init();
    this.loadShippingOption();
  },
}

</script>
<style>
.swal2-container {
  z-index: 10000;
}

.file-select > .select-button {
  padding: 0.2rem;
  color: white;
  background-color: #2ea169;
  border-radius: 0.3rem;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.file-select > input[type="file"] {
  display: none;
}

.cursor {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}

.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}

.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}

.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}

.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}

.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}

.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}

.chat_ib h5 span {
  font-size: 13px;
  float: right;
}

.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}

.chat_img {
  float: left;
  width: 11%;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 12%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 88%;
}

.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.received_withd_msg {
  width: 57%;
}

.mesgs {
  /* float: left;

   */
  padding: 30px 15px 0 25px;
}

.sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.sent_msg {
  float: right;
  width: 60%;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}

.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.msg_send_btn_off {
  background: #c4c4c4 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.messaging {
  padding: 0 0 20px 0;
}

.msg_history {
  height: 400px;
  overflow-y: auto;
}
</style>
