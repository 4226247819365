<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <div class="row mr-2">
            <div class="col-md-4">
              <h4 class="card-title">Meus Endereços</h4>
            </div>
            <div class="col-md-2 offset-md-6">
              <l-button wide type="success" size="sm" @click="createAddress">
                <span class="btn-label">
                  <i class="fa fa-check"></i>
                </span>
                &nbsp;&nbsp;Adicionar
              </l-button>
            </div>
          </div>
        </div>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-input
              type="search"
              class="mb-3"
              style="width: 200px"
              placeholder="Pesquiser"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div class="col-sm-12">
            <el-table stripe style="width: 100%" :data="queriedData" border>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column
                :min-width="95"
                fixed="right"
                label="Ações"
                align="center"
              >
                <template slot-scope="props">
                  <a
                    v-if="props.row.default"
                    v-tooltip.top-center="'Favorito'"
                    class="btn-success btn-simple btn-link"
                  >
                    <i class="fa fa-heart"></i
                  ></a>

                  <a
                    v-else
                    v-tooltip.top-center="'Marcar como favorito'"
                    class="btn-info btn-simple btn-link"
                    @click="addressLike(props.$index, props.row)"
                  >
                    <i class="fa fa-heart"></i
                  ></a>

                  <a
                    v-tooltip.top-center="'Editar'"
                    class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"
                    ><i class="fa fa-edit"></i
                  ></a>

                  <a
                    v-tooltip.top-center="'Apagar'"
                    class="btn-danger btn-simple btn-link"
                    @click="addressDelete(props.$index, props.row)"
                    ><i class="fa fa-times"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Exibindo de {{ from + 1 }} até {{ to }} de {{ total }} registros
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>

    <el-dialog title="Adicionar novo endereço" :visible.sync="modals.basic">
      <div>
        <form>
          <div class="row">
            <div class="col-md-12">
              <fg-input
                type="text"
                label="Nome do endereço"
                name="name_address"
                v-model="useraddress.name_address"
                v-validate="modelValidations.name_address"
                :error="getError('address')"
              >
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <fg-input
                type="text"
                label="Nome do destinatário"
                name="recipient_name"
                v-model="useraddress.recipient_name"
                v-validate="modelValidations.recipient_name"
                :error="getError('recipient_name')"
              >
              </fg-input>
            </div>

            <div class="col-md-4">
              <label>Telefone Celular</label>
              <vue-tel-input
                class="mb-0"
                label="Telefone Celular"
                placeholder="Telefone Celular"
                type="text"
                required
                autocomplete="cc-tel"
                :valid-characters-only="true"
                v-model="useraddress.recipient_phone"
                v-validate="modelValidations.recipient_phone"
                :error="getError('recipient_phone')"
              >
              </vue-tel-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <fg-input
                type="text"
                label="Endereço Completo"
                placeholder="Ex: Avenida, Rua, Bairro, Número"
                name="address"
                v-model="useraddress.address"
                v-validate="modelValidations.address"
                :error="getError('address')"
              >
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input
                type="text"
                label="Complemento Endereço"
                placeholder="Ex: Apartamento, Bloco, Andar, Prédio."
                name="complement"
                v-model="useraddress.complement"
              >
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group has-label">
                <label>País</label>
                <el-select
                  class="select-default"
                  size="large"
                  name="country"
                  filterable
                  placeholder="Selecione um País"
                  v-model="useraddress.country"
                  @change="loadState(useraddress.country)"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group has-label">
                <label>Estado / Província / Região</label>
                <el-select
                  class="select-default"
                  size="large"
                  name="country"
                  filterable
                  placeholder="Selecione um estado"
                  v-model="useraddress.state"
                >
                  <el-option
                    v-for="option in selects.states"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5">
              <fg-input
                type="text"
                label="Cidade / Localidade"
                name="city"
                v-model="useraddress.city"
                v-validate="modelValidations.city"
                :error="getError('city')"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input
                type="text"
                label="Código Postal / Cep"
                name="post_code"
                v-model="useraddress.post_code"
                v-validate="modelValidations.post_code"
                :error="getError('post_code')"
              >
              </fg-input>
            </div>
          </div>
        </form>
      </div>

      <span v-show="!loading" slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('basic')">
          cancelar</button
        >&nbsp;
        <button class="btn btn-success" @click.prevent="validate">
          salvar
        </button>
      </span>

      <span v-show="loading" slot="footer" class="dialog-footer">
        <button class="btn btn-default" disabled>cancelar</button>&nbsp;
        <button class="btn btn-default" disabled>salvar</button>
      </span>
    </el-dialog>

    <el-dialog title="Editar endereço" :visible.sync="modals.editAddress">
      <div>
        <form>
          <div class="row">
            <div class="col-md-12">
              <fg-input
                type="text"
                label="Nome do endereço"
                name="name_address"
                v-model="editUseraddress.name_address"
                v-validate="modelEditValidations.name_address"
                :error="getError('address')"
              >
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <fg-input
                type="text"
                label="Nome do destinatário"
                name="recipient_name"
                v-model="editUseraddress.recipient_name"
                v-validate="modelEditValidations.recipient_name"
                :error="getError('recipient_name')"
              >
              </fg-input>
            </div>

            <div class="col-md-4">
              <label>Telefone Celular</label>
              <vue-tel-input
                class="mb-0"
                label="Telefone Celular"
                placeholder="Telefone Celular"
                type="text"
                required
                autocomplete="cc-tel"
                :valid-characters-only="true"
                v-model="editUseraddress.recipient_phone"
                v-validate="modelEditValidations.recipient_phone"
                :error="getError('recipient_phone')"
              >
              </vue-tel-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <fg-input
                type="text"
                label="Endereço Completo"
                placeholder="Ex: Avenida, Rua, Bairro, Número"
                name="address"
                v-model="editUseraddress.address"
                v-validate="modelEditValidations.address"
                :error="getError('address')"
              >
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input
                type="text"
                label="Complemento Endereço"
                placeholder="Ex: Apartamento, Bloco, Andar, Prédio."
                name="complement"
                v-model="editUseraddress.complement"
              >
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group has-label">
                <label>País</label>
                <el-select
                  class="select-default"
                  size="large"
                  name="country"
                  filterable
                  placeholder="Selecione um País"
                  v-model="editUseraddress.country"
                  @change="loadState(editUseraddress.country)"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group has-label">
                <label>Estado / Província / Região</label>
                <el-select
                  class="select-default"
                  size="large"
                  name="country"
                  filterable
                  placeholder="Selecione um estado"
                  v-model="editUseraddress.state"
                >
                  <el-option
                    v-for="option in selects.states"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5">
              <fg-input
                type="text"
                label="Cidade / Localidade"
                name="city"
                v-model="editUseraddress.city"
                v-validate="modelEditValidations.city"
                :error="getError('city')"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input
                type="text"
                label="Código Postal / Cep"
                name="post_code"
                v-model="editUseraddress.post_code"
                v-validate="modelEditValidations.post_code"
                :error="getError('post_code')"
              >
              </fg-input>
            </div>
          </div>
        </form>
      </div>

      <span v-show="!loading" slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('editAddress')">
          cancelar</button
        >&nbsp;
        <button class="btn btn-success" @click.prevent="validateEdit">
          salvar
        </button>
      </span>

      <span v-show="loading" slot="footer" class="dialog-footer">
        <button class="btn btn-default" disabled>cancelar</button>&nbsp;
        <button class="btn btn-default" disabled>salvar</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog, Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import "vue-tel-input/dist/vue-tel-input.css";
import VueTelInput from "vue-tel-input";
import Fuse from "fuse.js";

export default {
  components: {
    LPagination,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueTelInput,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["address", "complement"],
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.pagination.total = result.length;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  data() {
    return {
      loading: false,
      selects: {
        countries: [],
        states: [],
      },
      modals: {
        basic: false,
        editAddress: false,
        centered: false,
      },
      useraddress: {},
      editUseraddress: {},
      modelValidations: {
        name_address: {
          required: true,
        },
        recipient_name: {
          required: true,
        },
        recipient_phone: {
          required: true,
        },
        address: {
          required: true,
        },
        city: {
          required: true,
        },
        state: {
          required: true,
        },
        country: {
          required: true,
        },
        post_code: {
          required: true,
        },
      },
      modelEditValidations: {
        name_address: {
          required: true,
        },
        recipient_name: {
          required: true,
        },
        recipient_phone: {
          required: true,
        },
        address: {
          required: true,
        },
        city: {
          required: true,
        },
        state: {
          required: true,
        },
        country: {
          required: true,
        },
        post_code: {
          required: true,
        },
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["address", "complement"],
      tableColumns: [
        { prop: "name_address", label: "Apelido", minWidth: 100 },
        {
          prop: "recipient_name",
          label: "Destinatário",
          minWidth: 100,
        },
        {
          prop: "recipient_phone",
          label: "Telefone",
          minWidth: 100,
        },
        { prop: "address", label: "Endereço Completo", minWidth: 200 },
        { prop: "complement", label: "Complemento", minWidth: 90 },
        { prop: "city", label: "Cidade" },
        { prop: "state", label: "Estado" },
        { prop: "country", label: "País" },
        { prop: "post_code", label: "Código Postal" },
      ],
      tableData: [],
      fuseSearch: null,
      error: {},
    };
  },
  methods: {
    createAddress() {
      this.modals["basic"] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    addAddress() {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .post("user/address", this.useraddress)
        .then(() => {
          this.$notify({
            message: "Dados inseridos com sucesso!",
            type: "success",
            icon: "fa fa-check",
          });
          this.loading = false;
          this.$Progress.finish();
          this.loadAddress();
          this.closeModal("basic");
          this.useraddress = "";
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.closeModal("basic");
          this.loading = false;
          this.$Progress.fail();
        });
    },
    updateAddress() {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .put("user/address/" + this.editUseraddress.id, this.editUseraddress)
        .then(() => {
          this.$notify({
            message: "Dados atualizados com sucesso!",
            type: "success",
            icon: "fa fa-check",
          });
          this.loading = false;
          this.$Progress.finish();
          this.loadAddress();
          this.closeModal("editAddress");
          this.editUseraddress = "";
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: error.response.data.error,
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.closeModal("editAddress");
          this.loading = false;
          this.$Progress.fail();
        });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.addAddress();
        }
      });
    },
    getEditError(fieldName) {
      return this.errors.first(fieldName);
    },
    validateEdit() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.updateAddress();
        }
      });
    },
    loadAddress() {
      axios
        .get("user/address")
        .then(({ data }) => (this.tableData = data.data));
    },
    loadCountry() {
      this.$Progress.start();
      axios
        .get("country")
        .then(({ data }) => (this.selects.countries = data.data))
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.$Progress.fail();
        });
      this.$Progress.finish();
    },
    loadState(id) {
      this.$Progress.start();
      let country = id;
      if (!country) {
        country = "";
      }
      axios
        .get("state?country=" + country)
        .then(({ data }) => (this.selects.states = data.data))
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.$Progress.fail();
        });
      this.$Progress.finish();
    },
    addressLike(index, row) {
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .put("user/address/setdefault/" + row.id)
        .then(() => {
          this.$notify({
            message: "Endereço padrão configurado com sucesso!",
            type: "info",
            icon: "fa fa-check",
          });
          this.loadAddress();
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.$Progress.fail();
        });
    },
    handleEdit(index, row) {
      this.modals["editAddress"] = true;
      this.editUseraddress = row;
      console.log(row);
    },
    addressDelete(index, row) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      this.$Progress.start();
      swal({
        title: "Você tem certeza?",
        text: "Você não poderá recuperar este registro!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, apague isto!",
        cancelButtonText: "Não, mantenha",
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        buttonsStyling: false,
      }).then((result) => {
        // Send request to the server
        if (result.value) {
          axios
            .delete("user/address/" + row.id)
            .then(() => {
              if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
              }
              swal({
                title: "Apagado!",
                text: "Seu registro foi apagado.",
                type: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
              this.$Progress.finish();
              this.$notify({
                message: "Dados inseridos com sucesso!",
                type: "success",
                icon: "fa fa-check",
              });
            })
            .catch((error) => {
              if (error) {
                Exception.handle(error);
              }
              swal({
                title: "Erro!",
                text: "Há algo errado.",
                type: "warning",
                confirmButtonClass: "btn btn-danger btn-fill",
                buttonsStyling: false,
              });
              this.$Progress.fail();
            });
        }
      });
    },
  },
  mounted() {
    this.loadAddress();
    this.loadCountry();
    this.loadState();
  },
};
</script>
<style>
</style>
