<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <form autocomplete="off" @submit.prevent="reset">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div slot="header">
                <h3 class="card-title text-center">Trocar Senha</h3>
              </div>
              <div>
                <fg-input label="E-mail"
                          placeholder="E-mail"
                          type="email"
                          v-model="user.email"
                          v-validate="modelValidations.email"
                          :error="getError('email')"
                          >

                </fg-input>
                <span class="text-danger small m-0 p-0" v-if="error.email">{{ error.email[0] }}</span>
              </div>
                 <div>
                <fg-input label="Senha"
                          placeholder="Senha"
                          type="password"
                          v-model="user.password"
                          v-validate="modelValidations.password"
                          :error="getError('password')"
                          >

                </fg-input>
                <span class="text-danger small m-0 p-0" v-if="error.password">{{ error.password[0] }}</span>
              </div>
                 <div>
                <fg-input label="Confirma Senhar"
                          placeholder="Conrimar Senha"
                          type="password"
                          v-model="user.password_confirmation"
                          v-validate="user.password ? 'required|confirmed:password' : ''"
                          :error="getError('password_confirmation')"
                      >

                </fg-input>
                <span class="text-danger small m-0 p-0" v-if="error.password_confirmation">{{ error.password_confirmation[0] }}</span>
              </div>
              <div class="text-center">
                <button v-show="!loading" type="submit" class="btn btn-fill btn-info btn-round btn-wd ">trocar senha</button>
                <button  v-show="loading" class="btn btn-default btn-fill btn-wd btn-round disabled" disabled>
                  <i class="spinner-grow spinner-grow-sm"></i>&nbsp;&nbsp;
                  enviando
                </button>
                <br>
                <div class="forgot">
                  <router-link to="/login" class="card-category">
                    já sou cadastrado!
                  </router-link>
                </div>
              </div>
            </card>
          </fade-render-transition>

        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'
  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout
    },
    data () {
      return {
          loading:false,
          user: {
              email: null,
              password: null,
              password_confirmation: null,
              token: null,
          },
          error: {
        },
        modelValidations: {
          email: {
            required: true,
            email: true
          },
          name: {
            required: true
          },
          password: {
            min: 6
          },
          password_confirmation: {
            confirmed: 'password'
          }
        }
      }
    },
    methods: {
      reset() {
          this.loading=true;
          this.user.token=this.$route.params.token;
            axios.post('/auth/reset', this.user)
               .then(res => User.responseAfterLogin(res))
                .catch((error) => {
                  this.error = error.response.data.errors;
                  this.loading=false;
                })
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open');
      },
      closeMenu () {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.registerForm, isValid)
        })
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
