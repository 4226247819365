import * as types from './mutation-types'
import axios from 'axios'
import * as storage from '@/plugins/storage'

const state = {
  user: storage.getUser() || {},
  access_token: storage.getToken() || '',
}

// getters
const getters = {
  getUser: state => state.user,
  getToken: state => state.access_token,
}

//mutations
const mutations =  {
  [types.SET_USER] (state, payload){
    state.user = payload
  },
  [types.SET_TOKEN] (state, payload){
    state.access_token = payload
  },
}
//actions
const actions = {
  doLogin({dispatch}, payload){
    return axios.post('/auth/login', payload)
      .then(({data}) =>{
        dispatch('authSuccess', data)
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
  },
  authSuccess({ commit }, payload){
    commit(types.SET_USER, payload.user)
    commit(types.SET_TOKEN, payload.access_token)
    storage.setToken(payload.access_token)
    storage.setUser(payload.user)
  },
  signOut({ commit }){
    storage.clearStorage()
    commit(types.SET_USER,'')
    commit(types.SET_TOKEN,'')
  },
  checkToken({ commit, dispatch, state }){
    if (state.access_token) {
      return Promise.resolve(state.access_token)
    }
    const token = storage.getToken()
    if (!token) {
      return Promise.reject(new Error('Token Inválido'))
    }
    commit(types.SET_TOKEN, token)
    return dispatch('loadSession')
  },
  loadSession({ commit, dispatch }){
    return axios.post('/auth/me')
      .then(({data}) =>{
        commit(types.SET_USER, data.data.user)
        return Promise.resolve(data)
      }).catch(error =>{
        dispatch('signOut')
        return Promise.reject(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
