import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
// Dashboard pages
import UserDashboard from 'src/pages/Dashboard/Dashboard/User.vue';

import Order from 'src/pages/Dashboard/Pages/Order/Index';

// Pages
import UserProfile from 'src/pages/Dashboard/Pages/UserProfile.vue';
// User Car
import NewCart from 'src/pages/Dashboard/Pages/User/Cart/NewCart';
import SendCart from 'src/pages/Dashboard/Pages/User/Cart/Send';
//Box
import Box from 'src/pages/Dashboard/Pages/Box/Box.vue';
import ViewBox from 'src/pages/Dashboard/Pages/Box/ViewBox.vue';
//Product
import Product from 'src/pages/Dashboard/Pages/Product/Product.vue';
import Store from 'src/pages/Dashboard/Pages/Product/Store.vue';

//Cart
import CartNewSend from 'src/pages/Dashboard/Pages/Cart/New';
import CartSenders from 'src/pages/Dashboard/Pages/Cart/Senders';

//Settings
import Settings from 'src/pages/Dashboard/Pages/Settings';
import ShippingOption from 'src/pages/Dashboard/Pages/Settings/Shipping/ShippingOption';
import Shipping from 'src/pages/Dashboard/Pages/Settings/Shipping/Shipping';
import PaymentOption from 'src/pages/Dashboard/Pages/Settings/PaymentOption';
import ExtraService from 'src/pages/Dashboard/Pages/Settings/ExtraService';
import BoxMessage from 'src/pages/Dashboard/Pages/Settings/BoxMessage';
import PaypalRate from 'src/pages/Dashboard/Pages/Settings/PaypalRate';
import WhereBuyCategory from 'src/pages/Dashboard/Pages/Settings/WhereBuy/Category';
import WhereBuyStore from 'src/pages/Dashboard/Pages/Settings/WhereBuy/Store';


//User
import ListUsers from 'src/pages/Dashboard/Pages/User/List.vue';
import ListUsersWithShipments from 'src/pages/Dashboard/Pages/User/ListWithShipments.vue';
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue';

import Login from 'src/pages/Dashboard/Pages/Login.vue';
import Logout from 'src/pages/Dashboard/Pages/Logout.vue';
import Register from 'src/pages/Dashboard/Pages/Register.vue';
import Lock from 'src/pages/Dashboard/Pages/Lock.vue';
import Forgot from 'src/pages/Dashboard/Pages/Forgot.vue';
import Reset from 'src/pages/Dashboard/Pages/Reset.vue';

//User -> Affiliate
import AffiliateIndicated from 'src/pages/Dashboard/Pages/User/Affiliate/Indicated.vue';
//User -> WhereBuy
import WhereBuy from 'src/pages/Dashboard/Pages/User/WhereBuy/WhereBuy';
//User -> WhereBuy
import Calculator from 'src/pages/Dashboard/Pages/User/Calculator/Index';

// Components pages
import Buttons from 'src/pages/Dashboard/Components/Buttons.vue';
import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue';
import Panels from 'src/pages/Dashboard/Components/Panels.vue';
import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
import Icons from 'src/pages/Dashboard/Components/Icons.vue'
import Typography from 'src/pages/Dashboard/Components/Typography.vue'

const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue');

// Forms pages
const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue');
const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue');
const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue');
const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue');
const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue');
const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue');
const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue');
const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue');


let componentsMenu = {
    path: '/components',
    component: DashboardLayout,
    redirect: '/components/buttons',
    children: [
        {
            path: 'buttons',
            name: 'Buttons',
            component: Buttons
        },
        {
            path: 'grid-system',
            name: 'Grid System',
            component: GridSystem
        },
        {
            path: 'panels',
            name: 'Panels',
            component: Panels
        },
        {
            path: 'sweet-alert',
            name: 'Sweet Alert',
            component: SweetAlert
        },
        {
            path: 'notifications',
            name: 'Notifications',
            component: Notifications
        },
        {
            path: 'icons',
            name: 'Icons',
            component: Icons
        },
        {
            path: 'typography',
            name: 'Typography',
            component: Typography
        }

    ]
};
let formsMenu = {
    path: '/forms',
    component: DashboardLayout,
    redirect: '/forms/regular',
    children: [
        {
            path: 'regular',
            name: 'Regular Forms',
            component: RegularForms
        },
        {
            path: 'extended',
            name: 'Extended Forms',
            component: ExtendedForms
        },
        {
            path: 'validation',
            name: 'Validation Forms',
            component: ValidationForms
        },
        {
            path: 'wizard',
            name: 'Wizard',
            component: Wizard
        }
    ]
};

let tablesMenu = {
    path: '/table-list',
    component: DashboardLayout,
    redirect: '/table-list/regular',
    children: [
        {
            path: 'regular',
            name: 'Regular Tables',
            component: RegularTables
        },
        {
            path: 'extended',
            name: 'Extended Tables',
            component: ExtendedTables
        },
        {
            path: 'paginated',
            name: 'Paginated Tables',
            component: PaginatedTables
        }]
};

let mapsMenu = {
    path: '/maps',
    component: DashboardLayout,
    redirect: '/maps/google',
    children: [
        {
            path: 'google',
            name: 'Google Maps',
            component: GoogleMaps
        },
        {
            path: 'full-screen',
            name: 'Full Screen Map',
            component: FullScreenMap
        },
        {
            path: 'vector-map',
            name: 'Vector Map',
            component: VectorMaps
        }
    ]
};

let pagesMenu = {
    path: '/pages',
    component: DashboardLayout,
    redirect: '/pages/user',
    children: [
        {
            path: 'timeline',
            name: 'Timeline Page',
            component: TimeLine
        }
    ]
};

let loginPage = {
    path: '/login',
    name: 'Login',
    component: Login,
};

let userprofilePage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/userprofile',
    children: [
        {
            path: 'userprofile',
            name: 'Perfil de Usuário',
            component: UserProfile
        }
    ]
};
let sendCartPage = {
    path: '/user',
    component: DashboardLayout,
    redirect: '/user/cart/send',
    children: [
        {
            path: '/user/cart/send',
            name: 'Envios',
            component: SendCart
        }
    ]
};

let boxPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/box',
    children: [
        {
            path: 'box',
            name: 'Cadastrar Caixa/Produto',
            component: Box,
        }
    ]
};

let viewboxPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/viewbox',
    children: [
        {
            path: 'viewbox',
            name: 'Lista de Caixas',
            component: ViewBox
        }
    ]
};

let productPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/product',
    children: [
        {
            path: 'product',
            name: 'Lista de Produtos',
            component: Product
        },
        {
            path: 'store',
            name: 'Armazenar',
            component: Store
        }
    ]
};

let viewuserPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/users',
    children: [
        {
            path: '/admin/users/list-users',
            name: 'Lista de Usuários',
            component: ListUsers
        },
        {
            path: '/admin/users/list-users-with-shipments',
            name: 'Lista de Usuários com envios',
            component: ListUsersWithShipments
        }
    ]
};


let AffiliateIndicatedPage = {
    path: '/user',
    component: DashboardLayout,
    redirect: '/user/affiliate/indicated',
    children: [
        {
            path: '/user/affiliate/indicated',
            name: 'Meus Indicados',
            component: AffiliateIndicated
        }
    ]
};

let WhereBuyPage = {
    path: '/user',
    component: DashboardLayout,
    redirect: '/user/whereBuy',
    children: [
        {
            path: '/user/wherebuy',
            name: 'Onde Comprar',
            component: WhereBuy
        }
    ]
};

let CalculatorPage = {
    path: '/user',
    component: DashboardLayout,
    redirect: '/user/calculator',
    children: [
        {
            path: '/user/calculator',
            name: 'Calculadora de Frete',
            component: Calculator
        }
    ]
};


let settingsPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/settings',
    children: [
        {
            path: 'settings',
            name: 'Definições',
            component: Settings
        }
    ]
};
let ShippingOptionPage = {
    path: '/admin/shipping',
    component: DashboardLayout,
    redirect: '/admin/shipping/shippingoption',
    children: [
        {
            path: 'shippingoption',
            name: 'Opções de Envio',
            component: ShippingOption
        }
    ]
};
let ShippingPage = {
    path: '/admin/shipping',
    component: DashboardLayout,
    redirect: '/admin/shipping/shipping',
    children: [
        {
            path: 'shipping',
            name: 'Frete',
            component: Shipping
        }
    ]
};
let PaymentOptionPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/paymentoption',
    children: [
        {
            path: 'paymentoption',
            name: 'Opções de Pagamento',
            component: PaymentOption
        }
    ]
};
let ExtraServicePage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/extraservice',
    children: [
        {
            path: 'extraservice',
            name: 'Serviços extras',
            component: ExtraService
        }
    ]
};
let BoxMessagePage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/boxmessage',
    children: [
        {
            path: 'boxmessage',
            name: 'Mensagens',
            component: BoxMessage
        }
    ]
};
let PaypalRatePage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/paypalrate',
    children: [
        {
            path: 'paypalrate',
            name: 'Taxas do Paypal',
            component: PaypalRate
        }
    ]
};

let WhereBuyCategoryPage = {
    path: '/admin/wherebuy/category',
    component: DashboardLayout,
    redirect: '/admin/wherebuy/category',
    children: [
        {
            path: '/admin/wherebuy/category',
            name: 'Categorias',
            component: WhereBuyCategory
        }
    ]
};
let WhereBuyStorePage = {
    path: '/admin/wherebuy/store',
    component: DashboardLayout,
    redirect: '/admin/wherebuy/store',
    children: [
        {
            path: '/admin/wherebuy/store',
            name: 'Lojas',
            component: WhereBuyStore
        }
    ]
};

let NewCartPage = {
    path: '/user',
    component: DashboardLayout,
    redirect: '/user/cart/newcart',
    children: [
        {
            path: '/user/cart/newcart',
            name: 'Novo Carrinho',
            component: NewCart
        }
    ]
};


let CartNewSendPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/cart/new',
    children: [
        {
            path: '/admin/cart/new',
            name: 'Novos Envios',
            component: CartNewSend,
        },
        {
            path: '/admin/cart/new/:status',
            name: 'Novos Envios',
            component: CartNewSend,
        }
    ]
};

let CartSendersPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/cart/senders',
    children: [
        {
            path: '/admin/cart/senders',
            name: 'Enviados',
            component: CartSenders,
        }
    ]
};


let logoutPage = {
    path: '/logout',
    name: 'Logout',
    component: Logout
};

let registerPage = {
    path: '/register',
    name: 'Resgistrar',
    component: Register,
    meta: {
        rule: 'isPublic'
    }
};

let registerPageAffiliate = {
    path: '/register/:affiliate',
    name: 'Resgistrar Afiliado',
    component: Register,
    meta: {
        rule: 'isPublic'
    }
};

let forgotPage = {
    path: '/forgot',
    name: 'Recuperar Senha',
    component: Forgot,
    meta: {
        rule: 'isPublic'
    }
};

let resetPage = {
    path: '/reset/:token',
    name: 'Resetar Senha',
    component: Reset,
    meta: {
        rule: 'isPublic'
    }
};

let lockPage = {
    path: '/lock',
    name: 'Lock',
    component: Lock
};

let orderPage = {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/ordeer',
    children: [
        {
            path: 'order',
            name: 'Solicitação de Envio',
            component: Order,
        }
    ]
};

const index = [
    {
        path: '/',
        redirect: '/admin/overview'
    },
    componentsMenu,
    formsMenu,
    tablesMenu,
    mapsMenu,
    pagesMenu,
    loginPage,
    logoutPage,
    registerPage,
    registerPageAffiliate,
    forgotPage,
    resetPage,
    lockPage,
    userprofilePage,
    settingsPage,
    boxPage,
    viewboxPage,
    viewuserPage,
    productPage,
    ShippingOptionPage,
    ShippingPage,
    PaymentOptionPage,
    ExtraServicePage,
    BoxMessagePage,
    PaypalRatePage,
    WhereBuyCategoryPage,
    WhereBuyStorePage,
    NewCartPage,
    CartNewSendPage,
    sendCartPage,
    CartSendersPage,
    AffiliateIndicatedPage,
    WhereBuyPage,
    CalculatorPage,
    orderPage,
    {
        path: '/admin',
        component: DashboardLayout,
        redirect: '/admin/overview',
        children: [
            {
                path: 'overview',
                name: 'Painel de Controle',
                component: UserDashboard
            },
        ]
    },
    {path: '*', component: NotFound},
    {path: '/page/404', component: NotFound}
];
export default index
