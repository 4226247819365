<template>
  <div>
    <skeleton-order-product v-if="loading"></skeleton-order-product>

    <div v-if="order_send.products.length && !loading">
      <div class="row">
        <div class="col-md-2">
          <div v-if="!is_selected_all">
            <l-button block type="primary" size="md" @click.prevent="selectAll()">
              &nbsp;marcar todos
            </l-button>
          </div>
          <div v-if="is_selected_all">
            <l-button block type="danger" size="md" @click.prevent="selectAll()">
              &nbsp;desmarcar todos
            </l-button>
          </div>
        </div>


        <div class="col-md-2">
          <l-button block type="info" size="md">
            &nbsp;1 - adicionar ao envio
          </l-button>
        </div>

        <div class="col-md-2">
          <l-button block type="secondary" size="md" disabled>
            &nbsp;2 - endereço de entrega
          </l-button>
        </div>

        <div class="col-md-2">
          <l-button block type="secondary" size="md" disabled>
            &nbsp;3 - declaração alfandegária
          </l-button>
        </div>

        <div class="col-md-2">
          <l-button block type="secondary" size="md" disabled>
            &nbsp;4 - serviços extras
          </l-button>
        </div>

        <div class="col-md-2">
          <l-button block type="secondary" size="md" disabled>
            &nbsp;5 - resumo
          </l-button>
        </div>
      </div>
      <form>
        <div class="row">
          <div
            class="col-md-4 p-0"
            v-for="(myProduct, indexMyproduct) in my_products"
            :key="indexMyproduct"
          >
            <div
              class="card cardhover m-2"
              style="max-width: 540px;"
              :class="{ 'border-success': order_send.products[myProduct.id].is_selected }"
            >
              <div class="row no-gutters">
                <div class="col-md-5 p-0">
                  <img
                    :src="productImage(myProduct)"
                    alt="image"
                    class="card-img-top"
                  >
                </div>

                <div class="col-md-7">
                  <div class="card-body">
                    <ul class="list-group list-group-flush">
                      <li class="list-inline-item">Caixa: {{ myProduct.box_id }}</li>
                      <li class="list-inline-item">Suite: {{ myProduct.user_suite }}</li>
                      <li class="list-inline-item">Código: {{ myProduct.id }}</li>
                      <li class="list-inline-item">Peso: {{ myProduct.weight }}</li>
                      <li class="list-inline-item">Qnt.: {{ myProduct.amount }}</li>
                      <li class="list-inline-item">Ref.: {{ myProduct.reference_code || '-' }}</li>
                    </ul>
                  </div>
                  <div class="row p-2 d-flex justify-content-between">
                    <div class="col-5">
                      <input
                        type="number"
                        v-model="order_send.products[myProduct.id].amount"
                        class="form-control form-control-sm"
                        :min="1"
                        :max="myProduct.amount"
                        @change="checkAmount(myProduct)"
                      />

                    </div>

                    <div class="col-7">
                      <div v-if="!order_send.products[myProduct.id].is_selected">
                        <l-button
                          block
                          type="success"
                          size="sm"
                          @click.prevent="setSelect(myProduct)"
                        >
                    <span class="btn-label">
                      <i class="fa fa-cart-plus pr-4"></i>
                    </span>
                          adicionar ao envio
                        </l-button>
                      </div>

                      <div v-if="order_send.products[myProduct.id].is_selected">
                        <l-button
                          block
                          type="danger"
                          size="sm"
                          @click.prevent="setSelect(myProduct)"
                        >
                    <span class="btn-label">
                      <i class="fa fa-times pr-4"></i>
                    </span>
                          remover ao envio
                        </l-button>
                      </div>
                    </div>

                  </div>
                  <div class="card-footer bg-transparent border-success">
                    <small class="text-muted">Criado em {{ myProduct.created_at }}</small>&nbsp;
                    <small class="text-muted">a {{ myProduct.days_in_stock }} dias em estoque.</small>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>
<script>
import {mapGetters} from 'vuex'
import SkeletonOrderProduct from "@/components/SkeletonOrderProduct";

export default {
  components: {SkeletonOrderProduct},
  data () {
    return {
      order_send: {
        products:[]
      },
      is_selected_all: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      'my_products': 'orderMyProduct/fetch',
      'order': 'order/fetch',
    }),
  },
  watch: {
    'order_send': {
    },
  },
  methods: {
    fetch () {
      this.$store.dispatch('orderMyProduct/fetchItems').then((response) =>{
        let products = this.my_products

        for (let count = 0; count < products.length; count++) {
          this.order_send.products[products[count].id] = {
            amount: 1,
            is_selected: false,
          }
        }
        this.loading=false
        this.$forceUpdate()
      }).catch( error => {})

      // this.$store.dispatch('order/fetchOrder').then( response =>{}).catch( error => {})
    },
    setSelect(row){
      this.order_send.products[row.id].is_selected = !this.order_send.products[row.id].is_selected
      if(this.is_selected_all){
        this.is_selected_all = !this.is_selected_all
      }
      this.$forceUpdate()
    },
    selectAll(){
      let products = this.my_products
      for (let count = 0; count < products.length; count++) {
        this.order_send.products[products[count].id].is_selected = !this.order_send.products[products[count].id].is_selected
      }
      this.is_selected_all = !this.is_selected_all
      this.$forceUpdate()
    },
    checkAmount (row) {
      if(this.order_send.products[row.id].amount > row.amount){
        this.order_send.products[row.id].amount = row.amount
        this.$forceUpdate()
      }
    },
    productImage (row) {
      return baseURLIMGUSER+'/'+ row.user_id +'/box/product/thumbnail_'+ row.image;
    },
  },
  filters: {
    jogos_br: function (value) {
      if (!value) return ''
      return value.filter(item => item.campeonato_pais_cc === 'br')
    },
  },
  mounted () {
    this.fetch()
  },
}
</script>
<style>
.form-control-sm {
  height: 30px !important;
}
</style>
