<template>
  <nav class="navbar navbar-expand-lg ">

    <div class="container-fluid">
      <div class="navbar-minimize">
        <button class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-bars'"></i>
        </button>
      </div>
      <a class="navbar-brand">{{this.$route.name}}</a>
      <button type="button"
              class="navbar-toggler navbar-toggler-right mb-5"
              :class="{toggled: $sidebar.showSidebar}"
              aria-expanded="false"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">

          <drop-down position="right">
            <i slot="title" class="nc-icon nc-cart-simple">&nbsp;{{ totalProducts }}</i>

            <router-link to="/user/cart/newcart"  class="dropdown-item text-primary">
              <i class="nc-icon nc-delivery-fast"></i>&nbsp;&nbsp;Finalizar
            </router-link>

              <button @click="removeAllProductToCart" class="dropdown-item text-danger cursor">
              <i class="nc-icon nc-simple-remove"></i>&nbsp;&nbsp;Esvaziar
            </button>

          </drop-down>

          <drop-down position="right">
            <i slot="title" class="fa fa-cog"></i>
            <router-link to="/logout"  class="dropdown-item text-danger">
              <i class="nc-icon nc-button-power"></i> Sair
            </router-link>
          </drop-down>

        </ul>
      </div>

    </div>
  </nav>
</template>
<script>
  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    data () {
      return {
        activeNotifications: false,
        cartQnt: User.userProfile().id,
          totalProducts: 0,
      }
    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      },
      removeAllProductToCart() {
        localStorage.removeItem('cart_'+User.userProfile().id);
        this.countCart(0);
      },
      countCart(count){
        EventBus.$emit('count-cart',count);
      }
    },
    created() {
      EventBus.$on('logout', () => {
        User.logout()
      });
      EventBus.$on('count-cart', (count) => {
          this.totalProducts=count;
      });
    },
      mounted() {
          this.totalProducts= (localStorage['cart_'+User.userProfile().id]) ? JSON.parse(localStorage['cart_'+User.userProfile().id]).length : 0;
      }
  }

</script>
<style>
.cursor{
  cursor: pointer;
}
</style>
