<template>
      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Declaração Alfandegaria.</h5>
        </div>
        <div class="row pt-2" v-for="(declaration, index) in declarations">
            <div class="col-md-4">
              <fg-input label="Descrição" type="text"  placeholder="Descrição" name="item" v-model="declaration.item"></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input label="Quant" type="text" name="qnt" placeholder="Quant" v-model="declaration.amount"
                        v-mask="['#','##']"
              ></fg-input>
            </div>
            <div class="col-md-3">
              <fg-input label="valor" type="text" name="valor" placeholder="Valor" v-model="declaration.price"
                        v-on:change="changeItem"
                        v-mask="['#.##','##.##','###.##']"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input label="Sub total" type="text" name="sub_total" placeholder="Sub total"
                        v-model="declaration.sub_total=rowsubTotalDeclarations(declaration)"
              ></fg-input>
            </div>
          <div class="col-12 col-md-12 col-sm-12 border-bottom m-0 p-0"></div>
        </div>

        <div class="row p-3">
          <div class="col-12 bg-dark text-white p-2 text-right">Valor declarado final: {{ subTotalDeclarations }}</div>
        </div>

      </card>
</template>

<script>

    import {mask} from 'vue-the-mask'

    export default {
        name: "CartDeclaration",
        directives: {mask},
        data () {
            return {
                declarations: [
                    { item: '',amount: '',price: '',sub_total: '' },
                    { item: '',amount: '',price: '',sub_total: '' },
                    { item: '',amount: '',price: '',sub_total: '' },
                ],
            }
        },
        methods: {
            rowsubTotalDeclarations(item) {
                let subTotal = item.amount * item.price;
                return subTotal.toFixed(2);
            },
            changeItem () {
                EventBus.$emit('declarations', this.declarations.filter(function(e){ return e.amount !== '' && e.item !== '' && e.price !== '' && e.sub_total !== ''; }));
            }
        },
        computed: {
            subTotalDeclarations() {
                return this.declarations.reduce((sub_total, item) => parseFloat(sub_total + item.amount * item.price), 0).toFixed(2)
            },
        }
    }
</script>

<style scoped>

</style>
