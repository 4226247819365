<template>
  <card class="card-user">
    <div slot="image">
      <img src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
           alt="...">
    </div>
    <div>
      <div class="author mb-0">
        <img class="avatar border-gray" :src="profile.picture" :alt="profile.name">
        <h4 class="title m-0">{{ profile.name }}
          <br>
          <a href="#">
            <small>{{ profile.created_at }}</small>
          </a>
        </h4>
      </div>

    </div>

    <div class="text-center mt-3">
      <div>
        <change-picture v-model="file"></change-picture>
      </div>
    </div>
  </card>
</template>
<script>

  import ChangePicture from "./ChangePicture";

  export default {
    components: {ChangePicture},
    data (){
      return {
        profile: {
        },
        file: null
      }
    },
    created() {
      if(User.userProfile().profile_picture =='no-img-profile.png'){
        this.profile.picture = baseURLIMGUSER+'/'+User.userProfile().profile_picture;
      }else{
        this.profile.picture = baseURLIMGUSER+User.userProfile().id+'/'+User.userProfile().profile_picture;
      }
      this.profile.name = User.userProfile().name;
      this.profile.created_at = User.userProfile().created_at;
    }
  }
</script>
<style>

</style>
