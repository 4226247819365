<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-8 col-md-8 col-sm-10">
        <form autocomplete="off" @submit.prevent="sigunp">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div slot="header">
                <h3 class="card-title text-center">Cadastrar</h3>
              </div>

             <div class="row">
               <div class="col-lg-6">
                 <fg-input class="mb-0" label="Primeiro Nome"
                           placeholder="First Name"
                           autocomplete="cc-name"
                           type="text"
                           v-model="form.name">
                 </fg-input>
                 <span class="text-danger small m-0 p-0" v-if="error.name">{{ error.name[0] }}</span>
               </div>

               <div class="col-lg-6">
                 <fg-input class="mb-0" label="Sobrenome completo"
                           placeholder="Last Name"
                           autocomplete="cc-name"
                           type="text"
                           v-model="form.last_name">
                 </fg-input>
                 <span class="text-danger small m-0 p-0" v-if="error.last_name">{{ error.last_name[0] }}</span>
               </div>
             </div>
              <div class="row">
                <div class="col-lg-5">
                  <fg-input class="mb-0" label="Email"
                            placeholder="Email"
                            autocomplete="cc-email"
                            type="email"
                            v-model="form.email">

                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.email">{{ error.email[0] }}</span>
                </div>

                <div class="col-lg-7">
                  <label>Telfone Celular</label>
                  <vue-tel-input class="mb-0" label="Telfone Celular"
                                 placeholder="Telfone Celular"
                                 type="text"
                                 required
                                 autocomplete="cc-tel"
                                 :valid-characters-only="true"
                                 v-model="form.cell_phone"
                  >
                  </vue-tel-input>
                  <span class="text-danger small m-0 p-0" v-if="error.cell_phone">{{ error.cell_phone[0] }}</span>
                </div>


              </div>
              <div class="row">
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="senha"
                            type="password"
                            placeholder="senha"
                            autocomplete="new-password"
                            v-model="form.password">
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.password">{{ error.password[0] }}</span>
                </div>
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="senha"
                            type="password"
                            placeholder="senha"
                            autocomplete="new-password"
                            v-model="form.password_confirmation">
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.password_confirmation">{{ error.password_confirmation[0] }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-12">
                  <fg-input class=""
                            label=""
                  >
                    <checkbox  v-model="form.terms">li e estou de acordo com os <a href="https://eurofastbox.com/termodeuso" target="_blank">Termos de Uso e Contrato.</a></checkbox>
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.terms">{{ error.terms[0] }}</span>
                </div>
              </div>

              <div class="text-center mt-2">
                <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">cadastrar</button>
                <br>
                <div class="forgot">
                  <router-link to="/login" class="card-category">
                    Já sou cadastrado!
                  </router-link>
                </div>
              </div>
            </card>
          </fade-render-transition>

        </form>
      </div>
    </div>
  </auth-layout>

</template>
<script>
    import { Checkbox } from 'element-ui'
    import 'vue-tel-input/dist/vue-tel-input.css';
    import VueTelInput from 'vue-tel-input';
    import { FadeRenderTransition } from 'src/components/index'
    import AuthLayout from './AuthLayout.vue'

  export default {
    data () {
      return {
        form: {
          name: null,
          last_name: null,
          email: null,
          cell_phone: null,
          password: null,
          password_confirmation:null,
          terms: '',
          indicated_by: '',
        },
        error: {
        },
      }
    },

    components: {
        FadeRenderTransition,
        AuthLayout,
        VueTelInput,
        Checkbox,
    },
    methods: {
        sigunp(){
          this.form.indicated_by=this.$route.params.affiliate;
            axios.post('/auth/signup', this.form)
                .then(res => User.responseAfterLogin(res))
                .catch(error => this.error = error.response.data.errors)
        },
        toggleNavbar () {
            document.body.classList.toggle('nav-open')
        },
        closeMenu () {
            document.body.classList.remove('nav-open');
            document.body.classList.remove('off-canvas-sidebar')
        }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
