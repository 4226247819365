<template>
  <div class="row">
    <div
      class="col-md-4 p-0"
      v-for="(item, indexItem) in 3"
      :key="indexItem"
    >
      <div
        class="card cardhover m-2"
        style="max-width: 540px;"
      >
        <div class="row no-gutters">
          <div class="col-md-5 p-0">
            <div class="product-card">
              <img class='product-image__img' />
            </div>
          </div>

          <div class="col-md-7 pl-1">
            <div class="product-info pt-2">
              <span />
              <h3 />
              <p class="m-0"/>
              <h4 />
              <h5 />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>



</template>
<script>
import Skeleton from './Skeleton'

export default {
  name: 'SkeletonOrderProduct',
  components: {
    Skeleton,
  },
  data() {
    return {}
  },
  updated() {
  },
}
</script>
<style>
.product-card .product-image__img {
  width: 100%;
  padding-top: 50%;
  background-color: #ddd;
  animation: pulse-bg 1s infinite;
}

.product-info span {
  width: 30%;
  height: 16px;
  display: block;
  background-color: #ddd;
  animation: pulse-bg 1s infinite;
}

.product-info h3 {
  content: ' ';
  width: 90%;
  height: 16px;
  background-color: #ddd;
  margin: 4px 0;
  animation: pulse-bg 1s infinite;
}

.product-info h4 {
  content: ' ';
  width: 55%;
  height: 16px;
  background-color: #ddd;
  margin: 4px 0;
  animation: pulse-bg 1s infinite;
}

.product-info h5 {
  content: ' ';
  width: 40%;
  height: 16px;
  background-color: #ddd;
  margin: 4px 0;
  animation: pulse-bg 1s infinite;
}

.product-info p {
  width: 70%;
  background-color: #ddd;
  height: 16px;
  line-height: 140%;
  animation: pulse-bg 1s infinite;
}
@keyframes pulse-bg {
  0% { background-color: #ddd; }
  50% { background-color: #d0d0d0; }
  100% { background-color: #ddd; }
}
</style>
