<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <form autocomplete="off" @submit.prevent="forgot">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div slot="header">
                <h3 class="card-title text-center">Recuperar Senha</h3>
              </div>
              <div>
                <fg-input label="Email"
                          placeholder="Email"
                          type="email"
                          name="email"
                          v-model="user.email"
                          v-validate="modelValidations.email"
                          :error="getError('email')"
                          >

                </fg-input>
                <span class="text-danger small m-0 p-0" v-if="error.email">{{ error.email[0] }}</span>
                <span v-show="!message" class="text-success">&nbsp;</span>
                <span v-show="message" class="text-success">{{ message }}</span>

              </div>
              <div class="text-center">
                <button v-show="!loading" type="submit" class="btn btn-fill btn-info btn-round btn-wd ">enviar</button>
                <button  v-show="loading" class="btn btn-default btn-fill btn-wd btn-round disabled" disabled>
                  <i class="spinner-grow spinner-grow-sm"></i>&nbsp;&nbsp;
                  enviando
                </button>
                <br>
                <div class="forgot">
                  <router-link to="/login" class="card-category">
                    já sou cadastrado!
                  </router-link>
                </div>
              </div>
            </card>
          </fade-render-transition>

        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'
  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout
    },
    data () {
      return {
          loading:false,
          message: '',
          user: {
              email: null,
          },
          modelValidations: {
            email: {
              required: true,
              email: true
            }
          },
          error: {
          },
      }
    },
    methods: {
      forgot() {
          this.loading=true;
            axios.post('/auth/forgot', this.user)
                .then((res) => {
                  this.message = res.data.message;
                  this.loading=false;
                })
                .catch((error) => {
                  // this.message = error.response.data.errors.email;
                  this.error = error.response.data.errors;
                  this.loading=false;
                })
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open');
      },
      closeMenu () {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.registerForm, isValid)
        })
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
