<template>
  <div>

    <div class="row">
      <div class="col-lg-6 col-md-6 m-0 p-0">
        <card
          class="card"
          title="Armazenar Produtos"
        >
          <div class="row">
            <div class="col-12">
              Cliente:
              <span v-if="product.user!=null">{{product.user.name}} {{product.user.last_name}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              Suite:
              <span v-if="product!=null">{{product.user_suite}}</span>
            </div>
          </div>

          <div>
            <form>
              <div class="row">
                <div class="col-lg-5">
                  <fg-input class="mb-0" label="Produto"
                            placeholder="Produto"
                            inputmode="numeric"
                            type="text"
                            v-model="store.product_id"
                            ref="product_id"
                            v-on:blur="getProduct()"
                            >
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.errors.product_suite_user">{{ error.errors.product_id[0] }}</span>
                </div>
                <div class="col-lg-7">
                  <fg-input class="mb-0" label="Localização"
                            placeholder="Localização"
                            type="text"
                            ref="localization"
                            v-model="store.localization"
                            masked="true"
                  >
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.errors.localization">{{ error.errors.localization[0] }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-4 col-sm-12">
                  <button
                    type="submit"
                    class="btn btn-primary btn-block btn-wd text-uppercase"
                    @click.prevent="save"
                    v-show="!loading"
                  >
                    <i class="fa fa-save"></i>
                    Armazenar
                  </button>

                  <button
                    v-show="loading"
                    type="submit"
                    class="btn btn-secondary btn-block btn-wd text-uppercase mt-0 disabled"
                  >
                    <i class="fa fa-spinner"></i>
                    Aguarde
                  </button>
                </div>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask'
import async from "async";

  export default {
    directives: {mask},
    data () {
      return {
        loading: false,
        store: {
          product_id:'',
          localization: '',
        },
        product: {},
        error: {
          errors:{}
        },
      }
    },
    methods: {
      getProduct(){
        this.loading = true
          this.$Progress.start()
          axios.get(`/products/${this.store.product_id}`).then(({data}) => {
            this.product = data
            this.store.localization = this.product.localization
            this.loading = false
            this.$Progress.finish();
          }).catch((error)=>{
            if(error){
              Exception.handle(error);
            }
            this.loading = false
            this.$Progress.fail();
          })
        },
      save() {
        this.loading = true;
        this.$Progress.start();
        axios.patch(`/products/${this.store.product_id}/localization`, this.store).then((res) => {
          this.store =  {
            product_id:'',
              localization: '',
          }
          this.product = {}
          this.loading = false;
          this.$Progress.finish();
        }).catch((err) => {
          if (err) {
            this.error.errors = err.response.data.errors;
          }
          this.loading = false;
          this.$Progress.fail();
        });
      },
      focusInput() {
        this.$refs.product_id.$el.focus();
      }
    },
    mounted(){
      this.focusInput();
    }
  }

</script>
<style scoped>
</style>
