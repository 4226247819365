<template>
  <card class="card" title="Atualizar senha" subTitle="troque sua senha.">
    <div>
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="password"
                      label="Senha"
                      ref="password"
                      name="password"
                      placeholder="senha"
                      v-model="user.password"
                      v-validate="modelValidations.password"
                      :error="getError('password')"
            >
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <fg-input type="password"
                      label="Confirme Senha"
                      name="password_confirmation"
                      placeholder="Confirme Senha"
                      v-model="user.password_confirmation"
                      v-validate="user.password ? 'required|confirmed:password' : ''"
                      :error="getError('password_confirmation')"
            >
            </fg-input>
          </div>
        </div>

        <div class="text-left">
          <button  v-show="!loading" type="submit" class="btn btn-success btn-fill btn-wd"  @click.prevent="validate">
            <i class="fa fa-save"></i>
            atualizar
          </button>

          <button  v-show="loading" class="btn btn-default btn-fill btn-wd disabled" disabled>
            <i class="spinner-grow spinner-grow-sm"></i>&nbsp;&nbsp;
            aguarde
          </button>

        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>

</template>
<script>

  export default {
    data () {
      return {
        loading: false,
        user: {},
        modelValidations: {
          password: {
            required: true,
            min: 6
          },
          password_confirmation: {
            confirmed: 'password'
          }
        }
      }
    },
    methods: {

      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      updateProfile () {
        this.loading = true;
        this.$Progress.start();
        this.$Progress.start();
        this.$notify(
          {
            message: 'Aguarde estamos trabalhando em sua solicitação!',
            type: 'info',
            icon: 'fa fa-check',
            horizontalAlign: 'left',
            verticalAlign: 'bottom',
          });
        axios.put('user/'+User.userProfile().id,this.user).then((res)=>{
          User.clearSetUser(res.data.data[0]);
          this.$notify(
            {
              message: 'Dados atualizados com sucesso!',
              type: 'success',
              icon: 'fa fa-check'
            });
          this.user = '';
          this.loading = false;
          this.$Progress.finish();
        }).catch((error)=>{
          if(error){
            Exception.handle(error);
          }
          swal({
            title:"Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false
          });
          this.loading = false;
          this.$Progress.fail();
        });
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          if(isValid){
            this.updateProfile();
          }
        })
      },
    },
  }

</script>
<style>

</style>
