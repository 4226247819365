<template>
  <div>
    <card class="card" title="Calculadora de envio" subTitle="Escolha um País e um peso e clique em calcular!">
    <div role="alert" class="alert alert-danger"><span data-notify="message"><span><b>Atenção!</b> Os Preços Apresentados São Apenas Para Envios De Portugal Para Outros Países.
Para Saber Preços De Envios Do Brasil Para Outros Países Por Favor Pedir Orçamento Ao Suporte..</span></span></div>
      <div class="row">
          <div class="col-sm-12 col-md-6">
            <el-select
              class="select-default d-block"
              size="large"
              name="country"
              filterable
              placeholder="Selecione um País"
              v-model="send.country"
            >
              <el-option
                v-for="option in selects.countries"
                class="select-default"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-12 col-md-3">
            <label class="p-0 m-0">Peso {{send.weight}}&nbsp;Kg</label>
            <el-slider
              v-model="send.weight"
              :step="0.500"
              :show-tooltip="false"
              :min="0"
              :max="30"
            >
            </el-slider>
          </div>
          <div class="col-sm-12 col-md-3">

            <button class="btn btn-info btn-fill btn-wd mt-1" @click.prevent="fetchShippingFee"
                    :disabled="disabled"
            >
              <i class="fa fa-search" v-show="!loading"></i>
              <i class="fa fa-spinner" v-show="loading"></i>
              calcular
            </button>
          </div>
      </div>
    </card>


    <div class="row" v-if="shippings" v-show="!loading">
      <div class="col-lg-6 col-md-6" v-for="(shipping, index) in shippings" :key="index">
        <card class="card" :title="shipping.shipping_option.name">
          <div class="bg-secondary">
            <h4 class="text-center text-white p-2 m-0">
              <strong>€&nbsp;{{shipping.price}}</strong>
            </h4>
          </div>
          <div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Tempo estimado de entrega: {{shipping.shipping_option.delivery_time}}</li>
              <li class="list-group-item">Peso Máximo: {{shipping.shipping_option.maximum_weight}}</li>
              <li class="list-group-item">Peso dimensional: {{shipping.shipping_option.dimensional_weight}}</li>
              <li class="list-group-item">Tamanho máximo (comprimento + largura + altarua):  {{shipping.shipping_option.maximum_size}}</li>
              <li class="list-group-item">Comprimento máximo: {{shipping.shipping_option.maximum_lenght}}</li>
              <li class="list-group-item">Frequencia de partida: {{shipping.shipping_option.view_starting_frequency}}</li>
              <li class="list-group-item">{{shipping.shipping_option.view_secure}}</li>
            </ul>

          </div>
        </card>
      </div>
    </div>

  </div>
</template>
<script>
import {StatsCard} from 'src/components/index'
import { Slider, Dialog, Select, Option } from "element-ui";

export default {
  components: {
    StatsCard,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Slider.name]: Slider,
  },
  data() {
    return {
      loading: false,
      selects: {
        countries: [],
      },
      shippings: {},
      send: {
        country: null,
        weight: null,
      },
      disabled: true,
    }
  },
  watch:{
    'send.country': function () {
      if(this.send.country){
        this.disabled = false
      }
    }
  },
  methods: {
    loadCountry() {
      this.$Progress.start();
      axios
        .get("country")
        .then(({ data }) => (this.selects.countries = data.data))
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.$Progress.fail();
        });
      this.$Progress.finish();
    },
    fetchShippingFee() {
        this.$Progress.start();
        this.loading = true
        axios
          .get(`shippingfee?country=${this.send.country}&weight=${this.send.weight}`)
          .then(({ data }) => {
            this.shippings = data.data
            this.loading = false
            this.$Progress.finish();
          })
          .catch((error) => {
            if (error) {
              Exception.handle(error);
            }
            swal({
              title: "Erro!",
              text: "Há algo errado.",
              type: "warning",
              confirmButtonClass: "btn btn-danger btn-fill",
              buttonsStyling: false,
            });
            this.$Progress.fail();
          });
    },
  },
  computed: {},
  mounted() {
    this.loadCountry();
  },
}

</script>
<style>

</style>
