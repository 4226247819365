<template>
  <div class="">
    <div class="">
      <l-button wide type="success" size="sm" @click="createAddress">
        <span class="btn-label">
          <i class="fa fa-check"></i>
        </span>
        &nbsp;&nbsp;Adicionar
      </l-button>
    </div>

    <el-dialog title="Adicionar novo endereço" :visible.sync="modals.basic">
      <div>
        <form>
          <div class="row">
            <div class="col-md-12">
              <fg-input
                type="text"
                label="Nome do endereço"
                name="name_address"
                v-model="useraddress.name_address"
                v-validate="modelValidations.name_address"
                :error="getError('address')"
              >
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <fg-input
                type="text"
                label="Nome do destinatário"
                name="recipient_name"
                v-model="useraddress.recipient_name"
                v-validate="modelValidations.recipient_name"
                :error="getError('recipient_name')"
              >
              </fg-input>
            </div>

            <div class="col-md-4">
              <label>Telefone Celular</label>
              <vue-tel-input
                class="mb-0"
                label="Telefone Celular"
                placeholder="Telefone Celular"
                type="text"
                required
                autocomplete="cc-tel"
                :valid-characters-only="true"
                v-model="useraddress.recipient_phone"
                v-validate="modelValidations.recipient_phone"
                :error="getError('recipient_phone')"
              >
              </vue-tel-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <fg-input
                type="text"
                label="Endereço Completo"
                placeholder="Ex: Avenida, Rua, Bairro, Número"
                name="address"
                v-model="useraddress.address"
                v-validate="modelValidations.address"
                :error="getError('address')"
              >
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input
                type="text"
                label="Complemento Endereço"
                placeholder="Ex: Apartamento, Bloco, Andar, Prédio."
                name="complement"
                v-model="useraddress.complement"
              >
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group has-label">
                <label>País</label>
                <el-select
                  class="select-default"
                  size="large"
                  name="country"
                  filterable
                  placeholder="Selecione um País"
                  v-model="useraddress.country"
                  @change="loadState(useraddress.country)"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group has-label">
                <label>Estado / Província / Região</label>
                <el-select
                  class="select-default"
                  size="large"
                  name="country"
                  filterable
                  placeholder="Selecione um estado"
                  v-model="useraddress.state"
                >
                  <el-option
                    v-for="option in selects.states"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5">
              <fg-input
                type="text"
                label="Cidade / Localidade"
                name="city"
                v-model="useraddress.city"
                v-validate="modelValidations.city"
                :error="getError('city')"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input
                type="text"
                label="Código Postal / Cep"
                name="post_code"
                v-model="useraddress.post_code"
                v-validate="modelValidations.post_code"
                :error="getError('post_code')"
              >
              </fg-input>
            </div>
          </div>
        </form>
      </div>

      <span v-show="!loading" slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('basic')">
          cancelar</button
        >&nbsp;
        <button class="btn btn-success" @click.prevent="validate">
          salvar
        </button>
      </span>

      <span v-show="loading" slot="footer" class="dialog-footer">
        <button class="btn btn-default" disabled>cancelar</button>&nbsp;
        <button class="btn btn-default" disabled>salvar</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog, Select, Option } from "element-ui";
import "vue-tel-input/dist/vue-tel-input.css";
import VueTelInput from "vue-tel-input";

export default {
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    VueTelInput,
  },
  data() {
    return {
      loading: false,
      selects: {
        countries: [],
        states: [],
      },
      modals: {
        basic: false,
        centered: false,
      },
      useraddress: {},
      modelValidations: {
        name_address: {
          required: true,
        },
        recipient_name: {
          required: true,
        },
        recipient_phone: {
          required: true,
        },
        address: {
          required: true,
        },
        city: {
          required: true,
        },
        state: {
          required: true,
        },
        country: {
          required: true,
        },
        post_code: {
          required: true,
        },
      },
    };
  },
  methods: {
    createAddress() {
      this.modals["basic"] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    addAddress() {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .post("user/address", this.useraddress)
        .then(() => {
          this.$notify({
            message: "Dados inseridos com sucesso!",
            type: "success",
            icon: "fa fa-check",
          });
          this.closeModal("basic");

          EventBus.$emit("loadUserAddress");

          this.useraddress = "";
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.closeModal("basic");
          this.loading = false;
          this.$Progress.fail();
        });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.addAddress();
        }
      });
    },
    loadCountry() {
      this.$Progress.start();
      axios
        .get("country")
        .then(({ data }) => (this.selects.countries = data.data))
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.$Progress.fail();
        });
      this.$Progress.finish();
    },
    loadState() {
      this.$Progress.start();
      let country = this.useraddress.country;
      if (!country) {
        country = "";
      }
      axios
        .get("state?country=" + country)
        .then(({ data }) => (this.selects.states = data.data))
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.$Progress.fail();
        });
      this.$Progress.finish();
    },
  },
  mounted() {
    this.loadCountry();
    this.loadState();
  },
};
</script>
<style>
</style>
