<template>
  <div>
    <div class="row mb-2">
      <div class="col-lg-12 pr-0">
        <div class="col-lg-2 offset-lg-10 pr-0">
          <router-link to="/admin/viewbox">
            <l-button wide type="primary" size="md">
                  <span class="btn-label">
                      <i class="fa fa-box"></i>
                  </span>
              &nbsp;&nbsp;Ver Caixas
            </l-button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 m-0 p-0">
        <card
          class="card"
          :class="is_open_box? 'border border-danger' : ''"
          title="Adicionar Produtos"
          subTitle="Cadastro de produtos da caixa."
        >
          <div>
            <div class="row">
              <div class="col-12">
                Cliente: <strong v-if="user.name">{{user.name}} {{user.last_name}}</strong>
                <strong v-if="!user.name">---</strong>
              </div>
            </div>
            <form>
              <div class="row">
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="Suite"
                            placeholder="Suite"
                            inputmode="numeric"
                            type="text"
                            v-model="product.product_suite_user"
                            v-mask="['#####']"
                            v-on:blur="getIsOpenBox()"
                            ref="product_suite_user"
                            >
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.errors.product_suite_user">{{ error.errors.product_suite_user[0] }}</span>
                </div>
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="Código de Referência"
                            placeholder="Código de Referencia"
                            type="text"
                            v-model="product.reference_code"
                  >
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.errors.reference_code">{{ error.errors.reference_code[0] }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="Peso"
                            placeholder="Peso"
                            type="text"
                            ref="product_weight"
                            v-model="product.product_weight"
                            v-mask="['#.##','#.###', '##.###']"
                            masked="true"
                            inputmode="numeric"
                            >
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.errors.product_weight">{{ error.errors.product_weight[0] }}</span>
                </div>
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="Quantidade"
                            placeholder="Quantidade"
                            type="text"
                            v-model="product.product_amount"
                            v-mask="['###']"
                            masked="true"
                            inputmode="numeric"
                            >
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.errors.reference_code">{{ error.errors.reference_code[0] }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="Localização"
                            placeholder="Localização"
                            type="text"
                            ref="localization"
                            v-model="product.localization"
                            masked="true"
                            >
                  </fg-input>
                  <span class="text-danger small m-0 p-0" v-if="error.errors.localization">{{ error.errors.localization[0] }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="img-fluid mt-2 mb-2" v-if="product.product_image.name">
                    <img :src="product.product_image_url_one" class="figure-caption" width="30%">
                  </div>

                  <label class="file-select">
                    <div class="btn btn-primary btn-block">
                      <span v-if="product.product_image">{{product.product_image.name}}</span>
                      <span v-else>Foto do Produto</span>
                    </div>
                    <input class="mb-0" label="Foto do Produto"
                           placeholder="Foto do Produto"
                           type="file"
                           id="product_image" ref="product_image"
                           v-on:change="onChangeProductImage()"
                           accept="image/*" capture
                    />
                  </label>
                  <span class="text-danger small m-0 p-0" v-if="error.errors.product_image">{{ error.errors.product_image[0] }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-sm-12">
                  <button
                    v-show="!loadingProduct"
                    type="submit"
                    class="btn btn-primary btn-block btn-wd text-uppercase"
                    @click.prevent="saveProduct"
                  >
                    <i class="fa fa-save"></i>
                    Adicionar Produto
                  </button>
                </div>

                <div class="col-12 col-md-6 col-sm-12">
                  <button
                    v-show="!loadingProduct"
                    type="submit"
                    class="btn btn-success btn-block btn-wd text-uppercase"
                    @click.prevent="saveProductMore"
                  >
                    <i class="fa fa-save"></i>
                    Adicionar Produto
                  <i class="fa fa-plus"></i>
                  </button>

                  <button  v-show="loadingProduct" class="btn btn-default btn-block btn-wd disabled" disabled>
                    <i class="spinner-grow spinner-grow-sm"></i>
                    Aguarde
                  </button>
                </div>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask'
import async from "async";

  export default {
    directives: {mask},
    data () {
      return {
        loadingFinish: false,
        is_open_box: false,
        loadingProduct: false,
        product: {
          product_suite_user:'',
          product_box_number: '',
          product_weight: '',
          product_amount: '',
          product_image: '',
          reference_code: '',
          localization: '',
        },
        user:{},
        status: {
          status:1,
        },
        error: {
          errors:{}
        },
      }
    },
    methods: {
      getIsOpenBox(){
        let suiteUser = this.product.product_suite_user? this.product.product_suite_user : 0
        if(suiteUser){
            this.$notify(
            {
              message: 'Por favor aguarde estamos trabalhando em sua solicitação!',
              type: 'info',
              icon: 'fa fa-check'
            })
          this.$Progress.start()
          axios.get(`/user/suite/${suiteUser}`).then(({data})=>{
            this.is_open_box = data[0].is_open_box
            this.user = data[0]
            this.$Progress.finish();

          }).catch((error)=>{
            if(error){
              Exception.handle(error);
            }
            swal({
              title:"Erro!",
              text: "Há algo errado.",
              type: "warning",
              confirmButtonClass: 'btn btn-danger btn-fill',
              buttonsStyling: false
            });
            this.$Progress.fail();
          })
        }
      },
      onChangeBoxImage(){
        this.box.image = this.$refs.image.files[0];
      },
      onChangeProductImage(){
        this.product.product_image = this.$refs.product_image.files[0];
        this.product.product_image_url_one=URL.createObjectURL(this.product.product_image);
      },
      saveProduct(){
        this.storeProduct(1)
      },
      saveProductMore() {
        this.storeProduct(2)
      },
      storeProduct(clear) {
        this.loadingProduct = true;
        this.$Progress.start();
        let formData = new FormData();
        formData.append('product_image', this.product.product_image);
        formData.append('product_suite_user', this.product.product_suite_user);
        formData.append('product_weight', this.product.product_weight);
        formData.append('product_amount', this.product.product_amount);
        formData.append('reference_code', this.product.reference_code);
        formData.append('localization', this.product.localization);

        axios.post('/products', formData,
          {
            headers: {
              'content-type': 'multipart/form-data'
            }
          }
        ).then(({data}) => {
          // let image = new Image();
          // image.src = "data:image/jpg;base64," + data.data.bar_code;
          // let w = window.open("", "_blank");
          // w.document.write(`<p style="text-align:center; margin: 0;">#${data.data.id}</br>${image.outerHTML}</p>`)

          if(clear===2){
            this.product.product_image = '';
            this.product.product_weight = '';
            this.product.product_amount = '';
            this.product.localization = '';
            this.product.product_image = '';
            this.product.product_image_url_one = '';
          }else{
            this.product = {
              product_suite_user:'',
              product_box_number: '',
              product_weight: '',
              product_amount: '',
              product_image: '',
              product_image_url_one: '',
              reference_code: '',
              localization: '',
            }
            this.user= {}
          }
          this.$notify(
            {
              message: 'Dados atualizados com sucesso!',
              type: 'success',
              icon: 'fa fa-check'
            });
          this.loadingProduct = false;
          this.$Progress.finish();
        }).catch((err) => {
          if (err) {
            this.error.errors = err.response.data.errors;
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false
          });
          this.loadingProduct = false;
          this.$Progress.fail();
        });
      },
      focusInput() {
        this.$refs.product_suite_user.$el.focus();
      }
    },
    mounted(){
      this.focusInput();
    }
  }

</script>
<style scoped>
  .file-select > .select-button {
    padding: 0.2rem;
    color: white;
    background-color: #2EA169;
    border-radius: .3rem;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  .file-select > input[type="file"] {
    display: none;
  }
</style>
