<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div v-if="isAdmin">
          <div class="row">
            <div class="col-12">
              <card class="mb-2">
                <div class="row">
                  <div class="col-12 col-md-3 col-sm-12">
                    <fg-input
                      placeholder="Suite"
                      inputmode="numeric"
                      type="text"
                      v-model="searchFilters.suite"
                      ref="suite"
                    >
                    </fg-input>
                  </div>
                  <div class="col-12 col-md-3 col-sm-12">
                    <fg-input
                      placeholder="Código Produto"
                      inputmode="numeric"
                      type="text"
                      v-model="searchFilters.product_id"
                      ref="product_id"
                    >
                    </fg-input>
                  </div>
                  <div class="col-12 col-md-3 col-sm-12">
                    <fg-input
                      placeholder="Código de Referencia"
                      type="text"
                      v-model="searchFilters.reference_code"
                      ref="reference_code"
                    >
                    </fg-input>
                  </div>
                  <div class="col-12 col-md-3 col-sm-12">
                    <button class="btn btn-primary btn-block btn-wd text-uppercase" @click.prevent="filterQuery()"
                            v-show="!loading">
                      <i class="fa fa-search"></i>
                      filtrar
                    </button>
                    <button class="btn btn-secondary btn-block btn-wd text-uppercase disabled" v-show="loading">
                      <i class="el-icon-loading"></i>
                      carregando
                    </button>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>

        <div class="row mt-2" v-if="meta.from">
          <div class="col-12">
            <div>
              <p class="card-category">Exibindo {{ meta.from }} até {{ meta.to }} de um total de {{ meta.total }}
                registros</p>
            </div>
            <ul class="pagination">
              <li class="page-item" :class="{active: link.active}" v-for="link in meta.links" :key="link.label">
                <a class="page-link" @click.prevent="changePage(link.label)">{{ link.label }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4" v-for="product in filteredProduct" v-bind:key="product.id">
            <div class="cardhover border mt-2" v-bind:class="{ 'border-success': stockMin.check[product.id] }">
              <div class="media bg-white p-2">

                <img :src="productImage(product)" width="120" alt="image"
                     class="align-self-center img-thumbnail img-fluid cursor-pointer"
                     @click="productImageList(product)">

                <div class="media-body pr-2">
                  <p class="card-text text-right m-0 h5 text-capitalize">Caixa: {{ product.box_id }}</p>
                  <p class="card-text text-right m-0 h5 text-capitalize">Suite: {{ product.user_suite }}</p>
                  <p class="card-text text-right m-0 h5 text-capitalize">Código Produto: {{ product.id }}</p>
                  <p class="card-text text-right m-0 h5 text-capitalize">Peso Produto: {{ product.weight }}</p>
                  <p class="card-text text-right m-0 h5 text-capitalize">Quantidade: {{ product.amount }}</p>
                  <p class="card-text text-right m-0 h6 text-capitalize text-wrap">Referência:
                    {{ product.reference_code || '-' }}</p>
                  <p class="card-text text-right m-0 text-capitalize text-wrap text-muted" v-show="isAdmin">Localização:
                    {{ product.localization || '-' }}</p>
                  <p class="card-text text-right m-0 h5 text-uppercase">&nbsp;</p>
                  <p class="card-text text-right m-0 h6 text-uppercase ">Quantidade a enviar:</p>
                  <p class="card-text m-0 mt-1 text-muted bg-white text-right">
                    <el-input-number v-model="stockMin.amount[product.id]" controls-position="right" size="small"
                                     :min="1" :max="productAmountMax(product)"
                    >
                    </el-input-number>
                  </p>
                  <p class="card-text m-0 mt-1 text-muted bg-white text-right">
                    <l-button type="success" size="sm" wide
                              @click="addProductToCart(product,stockMin.amount[product.id])">
                              <span class="btn-label">
                                  <i class="fa fa-cart-plus"></i>
                              </span>
                      &nbsp;adicionar
                    </l-button>
                  </p>
                </div>

              </div>

              <div class="media-footer text-muted bg-white pl-2 pt-2" v-if="isAdmin">
                <l-button type="danger" size="md" @click="productDelete(product)" class="mr-2">
                          <span class="btn-label">
                              <i class="fa fa-times"></i>
                          </span>
                  &nbsp;Excluir
                </l-button>

                <l-button type="primary" size="md" @click="handleEditProduct(product)" class="mr-2">
                          <span class="btn-label">
                              <i class="fa fa-edit"></i>
                          </span>
                  &nbsp;Editar
                </l-button>
                <a :href="printLabel(product)" target="_blank">
                  <l-button type="warning" size="md" class="mr-2">
                            <span class="btn-label">
                                <i class="fa fa-tag"></i>
                            </span>
                    &nbsp;Etiqueta
                  </l-button>
                </a>
              </div>
              <div class="media-footer text-muted bg-white pl-2">
                <small class="text-muted">Criado em {{ product.created_at }}</small>
              </div>
              <div class="media-footer text-muted bg-white pl-2">
                <small class="text-muted">a {{ product.days_in_stock }} dias em estoque.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2" v-if="meta.from">
          <div class="col-12">
            <div>
              <p class="card-category">Exibindo {{ meta.from }} até {{ meta.to }} de um total de {{ meta.total }}
                registros</p>
            </div>
            <ul class="pagination">
              <li class="page-item" :class="{active: link.active}" v-for="link in meta.links" :key="link.label">
                <a class="page-link" @click.prevent="changePage(link.label)">{{ link.label }}</a>
              </li>
            </ul>
          </div>
        </div>


        <el-dialog
          title="Fotos do Produto"
          :visible.sync="modals.image"
          width="40%"
        >
          <div class="row">
            <div class="col-md-12">
                <span class="img-fluid">
                  <img :src="images.one" class="img-fluid">
                </span>
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
                  <button class="btn btn-danger" @click="closeModal('image')">fechar</button>&nbsp;
          </span>
        </el-dialog>

        <el-dialog
          title="Editar Produto"
          :visible.sync="modals.editProduct">

          <div>
            <form>
              <div class="row">
                <div class="col-lg-6">
                  <fg-input
                    class="mb-0"
                    label="Suite"
                    placeholder="Suite"
                    name="user_suite"
                    type="text"
                    v-model="editProduct.user_suite"
                    v-validate="modelEditValidations.user_suite"
                    :error="getEditError('user_suite')"
                    v-mask="['#####']"
                  >
                  </fg-input>
                </div>
                <div class="col-lg-6">
                  <fg-input
                    class="mb-0"
                    label="Código de Referência"
                    placeholder="Código de Referência"
                    name="reference_code"
                    type="text"
                    v-model="editProduct.reference_code"
                    v-validate="modelEditValidations.reference_code"
                    :error="getEditError('reference_code')"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="Peso"
                            placeholder="Peso"
                            name="weight"
                            type="text"
                            ref="product_weight"
                            v-model="editProduct.weight"
                            v-validate="modelEditValidations.weight"
                            :error="getEditError('weight')"
                            v-mask="['#.##','#.###', '##.###']"
                            masked="true"
                  >
                  </fg-input>
                </div>
                <div class="col-lg-6">
                  <fg-input class="mb-0" label="Quantidade"
                            placeholder="Quantidade"
                            name="amount"
                            type="text"
                            v-model="editProduct.amount"
                            validate="modelEditValidations.amount"
                            :error="getEditError('amount')"
                            v-mask="['###']"
                            masked="true"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <fg-input
                    class="mb-0"
                    label="Localização"
                    placeholder="Localização"
                    name="localization"
                    type="text"
                    v-model="editProduct.localization"
                    v-validate="modelEditValidations.localization"
                    :error="getEditError('localization')"
                  >
                  </fg-input>
                </div>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>

          <span v-show="!loading" slot="footer" class="dialog-footer">
                  <button class="btn btn-danger" @click="closeModal('editProduct')">cancelar</button>&nbsp;
                  <button class="btn btn-success" @click.prevent="validateEdit">salvar</button>
                </span>

          <span v-show="loading" slot="footer" class="dialog-footer">
                  <button class="btn btn-default" disabled>cancelar</button>&nbsp;
                  <button class="btn btn-default" disabled>salvar</button>
                </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {Dialog} from 'element-ui'
import {mask} from 'vue-the-mask'
import Fuse from 'fuse.js'

export default {
  directives: {mask},
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      loading: false,
      isAdmin: false,
      cartProducts: [],
      totalProducts: '',
      stockMin: {
        amount: [],
        check: []
      },
      box: {},
      images: {
        one: '',
        two: ''
      },
      products: {},
      productsSearch: {},
      editProduct: {},
      search: '',
      error: {
        errors: {}
      },
      tableData: {},
      modals: {
        editProduct: false,
        basic: false,
        image: false,
        centered: true,
      },
      modelEditValidations: {
        user_suite: {
          required: true,
          integer: true
        },
        box_id: {
          required: true,
        },
        weight: {
          required: true,
        },
        amount: {
          required: true,
        },
      },
      meta: {},
      paginate: 25,
      searchFilters: {},
      page: 0,
    }
  },
  methods: {
    changePage(page) {
      let new_page = page
      if (new_page === '«') {
        new_page = this.meta.current_page - 1
      }
      if (new_page === '»') {
        new_page = this.meta.current_page + 1
      }
      this.page = new_page;
      this.loadProducts()
    },
    filterQuery() {
      let params = this.searchFilters;
      this.params = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      this.loadProducts()
    },
    printLabel(product) {
      return baseURL + "products/" + product.id + "/product-label"
    },
    productAmountMax(row) {
      return row.amount;
    },
    productImage(row) {
      return baseURLIMGUSER + '/' + row.user_id + '/box/product/thumbnail_' + row.image;
    },
    productImageList(row) {
      this.modals['image'] = true;
      this.images.one = baseURLIMGUSER + '/' + row.user_id + '/box/product/' + row.image;
    },
    closeModal(name) {
      this.modals[name] = false
    },
    handleEditProduct(row) {
      this.modals['editProduct'] = true;
      this.editProduct.id = row.id;
      this.editProduct.user_suite = row.user_suite;
      this.editProduct.user_id = row.user_id;
      this.editProduct.localization = row.localization;
      this.editProduct.reference_code = row.reference_code;
      this.editProduct.box_id = row.box_id;
      this.editProduct.amount = row.amount;
      this.editProduct.weight = row.weight;
    },
    getEditError(fieldName) {
      return this.errors.first(fieldName)
    },
    validateEdit() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.updateProduct();
        }
      })
    },
    updateProduct() {
      this.loading = true;
      this.$Progress.start();
      this.$notify(
        {
          message: 'Aguarde estamos trabalhando em sua solicitação!',
          type: 'info',
          icon: 'fa fa-check',
          horizontalAlign: 'left',
          verticalAlign: 'bottom',
        });
      axios.put('product/' + this.editProduct.id, this.editProduct).then(() => {
        this.$notify(
          {
            message: 'Dados atualizados com sucesso!',
            type: 'success',
            icon: 'fa fa-check'
          });
        this.loading = false;
        this.$Progress.finish();
        this.loadProducts();
        this.closeModal('editProduct');
        this.editProduct.id = '';
        this.editProduct.user_suite = '';
        this.editProduct.user_id = '';
        this.editProduct.box_id = '';
        this.editProduct.amount = '';
        this.editProduct.weight = '';
      }).catch((error) => {
        let message = error.response.data.error;
        if (error) {
          Exception.handle(error);
        }
        swal({
          title: "Erro!",
          text: message,
          type: "warning",
          confirmButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        });
        this.closeModal('editProduct');
        this.loading = false;
        this.$Progress.fail();
      });
    },
    loadProducts() {
      this.loading = true;
      axios.get("/product?page=" + this.page + "&limit=" + this.paginate + "&" + this.params)
        .then((data) => {
          this.productsSearch = data.data.data;
          this.products = data.data.data;
          this.meta = data.data.meta;
          // this.tableData = data.data.data;
          var products = this.products;
          for (var i = 0, l = products.length; i < l; i++) {
            this.stockMin.amount[products[i].id] = 1;
          }

          if (localStorage.getItem('cart_' + User.userProfile().id)) {
            this.cartProducts = JSON.parse(localStorage.getItem('cart_' + User.userProfile().id));
            var pro = this.cartProducts;
            for (var i = 0, l = pro.length; i < l; i++) {
              this.stockMin.amount[pro[i].id] = pro[i].amount_send;
              this.stockMin.check[pro[i].id] = true;
            }
          }
          this.loading = false;
        });
    },
    addProductToCart(product, amount) {

      const index = this.cartProducts.findIndex((cartProduct, index, array) => cartProduct.id === product.id);

      let qtde = amount;

      if (index < 0) {
        if (parseInt(qtde) < parseInt(product.amount)) {
          this.cartProducts.push({
            id: product.id,
            description: product.description,
            stock: product.amount,
            amount_send: qtde,
            weight: product.weight,
            image: product.image,
            created_at: product.created_at,
            active: false
          });
          localStorage['cart_' + User.userProfile().id] = JSON.stringify(this.cartProducts);
          // this.totalWeigth();
          this.countCart(JSON.parse(localStorage['cart_' + User.userProfile().id]).length);

        } else {
          this.cartProducts.push({
            id: product.id,
            description: product.description,
            stock: product.amount,
            amount_send: product.amount,
            weight: product.weight,
            image: product.image,
            created_at: product.created_at,
            active: false
          });
          localStorage['cart_' + User.userProfile().id] = JSON.stringify(this.cartProducts);
          // this.totalWeigth();
          this.countCart(JSON.parse(localStorage['cart_' + User.userProfile().id]).length);

        }
      } else {
        const cartProductsList = this.cartProducts.map((productCart, index, array) => {
          if (productCart.id === product.id) {
            let amount_send = parseInt(qtde);
            if (parseInt(amount_send) < parseInt(productCart.stock)) {
              productCart.amount_send = parseInt(amount_send);
            } else {
              productCart.amount_send = product.amount;
            }
          } else {
          }
          return productCart;
        });
        localStorage['cart_' + User.userProfile().id] = JSON.stringify(this.cartProducts);

        this.countCart(JSON.parse(localStorage['cart_' + User.userProfile().id]).length);
        // this.loadOptionsSend();
      }

      swal({
        title: 'Adicionado!',
        text: 'Seu produto foi adicionado ao carrinho.',
        type: 'success',
        showCancelButton: true,
        confirmButtonText: 'Continuar adicionando',
        cancelButtonText: 'Criar Envio',
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-primary btn-fill',
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
        } else {
          this.$router.push({path: '/user/cart/newcart'})
        }
      })
    },
    productDelete(row) {
      let indexToDelete = this.productsSearch.findIndex((tableRow) => tableRow.id === row.id);
      this.$Progress.start();
      swal({
        title: 'Você tem certeza?',
        text: 'Você não poderá recuperar este registro!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apague isto!',
        cancelButtonText: 'Não, mantenha',
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false,
      }).then((result) => {
        // Send request to the server
        if (result.value) {
          axios.delete('/product/' + row.id).then(() => {
            if (indexToDelete >= 0) {
              this.tableData.splice(indexToDelete, 1)
            }
            swal({
              title: 'Apagado!',
              text: 'Seu registro foi apagado.',
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            this.$Progress.finish();
            this.$notify(
              {
                message: 'Registro apagado com sucesso!',
                type: 'success',
                icon: 'fa fa-check'
              });
          }).catch((error) => {
            let message = error.response.data.error;
            if (error) {
              Exception.handle(error);
            }
            swal({
              title: "Erro!",
              text: message,
              type: "warning",
              confirmButtonClass: 'btn btn-danger btn-fill',
              buttonsStyling: false
            });
            this.$Progress.fail();
          });
        }
      })
    },
    countCart(count) {
      EventBus.$emit('count-cart', count);
    },
  },
  computed: {
    filteredProduct: function () {
      if (this.search) {
        const fuse = new Fuse(this.productsSearch, {
          tokenize: false,
          threshold: 0.0,
          keys: ['user_suite', 'reference_code']
        });
        return fuse.search(this.search);
      } else {
        return this.products;
      }
    }
  },
  created() {
    this.loadProducts();

    this.totalProducts = (localStorage['cart_' + User.userProfile().id]) ? JSON.parse(localStorage['cart_' + User.userProfile().id]).length : 0;
    // if(localStorage['cart_'+User.userProfile().id]){
    //     this.loadOptionsSend();
    // }
    this.isAdmin = User.isAdmin();

    EventBus.$on('count-cart', (count) => {
      this.totalProducts = count;
    });

    EventBus.$on('AfterCreate', () => {
      this.loadProducts();
    });

  },
  mounted() {
    this.isAdmin = User.isAdmin();
    this.totalProducts = (localStorage['cart_' + User.userProfile().id]) ? JSON.parse(localStorage['cart_' + User.userProfile().id]).length : 0;
  },
}
</script>
<style>
.swal2-container {
  z-index: 10000;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
