<template>
  <div>
    <div class="row">
      <div class="col-lg-12 pr-0">
        <card class="m-0 m-0">
          <div class="m-0 m-0">
            <div
              class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-default mb-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-input
                type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Pesquiser"
                v-model="searchQuery"
                aria-controls="datatables"
              />
            </div>
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="queriedData"
                border
              >
                <el-table-column min-width="60" label="Foto">
                  <template slot-scope="{ row }">
                    <div class="img-container">
                      <img
                        :src="cartImage(row)"
                        alt="Foto"
                        class="img-thumbnail"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :align="column.align"
                  :fit="column.fit"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column
                  :min-width="80"
                  fixed="right"
                  label="Valor"
                  align="center"
                >
                  <template slot-scope="props">
                    {{ cart_total_price[props.row.id] | currency("€") }}
                  </template>
                </el-table-column>

                <el-table-column
                  :min-width="110"
                  fixed="right"
                  label="Status"
                  align="center"
                >
                  <template slot-scope="props">
                    {{ cart_status[props.row.id] }}
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="100"
                  fixed="right"
                  label=""
                  align="center"
                >
                  <template slot-scope="props">
                    <div
                      v-if="
                        cart_status[props.row.id] === 'aguardando pagamento'
                      "
                    >
                      <button
                        class="btn btn-social btn-linkedin"
                        @click="payCart(props.row.id, props.row.total_price)"
                      >
                        <i class="fab fa-paypal"></i>&nbsp;Pay Pal
                      </button>
                    </div>
                    <div v-else-if="cart_status[props.row.id] === 'finalizado'">
                      <a
                        :href="sendTracking(props.row.shipping_url, props.row.tracking)"
                        target="_blanck"
                      >
                        <button class="btn btn-social btn-success active">
                          {{ props.row.tracking }}
                        </button>
                      </a>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  :min-width="80"
                  fixed="right"
                  label="Ações"
                  align="center"
                >
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Observações'"
                      class="btn-success btn-simple btn-link cursor"
                      @click="
                        openCartObservations(
                          props.row.id,
                          props.row.observations
                        )
                      "
                    >
                      <i class="fa fa-comments"></i> </a
                    >&nbsp;&nbsp;

                    <a
                      v-tooltip.top-center="'Detalhes'"
                      class="btn-info btn-simple btn-link"
                      @click="openCartDetails(props.$index, props.row)"
                    >
                      <i class="fa fa-eye"></i>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Exibindo de {{ from + 1 }} até {{ to }} de {{ total }} registros
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>

    <el-dialog :visible.sync="modals.details" width="80%">
      <card class="m-0 mb-2">
        <div class="card-header p-0">
          <h5 class="card-title">Detalhes do Envio Usuário</h5>
        </div>
        <table class="table table-hover table-responsive-md">
          <thead>
          <tr>
            <th class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="p-1 m-0 text-left text-secondary">
              Envio: #{{ sendId }} &nbsp; &nbsp; Data: {{ sendDate }}
            </td>
          </tr>
          </tbody>
        </table>
      </card>

      <card class="m-0 mb-2">
        <div class="card-header p-0 mb-3">
          <h5 class="card-title"><strong>Selecionar forma de envio:</strong></h5>
        </div>
        <span v-if="loadingMessage != ''">{{ loadingMessage }}<br/></span>
        <el-radio-group v-model="shippingOptionChoose" :disabled="shippingOptionChooseDisabled">
          <table>
            <tbody>
            <tr v-for="shippingFeeOption in shippingFeeOptions" :key="shippingFeeOption.id">
              <td class="pb-1">
                <el-radio :label="shippingFeeOption.id"
                          @change="changeShippingFee(sendId,shippingFeeOption.id, shippingFeeOption.price, shippingFeeOption.is_dimensional)">
                  {{ shippingFeeOption.shipping_option_name }}&nbsp;-&nbsp;Tempo estimado de entrega
                  {{ shippingFeeOption.shipping_option.delivery_time }}&nbsp;-&nbsp;€ {{ shippingFeeOption.price }}
                </el-radio>
              </td>
            </tr>
            </tbody>
          </table>
        </el-radio-group>
      </card>

      <el-collapse>
        <el-collapse-item title="Produtos solicitados" name="1">
          <card class="m-0 mb-2">
            <table class="table table-hover table-responsive-md">
              <thead>
              <tr>
                <th class="text-center">Foto</th>
                <th class="text-center">Código Produto</th>
                <th class="text-center">Referiencia</th>
                <th class="text-center">Qt Estoque</th>
                <th class="text-center">Qt Enviar</th>
                <th class="text-center">Peso</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="product in sendProducts" :key="product.id">
                <td class="p-1 m-0" width="70">
                  <div class="img-fluid" style="cursor: pointer">
                    <img
                      :src="productImage(product)"
                      alt="Foto"
                      class="img-thumbnail"
                      @click="productImageList(product)"
                    />
                  </div>
                </td>
                <td class="p-1 m-0 text-center text-secondary" width="120">
                  {{ product.id }}
                </td>
                <td class="p-1 m-0 text-center text-secondary">{{ product.reference_code || '-' }}</td>
                <td class="p-1 m-0 text-center text-secondary">
                  {{ product.amount_stock }}
                </td>
                <td class="p-1 m-0 text-center text-secondary">
                  {{ product.amount }}
                </td>
                <td class="p-1 m-0 text-center text-secondary">
                  {{ product.weight }}
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="5">
                  <table class="table table-responsive-md m-0 p-0">
                    <tr style="border: 0px">
                      <td class="m-0 p-0 b-0" style="border: 0px" width="30%">
                          <span class="text-left">
                            <strong>Total de produstos:</strong>&nbsp;
                          </span>
                      </td>
                      <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                        <span class="text-right">{{ totalQntProduct }}</span>
                      </td>
                    </tr>
                    <tr style="border: 0px">
                      <td class="m-0 p-0 b-0" style="border: 0px">
                          <span class="text-left">
                            <strong>Peso Total dos produstos:</strong>&nbsp;
                          </span>
                      </td>
                      <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                          <span class="text-right"
                          >{{ totalProductWeight }} kg</span
                          >
                      </td>
                    </tr>
                    <tr>
                      <td class="m-0 p-0" style="border: 0px">
                          <span class="text-left">
                            <strong>Peso estimado da caixa:</strong>&nbsp;
                          </span>
                      </td>
                      <td class="m-0 p-0 b-0 text-left" style="border: 0px">
                          <span class="text-right"
                          >{{ totalProductWeightApproximate }} kg</span
                          >
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              </tfoot>
            </table>
          </card>
        </el-collapse-item>
        <el-collapse-item title="Detalhes do pedido de envio." name="2">
          <div class="row">
            <div class="col-md-8">
              <card class="m-0 mb-2">
                <div class="card-header p-0">
                  <h5 class="card-title">Endereço de entrega.</h5>
                </div>
                <table class="table table-hover table-responsive-md">
                  <tbody>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Apelido do Endereço:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.name_address }}
                    </td>
                    <td
                      class="p-1 m-0 text-left text-secondary font-weight-bold"
                    ></td>
                    <td class="p-1 m-0 text-left text-secondary"></td>
                  </tr>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Destinatário:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.recipient_name }}
                    </td>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Telefone destinatário:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.recipient_phone }}
                    </td>
                  </tr>

                  <tr>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Endereço:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.address }}
                    </td>
                    <td class="p-1 m-0 text-left text-secondary"></td>
                    <td class="p-1 m-0 text-left text-secondary"></td>
                  </tr>

                  <tr>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Complemento:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.complement }}
                    </td>
                    <td
                      class="p-1 m-0 text-left text-secondary font-weight-bold"
                    ></td>
                    <td class="p-1 m-0 text-left text-secondary"></td>
                  </tr>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Cidade / Localidade:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.city }}
                    </td>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Estado / Província / Região:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.state }}
                    </td>
                  </tr>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Pais:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.country }}
                    </td>
                    <td class="p-1 m-0 text-left text-secondary font-weight-bold">
                      Código Postal / Cep:
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendAddress.post_code }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </card>
            </div>
            <div class="col-md-4">
              <card class="m-0 mb-2">
                <div class="card-header p-0">
                  <h5 class="card-title">Opção de pagamento.</h5>
                </div>
                <h5 class="card-title pt-2 pb-2">
                  <strong>{{ payment_option }}</strong>
                </h5>
              </card>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <card class="m-0 mb-2" v-if="sendDeclarations.length > 0">
                <div class="card-header p-0">
                  <h5 class="card-title">Declaração Alfandegaria.</h5>
                </div>
                <table class="table table-hover table-responsive-md">
                  <thead>
                  <tr>
                    <th>Item</th>
                    <th class="text-center">Quantidade</th>
                    <th class="text-center">Valor Unit.</th>
                    <th class="text-center">Valor Total.</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="declaration in sendDeclarations"
                    :key="declaration.id"
                  >
                    <td class="p-1 m-0 text-secondary">{{ declaration.name }}</td>
                    <td class="p-1 m-0 text-center text-secondary">
                      {{ declaration.amount }}
                    </td>
                    <td class="p-1 m-0 text-center text-secondary">
                      {{ declaration.unit_price }}
                    </td>
                    <td class="p-1 m-0 text-center text-secondary">
                      {{ declaration.total_price }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </card>

              <card class="m-0 mb-2" v-else>
                <div class="card-header p-0">
                  <h5 class="card-title text-center">
                    <strong>Sem solicitação de declaração alfandegaria.</strong>
                  </h5>
                </div>
              </card>
            </div>

            <div class="col-md-6">
              <card class="m-0 mb-2" v-if="sendServices.length > 0">
                <div class="card-header p-0">
                  <h5 class="card-title">Serviços Extras.</h5>
                </div>
                <table class="table table-hover table-responsive-md">
                  <thead>
                  <tr>
                    <th>Serviço</th>
                    <th class="text-center">Valor</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="service in sendServices" :key="service.id">
                    <td class="p-1 m-0 text-center text-secondary">
                      {{ service.name }}
                    </td>
                    <td class="p-1 m-0 text-center text-secondary">
                      {{ service.price }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </card>

              <card class="m-0 mb-2" v-else>
                <div class="card-header p-0">
                  <h5 class="card-title text-center">
                    <strong>Sem solicitação de serviços extras.</strong>
                  </h5>
                </div>
              </card>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <card class="m-0 mb-2">
                <div class="card-header p-0">
                  <h5 class="card-title">Detalhamento de valores.</h5>
                </div>
                <table class="table table-hover table-responsive-md">
                  <thead>
                  <tr>
                    <th>Item</th>
                    <th class="text-center">Valor</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary">Frete</td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendPrices.shipping_price }}
                    </td>
                  </tr>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary">
                      Taxa Redirecionamento
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendPrices.service_price }}
                    </td>
                  </tr>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary">
                      Taxa Serviços Extras
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendPrices.extra_service_price }}
                    </td>
                  </tr>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary">
                      Taxa PayPal &nbsp;&nbsp;{{ sendPrices.rate_paypal }}%
                    </td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendPrices.rate_paypal_price }}
                    </td>
                  </tr>
                  <tr>
                    <td class="p-1 m-0 text-left text-secondary">Valor Total</td>
                    <td class="p-1 m-0 text-left text-secondary">
                      {{ sendPrices.total_price }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </card>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>


      <span slot="footer" class="dialog-footer">
        <button class="btn btn-default" @click="closeModal('details')">
          fechar</button
        >&nbsp;
      </span>
    </el-dialog>

    <el-dialog
      title="Fotos do Produto"
      :visible.sync="modals.image"
      width="40%"
    >
      <div class="row">
        <div class="col-md-12">
          <span class="img-fluid">
            <img :src="images.one" class="img-fluid"/>
          </span>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('image')">
          fechar</button
        >&nbsp;
      </span>
    </el-dialog>

    <el-dialog
      title="Observações do envio"
      :visible.sync="modals.observation"
      width="40%"
    >
      <div class="mesgs">
        <div class="msg_history">
          <div v-for="observation in sendObservations" :key="observation.id">
            <div class="incoming_msg" v-if="observation.role == 'user'">
              <!-- <div class="incoming_msg_img">
                  <img :src="observationUserImage(observation.user_id, observation.profile_picture)" class="rounded-circle img-thumbnail">
                </div> -->

              <div class="received_msg">
                <div class="received_withd_msg">
                  <p>{{ observation.text }}</p>
                  <span class="time_date"
                  >{{ observation.user_full_name }} |
                    {{ observation.created_at }}</span
                  >
                </div>
              </div>
            </div>

            <div class="outgoing_msg" v-if="observation.role == 'admin'">
              <div class="sent_msg">
                <p>{{ observation.text }}</p>
                <span class="time_date"
                >{{ observation.user_full_name }} |
                  {{ observation.created_at }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="type_msg">
          <div class="input_msg_write">
            <input
              type="text"
              class="write_msg"
              v-model="sendObservation.text"
              placeholder="Digite uma mensagem"
            />
            <button
              class="msg_send_btn"
              type="button"
              v-show="sendObservation.text"
            >
              <i
                class="fa fa-paper-plane"
                aria-hidden="true"
                @click="addObservation"
              ></i>
            </button>
            <button
              class="msg_send_btn_off"
              type="button"
              v-show="!sendObservation.text"
            >
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('observation')">
          fechar</button
        >&nbsp;
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  Collapse,
  CollapseItem,
  Dialog,
  Option,
  Radio,
  RadioButton,
  RadioGroup,
  Select,
  Table,
  TableColumn,
} from "element-ui";
import {Pagination as LPagination} from "src/components/index";
import {Radio as LRadio} from 'src/components';
import Fuse from "fuse.js";

export default {
  components: {
    LPagination,
    LRadio,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["suite", "id", "tracking"],
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.pagination.total = result.length;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  data() {
    return {
      shippingOptionChooseDisabled: false,
      shippingOptionChoose: '',
      loadingMessage: '',
      shippingFeeOptions: {},
      shippingChoose: {},
      loading: false,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      cartPay: {
        id: "",
        price: 0,
      },
      searchQuery: "",
      propsToSearch: ["suite", "id", "tracking"],
      tableColumns: [
        // { prop: 'suite', label: 'suite', align: 'center', fit: true },
        {prop: "id", label: "caixa", align: "center", fit: true},
        // { prop: 'total_price', label: 'valor',align: 'center', fit: true},
        // { prop: 'payment_option', label: 'pagamento',align: 'center', fit: true},
        {
          prop: "created_at",
          label: "criada em",
          align: "center",
          minWidth: 120,
        },
        // { prop: 'tracking', label: 'rastreio', align: 'center', fit: true},
      ],
      tableData: [],
      fuseSearch: null,
      selects: {
        place: "",
        cartStatus: [
          {value: "0", label: "novo"},
          {value: "1", label: "pago"},
          {value: "2", label: "em processamento"},
          {value: "3", label: "cancelado"},
          {value: "4", label: "finalizado"},
          {value: "5", label: "aguardando pagamento"},
        ],
      },
      cart_status: [],
      cart_tracking: [],
      cart_total_price: [],
      payment_option: "",
      modals: {
        details: false,
        image: false,
        centered: false,
        observation: false,
      },
      sendId: "",
      sendDate: "",
      sendProducts: {},
      sendAddress: {},
      sendAddressNull: {
        address: "-",
        city: "-",
        complement: "-",
        country: "-",
        created_at: "2020-02-27 10:45:27",
        default: "-",
        deleted_at: null,
        district: "-",
        id: 0,
        name_address: "ENDEREÇO NÃO INFORMADO",
        number: "-",
        post_code: "-",
        recipient_cpf: "-",
        recipient_name: "-",
        state: "-",
        updated_at: "2020-02-27 10:45:27",
        user_id: 0,
      },
      sendDeclarations: {},
      sendServices: {},
      sendPrices: {},
      totalQntProduct: 0,
      totalProductWeight: 0,
      totalProductWeightApproximate: 0,
      images: {
        one: "",
      },
      sendObservation: {},
      sendObservations: {},
    };
  },
  methods: {
    cartImage(cart) {
      if (cart.image_url_thumbnail) {
        return baseURLIMG + cart.image_url_thumbnail;
      }
      return baseURLIMG + "/sem-imagem.png";
    },
    sendTracking(url, tracking) {
      if (!url) {
        let url = "https://www.ctt.pt/feapl_2/app/open/objectSearch/objectSearch.jspx?objects=";
        return url + tracking;
      }
      return url + tracking;
    },
    productImageList(row) {
      this.modals["image"] = true;
      this.images.one =
        baseURLIMGUSER + "/" + row.user_id + "/box/product/" + row.image;
    },
    productImage(product) {
      return (
        baseURLIMGUSER +
        "/" +
        product.user_id +
        "/box/product/thumbnail_" +
        product.image
      );
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    totalWeigth(products) {
      let productsWeight = products
        .reduce(
          (total, item) => parseFloat(total + item.amount * item.weight),
          0
        )
        .toFixed(3);
      if (productsWeight) {
        return productsWeight;
      }
    },
    approximate(products) {
      let productsWeight = products
        .reduce(
          (total, item) => parseFloat(total + item.amount * item.weight),
          0
        )
        .toFixed(3);
      if (productsWeight) {
        let weight = parseFloat(productsWeight * 0.17).toFixed(3);
        return (parseFloat(productsWeight) + parseFloat(weight)).toFixed(3);
      }
    },
    addObservation() {
      this.loading = true;
      this.$Progress.start();
      this.$notify({
        message: "Aguarde estamos trabalhando em sua solicitação!",
        type: "info",
        icon: "fa fa-check",
        horizontalAlign: "left",
        verticalAlign: "bottom",
      });
      axios
        .post("cartobservation", this.sendObservation)
        .then((response) => {
          this.$notify({
            message: response.data.message,
            type: "success",
            icon: "fa fa-check",
          });
          this.loading = false;
          this.$Progress.finish();
          this.loadCarts();
          this.sendObservation.cart_id = "";
          this.sendObservation.text = "";
          this.closeModal("observation");
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: error.response.data.error,
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.closeModal("observation");
          this.loading = false;
          this.$Progress.fail();
        });
    },
    openCartObservations(cartId, observations) {
      this.modals["observation"] = true;
      this.sendObservation.cart_id = cartId;
      this.sendObservations = observations;
    },
    observationUserImage(userId, image) {
      if (image == "no-img-profile.png") {
        return baseURLIMGUSER + "/" + image;
      } else {
        return baseURLIMGUSER + userId + "/" + image;
      }
    },
    openCartDetails(index, row) {
      this.shippingFeeOptions = {};
      let weight = row.cart_sizes.weight;
      this.loadingMessage = "Aguarde, buscando fretes disponiveis.";
      this.modals["details"] = true;
      this.shippingOptionChooseDisabled = false;

      if (weight != 0) {
        this.loadShippingFee(row.cart_sizes.weight, row.cart_sizes.dimensional_weight, row.send_address.country);
      } else {
        this.loadingMessage = "Estamos preparando o seu pedido, por favor aguarde.";
      }

      this.shippingOptionChoose = row.shipping_id;
      this.sendId = row.id;
      this.sendDate = row.created_at;
      this.sendProducts = row.products;
      this.sendAddress = row.send_address
        ? row.send_address
        : this.sendAddressNull;
      this.sendDeclarations = row.declarations;
      this.sendServices = row.services;
      this.sendPrices = row.cart_prices;
      this.payment_option = row.payment_option
      this.totalQntProduct = row.products.reduce(
        (total, item) => total + item.amount,
        0
      );
      this.totalProductWeight = this.totalWeigth(row.products);
      this.totalProductWeightApproximate = this.approximate(row.products);
    },
    payCart(cartId, cartPrice) {
      this.loading = true;
      this.cartPay.id = cartId;
      this.cartPay.price = cartPrice;

      axios
        .post("/create-payment", this.cartPay)
        .then(({data}) => {
          window.open(
            data,
            "_blank",
            "location=no,toolbar=no,scrollbars=yes,resizable=yes,top=300,left=600,width=600,height=600"
          );

          this.loading = false;
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loading = false;
          this.$Progress.fail();
        });
    },
    changeShippingFee(cartId, shippingId, shippingPrice, is_dimensional) {
      this.$notify(
        {
          message: 'Aguarde estamos trabalhando em sua solicitação!',
          type: 'info',
          icon: 'fa fa-check',
          horizontalAlign: 'right',
          verticalAlign: 'top',
        });
      this.loading = true;
      this.shippingOptionChooseDisabled = true;
      this.$Progress.start();
      this.shippingChoose.shipping_id = shippingId;
      this.shippingChoose.shipping_price = shippingPrice;
      this.shippingChoose.is_dimensional = is_dimensional;
      axios.put("/cart/chooseshipping/" + cartId, this.shippingChoose)
        .then(({data}) => {
          this.loadCarts();
          swal({
            text: data.message,
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          this.modals["details"] = false;
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loading = false;
          this.$Progress.fail();
        });
    },
    loadShippingFee(weight, dimensional_weight, country_name) {
      axios
        .get("/shippingfeechoose?dimensional_weight=" + dimensional_weight + "&weight=" + weight + "&country_name=" + country_name)
        .then(({data}) => {
          this.loadingMessage = "";
          this.shippingFeeOptions = data.data
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loading = false;
          this.$Progress.fail();
        });
    },
    loadCarts() {
      axios
        .get("/myshipment")
        .then(({data}) => {
          this.tableData = data.data;
          for (let i = 0; i < data.data.length; i++) {
            this.cart_status[data.data[i].id] = data.data[i].status;
            this.cart_tracking[data.data[i].id] = data.data[i].tracking;
            this.cart_total_price[data.data[i].id] = data.data[i].total_price;
          }
        })
        .catch((error) => {
          if (error) {
            Exception.handle(error);
          }
          swal({
            title: "Erro!",
            text: "Há algo errado.",
            type: "warning",
            confirmButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          });
          this.loading = false;
          this.$Progress.fail();
        });
    },
  },
  mounted() {
    this.loadCarts();
  },
};
</script>
<style>
.swal2-container {
  z-index: 10000;
}

.cursor {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}

.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}

.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}

.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}

.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}

.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}

.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}

.chat_ib h5 span {
  font-size: 13px;
  float: right;
}

.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}

.chat_img {
  float: left;
  width: 11%;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 12%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 88%;
}

.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.received_withd_msg {
  width: 57%;
}

.mesgs {
  /* float: left;

   */
  padding: 30px 15px 0 25px;
}

.sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.sent_msg {
  float: right;
  width: 60%;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}

.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.msg_send_btn_off {
  background: #c4c4c4 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.messaging {
  padding: 0 0 20px 0;
}

.msg_history {
  height: 400px;
  overflow-y: auto;
}
</style>
