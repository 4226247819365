import * as types from './mutation-types'
import axios from 'axios'
const endPoint = '/order'
export default {
  namespaced: true,
  state: {
    items: [],
    order: {
      products:[]
    },
  },
  getters:{
    fetch: state => state.items,
    order: state => state.order,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_ORDER] (state, payload){
      state.order = payload
    },
    [types.SET_PRODUCT_ORDER] (state, payload){
      state.order.products = state.order.products.push(payload)
    },
  },
  actions: {
    fetchItems({commit}) {
      return axios.get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchOrder({commit}) {
      return axios.get(endPoint)
        .then(({data}) =>{
          commit(types.SET_ORDER, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    addProductOrder({ commit }, payload) {
      return commit(types.SET_PRODUCT_ORDER, payload)
    },
  }
}
