<template>
  <div>
    <div class="row">
      <div class="col-lg-8 col-md-8">
        <update-address></update-address>
      </div>
      <div class="col-lg-4 col-md-4">
        <update-password></update-password>
      </div>
    </div>
  </div>
</template>
<script>
  import UpdateAddress from './Settings/UpdateAddress'
  import UpdatePassword from './Settings/UpdatePassword'

  export default {
    components: {
      UpdateAddress,
      UpdatePassword,
    }
  }

</script>
<style>

</style>
