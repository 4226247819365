<template>
</template>
<script>
  export default {
    name: "TotalProduct",
    data () {
      return {
        totalProducts:'',
      }
    },
    mounted() {
      this.totalProducts = JSON.parse(localStorage['cart_'+User.userProfile().id]).length;
    }
  }
</script>
<style>

</style>
